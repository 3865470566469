import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBuilding } from '@avesdo-common/src/lib/models/building-models/Building';
import { NotificationService } from '@avesdo-common/src/lib/services/notifications/notification.service';
import { AdminService } from '@ng-new/src/app/shared/services/admin.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-feature-permissions',
  templateUrl: './manage-feature-permissions.component.html',
  styleUrls: ['./manage-feature-permissions.component.scss']
})
export class ManageFeaturePermissionsComponent implements OnInit {
  existingFeatureIds: string;
  isSaving: boolean = false;
  isLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ManageFeaturePermissionsComponent>,
    private adminService: AdminService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {features: any, project: IBuilding, organizationId: string}
  ) { }

  parentFeatures() {
    return this.data.features.filter(feature => !feature.parentFeature);
  }

  subFeatures(parentId) {
    return this.data.features.filter(feature => feature.parentFeature?.id === parentId);
  }

  setAll(parent) {
    this.data.features.forEach(feature => {
      if (feature.parentFeature?.id === parent.id) {
        feature.checked = parent.checked;
      }
    });
  }

  updateParentFeature(parent) {
    parent.checked = this.data.features.filter(feature => feature.parentFeature?.id === parent.id).reduce((a, b) => a && b.checked, true);
  }

  disableSave() {
    return this.data.features.reduce((a, b) => a && b.checked === this.existingFeatureIds.includes(b.id), true);
  }

  save() {
    this.isSaving = true;
    this.adminService.updateOrganizationFeatures(
      this.data.organizationId,
      this.data.project.developerId,
      this.data.features.filter(feature => feature.checked).map(feature => feature.id)
    ).subscribe(result => {
      this.isSaving = false;
      if (result.success) {
        this.notificationService.success(this.translateService.instant('GENERIC.SUCCESS'));
        this.dialogRef.close(true);
      } else {
        this.notificationService.error(this.translateService.instant('ERRORS.ERROR'));
      }
    }, () => {
      this.isSaving = false;
      this.notificationService.error(this.translateService.instant('ERRORS.ERROR'));
    });
  }

  ngOnInit(): void {
    this.adminService.getFeaturesById(this.data.organizationId, this.data.project.developerId).subscribe(data => {
      this.existingFeatureIds = data.map(i => i.feature.id);
      this.data.features.map(feature => {
        feature.checked = this.existingFeatureIds.includes(feature.id);
      });
      this.isLoading = false;
    });
  }

}
