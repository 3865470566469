import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { Router } from '@angular/router';
import { LocalStorageNames } from '../../shared/enums/LocalStorageNames';
import { HubSpotApiChatService } from '@avesdo-common/src/lib/services/hubspot-chat.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private hubSpotChatService: HubSpotApiChatService
  ) { }

  ngOnInit(): void {
    this.authService.completeAuthentication().then(
      (oicdUser) => {
        if (oicdUser) {
          const url = localStorage.getItem(LocalStorageNames.CoreReturnUrl);
          localStorage.removeItem(LocalStorageNames.CoreReturnUrl);

          if (url) {
            this.router.navigateByUrl(url);
          } else {
            this.router.navigate(['']);
          }

          // As soon after the login success, the Chat is pop up
          // Disabled for now, can be enabled again by uncommenting
          // this.hubSpotChatService.injectHubSpotScript();
        }
      }
    );
  }


}
