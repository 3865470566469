import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GalleryDialogComponent } from '../../components/gallery-dialog/gallery-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ImageCarouselModalService {

  constructor(private dialog: MatDialog) {}

  openDialog(imageUrls: string[], startIndex = 0) {
    this.dialog.open(GalleryDialogComponent, {
      data: {
        imageUrls,
        startIndex,
      },
      backdropClass: 'dialog-dark-backdrop',
      panelClass: 'av-dialog',
    });
  }
}
