import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PropertyCard } from '../../models/building-models/PropertyCard';
import { TimeRemainingPipe } from '../../pipes/time-remaining.pipe';

@Component({
  selector: 'lib-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
})
export class PropertyCardComponent implements OnInit , OnDestroy {
  @Input() property: PropertyCard;
  @Output() clickAction: EventEmitter<any> = new EventEmitter();
  timerLeftInterval;

  constructor() {}

  ngOnInit() {
    const reservationTime = this.property.propertyReservation && this.property.propertyReservation.reserveExpireDate;
    if (reservationTime) {
      const timeRemainingPipe = new TimeRemainingPipe();
      this.timerLeftInterval = setInterval(() => {
        const timeLeft = timeRemainingPipe.transform(reservationTime);
        if (timeLeft.indexOf('No time remaining') === -1 && timeLeft.indexOf('NoLimitReservation') === -1) {
         this.property.timeLeftToReserve = timeLeft;
       }
     }, 60000);
   }
  }

  clickEmit() {
    this.clickAction.emit();
  }

  ngOnDestroy() {
    clearInterval(this.timerLeftInterval);
  }
}
