import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private dialog: MatDialog) { }

  openDialog(data: ConfirmationDialogData, width = 512): MatDialogRef<ConfirmationDialogComponent, boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data,
      width: `${width}px`,
      height: 'auto',
      panelClass: 'confirmation-dialog'
    });

    return dialogRef;
  }
}
