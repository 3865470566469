export interface DemandLevelSettings {
	level: number;
	lowerBound: number;
	upperBound: number;
	label: string;
	color?: string;
	textColor?: string;
}

export interface IDemandColorThemes {
	[key: string]: {
		colors: {
			level: number;
			color: string;
			textColor: string;
		}[]
	}
}

export const DemandColorThemes: IDemandColorThemes =
{
	green: {
		colors: [
			{ level: 0, color: '#beef9e', textColor: '#1e3a4c'  },
			{ level: 1, color: '#8fc16e', textColor: '#1e3a4c' },
			{ level: 2, color: '#688c51', textColor: '#ffffff' },
			{ level: 3, color: '#3f5133', textColor: '#ffffff' },
			{ level: 4, color: '#040603', textColor: '#ffffff' },
		]
	},
	// TODO: Product Team to provide proper color shades and themes
	red: {
		colors: [
			{ level: 0, color: '#330000', textColor: '#1e3a4c'  },
			{ level: 1, color: '#660000', textColor: '#1e3a4c'  },
			{ level: 2, color: '#990000', textColor: '#ffffff'  },
			{ level: 3, color: '#cc0000', textColor: '#ffffff'  },
			{ level: 4, color: '#ff0000', textColor: '#ffffff'  },
		]
	},
	blue: {
		colors: [
			{ level: 0, color: '#000033', textColor: '#1e3a4c'  },
			{ level: 1, color: '#000066', textColor: '#1e3a4c'  },
			{ level: 2, color: '#000099', textColor: '#ffffff'  },
			{ level: 3, color: '#0000cc', textColor: '#ffffff'  },
			{ level: 4, color: '#0000ff', textColor: '#ffffff'  },
		]
	},
}

