import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'propUnitSlName',
})
@Injectable({
  providedIn: 'root',
})
export class PropUnitSlNamePipe implements PipeTransform {
  unit: string;
  sl: string;
  floorPlan: string;
  l: string;
  u: string;
  level: string;
  suite: string;

  constructor(public translateService: TranslateService) {
    this.unit = translateService.instant('GENERIC.UNIT');
    this.sl = translateService.instant('GENERIC.STRATALOT');
    this.floorPlan = translateService.instant('HOME.FLOOR_PLAN');
    this.l = translateService.instant('L');
    this.u = translateService.instant('U');
    this.level = translateService.instant('GENERIC.LEVEL');
    this.suite = translateService.instant('GENERIC.SUITE');
  }

  transform(prop: any, area: string, type?: string) {
    if (!prop)
      return '';
    if (area === 'BC' || area === 'AB'|| area === 'AU_EST' || area == 'CO') {
      return this.forAreaBC(prop, type);
    }
    if (area === 'ON') {
      return this.forAreaON(prop, type);
    }
    return '';
  }

  private forAreaBC(prop: any, type?: string) {
    switch (type) {
      case 'shortSl':
      case 'sl':
        return `${this.sl}${prop.strataLot}`;
      case 'unitSuiteWithFloorPlan':
        return `${this.unit}: ${prop.unitNumber ?? ''} ${this.sl}${prop.strataLot}  ${this.floorPlan}: ${prop.floorPlan}`;
      case 'SLUnit':
        return `${this.sl}${prop.strataLot ?? ''} ${this.unit}.${prop.unitNumber ?? ''}`;
      case 'TransferFrom':
        return `${this.sl}${prop.strataLot ?? ''} ${this.unit}.${prop.unitNumber ?? ''} - ${prop.availabilty ?? ''}`;
      default:
        return `${this.unit}: ${prop.unitNumber ?? ''} ${this.sl}${prop.strataLot}`;
    }

  }

  private forAreaON(prop: any, type?: string) {
    switch (type) {
      case 'shortSl':
        return `${this.l}.${prop.level} ${this.u}.${prop.lotUnit}`;
      case 'sl':
        return `${this.level}.${prop.level} ${this.unit}.${prop.lotUnit}`;
      case 'unitSuiteWithFloorPlan':
        return `${this.suite}: ${prop.unitNumber ?? ''} ${this.floorPlan}: ${prop.floorPlan}`;
      case 'TransferFrom':
        return `${this.suite}. ${prop.unitNumber ?? ''} ${this.level}.${prop.level ?? ''} ${this.unit}.${prop.lotUnit ?? ''} - ${prop.availabilty ?? ''}`;
      case 'SLUnit':
      default:
        return `${this.suite}: ${prop.unitNumber ?? ''} ${this.level}.${prop.level ?? ''} ${this.unit}.${prop.lotUnit ?? ''}`;
    }

  }
}
