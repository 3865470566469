export enum PropertyStatus {
    Available = 'Available',
    HoldBack = 'Unavailable',
    Reserved = 'Reserved',
    PaymentHold = 'Payment Hold',
    NotSigned = 'Not Signed',
    SignInProgress = 'Sign In Progress',
    Signed = 'Signed',
    Executed = 'Executed',
    Request = 'Request',
    SoldRescissionPeriod = 'Sold Rescission Period',
    SoldFirm = 'Sold Firm',
    SoldWithSubjects = 'SoldWithSubjects',
    Scheduled = 'Scheduled'
}
