import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-badge-pill-toggle',
  templateUrl: './badge-pill-toggle.component.html',
  styleUrls: ['./badge-pill-toggle.component.scss']
})
export class BadgePillToggleComponent implements OnInit {

  @Input() label: string = '';
  @Input() toggleState: boolean = false;
  @Output() onToggle: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  
  onClick(): void {
    this.toggleState = !this.toggleState;
    this.onToggle.emit(this.toggleState ? this.label : '');
  }

  getClass(): string {
    return this.toggleState ? 'badge-primary' : 'badge-secondary';
  }

}
