import { Countries } from '@avesdo-common/src/lib/enums/Countries';

export class Widget {
  type?: string;
  key?: string;
  label?: string;
  placeholder?: string;
  input?: boolean;
  disableOnInvalid?: boolean;
  displayOrder?: number;
  isRequired?: boolean;
  name?: string;
  validateOn?: string;
  validate?: {
    required: boolean;
    customMessage?: string;
    onlyAvailableItems?: boolean;
  };
  conditional: {
    show: boolean;
    when: string;
    eq: string;
  };
  customClass: string;
  components: any;
  isCustomField: boolean;
  templateControlOptions?: any;
  templateControlOptionSource?: {
    id: string,
    templateControlId: string,
    sourceUrl: string,
    path: string,
    httpRequestType: string,
    parameters: string,
    sourceTypeId: string
  };
  groupName: any;
  data;
  entitySchema: string;
  entityColumnName: string;
  entityTable: string;
  dataSrc?: string;
  dataType?: string;
  valueProperty?: string;
  refreshOn?: string;
  clearOnRefresh?: boolean;
  searchEnabled?: boolean;
  disableLimit?: boolean;

  constructor(widget: IFormControls,  displayOrder: number , countryKey?: any) {
    this.type = widget.controlType;
    this.label = widget.label;
    this.placeholder = widget.placeHolder;
    this.input = true;
    this.name = widget.name;
    this.dataType = widget.dataType;
    this.valueProperty = widget.valueProperty;
    this.refreshOn = widget.refreshOn;
    this.isRequired = widget.isRequired ? widget.isRequired : false;
    this.clearOnRefresh = widget.clearOnRefresh;
    this.templateControlOptionSource = widget.templateControlOptionSource ? widget.templateControlOptionSource : null;
    this.templateControlOptions = widget.templateControlOptions ? widget.templateControlOptions : null;
    this.validate = {
      required: widget.isRequired,
      customMessage: '',
    };
    this.conditional = {
      show :  true,
      when :  widget.parentTemplateControlId,
      eq : widget.parentTemplateControlValue
    };

    if (widget.label === LeadFormConstants.stateOrProvince) {
      this.dataSrc = widget.dataSrc ? widget.dataSrc : '';
    }

    if ((widget.templateControlOptionSource && countryKey)) {
      this.data =  {
        values: (widget.templateControlOptions) ? [...this.setCountriesInAlphabeticalOrder(widget)] : [],
        json: widget.templateControlOptions ? this.setCountriesInAlphabeticalOrder(widget) : [],
        url: (widget.templateControlOptionSource && countryKey) ?
          (widget.templateControlOptionSource.sourceUrl + '/'
            + widget.templateControlOptionSource.path + '/' + `{{ row['${countryKey}'] }}`) : ''
      };
    } else {
      this.data =  {
        values: (widget.templateControlOptions) ? [...widget.templateControlOptions] : []
      };
    }

    this.displayOrder = displayOrder;
    this.key = widget.key;
    this.customClass  = 'custom-formio-class';
    this.components = widget.components;
    this.isCustomField  = widget.isCustomField;
    this.templateControlOptions = widget.templateControlOptions;
    this.groupName = widget.groupName;
    this.name = widget.name;
    this.entityColumnName = widget.entityColumnName;
    this.entitySchema = widget.entitySchema;
    this.entityTable = widget.entityTable;
  }

  setCountriesInAlphabeticalOrder(widget) {
    widget.templateControlOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    const templateControlOptions = [];
    const countryDisplayOnTop = [];
    widget.templateControlOptions.forEach(option => {
      if (option.label === Countries.Canada || option.label === Countries.China || option.label === Countries.UnitedStatesOfAmerica) {
        countryDisplayOnTop.push(option);
      } else {
        templateControlOptions.push(option);
      }
    });
    return  countryDisplayOnTop.concat(templateControlOptions);
  }
}

export interface IFormControls {
  controlType?: string;
  controlTypeId?: string;
  dataType?: string;
  isCustomField?: boolean;
  label?: string;
  name: string;
  entitySchema: string;
  entityColumnName: string;
  entityTable: string;

  $Id?: string;
  id?: string;
  defaultLabel?: string;
  fieldTypeId?: string;
  customFieldTypeId?: string;
  key?: string;
  isRequired?: boolean;
  groupName?: string;
  domainFieldName?: string;
  domainName?: string;
  displayOrder?: number;
  hasMultipleValues?: boolean;
  placeHolder?: string;
  isAutoComplete?: boolean;
  defaultValue?: string;
  sectionId?: string;
  templateControlOptions?: [] | any;
  templateControlOptionSource?: {
    id: string,
    templateControlId: string,
    sourceUrl: string,
    path: string,
    httpRequestType: string,
    parameters: string,
    sourceTypeId: string
  };
  components?: any;
  parentTemplateControlId?: any;
  parentTemplateControlValue?: any;
  type?: any;
  dataSrc?: string;
  valueProperty?: string;
  refreshOn?: string;
  clearOnRefresh?: boolean;
}

export const LeadFormConstants = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  submit: 'Submit',
  brokerage: 'Brokerage',
  contactDetail: 'Contact Detail',
  addressDetails: 'Address Details',
  addressProperties: 'Address Properties',
  addressDetail: 'Address',
  validation: 'Validation',
  containerAddressProperties: 'ContainerAddressProperties',
  containerRealtor: 'ContainerRealtor',
  areYouRealtor: 'Are you a Realtor?',
  areYourWorkingWithRealtor: 'Are you working with a Realtor?',
  howDidYouHearAboutUs: 'How did you hear about us?',
  CASL: 'CASL',
  templateContainerTypeId: '9abb3c58-1f90-4b10-a1e5-e00fe24e52eb',
  templateContainerType: 'Layout',
  city: 'City',
  stateOrProvince: 'State/Province',
  postalOrZipCode: 'Postal/Zipcode',
  streetAddress: 'Street Address',
  country: 'Country'
};
