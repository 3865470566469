import { Pipe, PipeTransform } from '@angular/core';
import { DocumentProgressStatus } from '../enums/DocumentProgressStatus';

@Pipe({
  name: 'docCancelledFilter'
})
export class DocCancelledFilterPipe implements PipeTransform {

  transform(value: any[], cancelled: boolean = true): any[] {
    if (!value)
      return [];
    const docs = value.filter((elem) => cancelled === (elem.progressStatus === DocumentProgressStatus.Rejected || elem.progressStatus === DocumentProgressStatus.Cancelled));

    // Sort but prioritize 'Contract'
    docs.sort((a, b) => {
      a = a.documentType === 'Contract' ? '0' + a.documentType : '1' + a.documentType;
      b = b.documentType === 'Contract' ? '0' + b.documentType : '1' + b.documentType;
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    });
    return docs;
  }

}
