import { Component } from '@angular/core';
import { AsyncButtonMixin } from './async-button.mixin';
import { Injector } from '@angular/core';
import { Base } from 'ng-mix';

@Component({
  selector: 'lib-async-button',
  templateUrl: './async-button.component.html',
  styleUrls: ['./async-button.component.scss'],
  inputs: ['disabled', 'isLoading', 'color'],
  outputs: ['btnClick']
})
export class AsyncButtonComponent extends AsyncButtonMixin(Base) {
  constructor(inj: Injector) { super(inj); }
}
