import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@avesdo-common/src/lib/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CfgFormService {

  constructor(private http: HttpClient) { }

  getPdf(formId: number): Observable<HttpResponse<Blob>> {
    const url = `${environment.crmApiUrl}/Contracts/CfgForm/GetPdf`
    const params = { formId };

    return this.http.get(url, {
      params,
      responseType: 'blob',
      observe: 'response'
    }).pipe(catchError((error) => throwError(error)))
  }

  getPdfPreviousVersions(formId: number): Observable<HttpResponse<Blob>> {
    const url = `${environment.crmApiUrl}/Contracts/CfgForm/GetPdfPreviousVersions`
    const params = { formId };

    return this.http.get(url, {
      params,
      responseType: 'blob',
      observe: 'response'
    }).pipe(catchError((error) => throwError(error)))
  }

  extractPage(formId: number, page: number): Observable<HttpResponse<Blob>> {
    const url = `${environment.crmApiUrl}/Contracts/CfgForm/ExtractPage`
    const params = { formId, page };

    return this.http.get(url, {
      params,
      responseType: 'blob',
      observe: 'response'
    }).pipe(catchError((error) => throwError(error)))
  }

}
