import { createAction, props } from '@ngrx/store';
import { ModuleNames } from '../enums/ModuleNames';

export const UPDATE_ACTIVE_MODULE = '[Shared] Update Active Module';
export const HIDE_COMMON_HEADER = '[Shared] Hide Common Header';
export const SHOW_COMMON_HEADER = '[Shared] Show Common Header';

export const updateActiveModule = createAction(
	UPDATE_ACTIVE_MODULE,
  props<{ activeModule: ModuleNames }>()
);

export const hideCommonHeader = createAction(HIDE_COMMON_HEADER);
export const showCommonHeader = createAction(SHOW_COMMON_HEADER);