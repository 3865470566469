import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { genericNoopAction } from '@avesdo-common/src/lib/redux/generic.actions';
import {
  FETCH_BUILDING_SETTINGS,
  FETCH_BUILDING_SETTINGS_IF_NEEDED,
  fetchBuildingSettings,
  fetchBuildingSettingsFail,
  fetchBuildingSettingsSuccess,
  FETCH_IS_SELECTIONS_SETUP,
  FETCH_IS_SELECTIONS_SETUP_IF_NEEDED,
  fetchIsSelectionsSetupSuccess,
  fetchIsSelectionsSetupFail,
  fetchIsSelectionsSetup
} from '@ng-new/src/app/shared/redux/BuildingSettings/building-settings.actions';
import { ConfigService } from '../../services/config.service';
import { AuthService } from '@ng-new/src/app/auth/services/auth.service';

@Injectable()
export class BuildingSettingsEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private configService: ConfigService,
    private authService: AuthService
  ) { }

  fetchBuildingSettingsIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_BUILDING_SETTINGS_IF_NEEDED),
      withLatestFrom(this.store.select('buildingSettings')),
      switchMap(([action, state]) => {
        if(state.buildingSettings || state.isLoading)
          return of(genericNoopAction())

        return of(fetchBuildingSettings());
      })
    )
  );

  fetchBuildingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_BUILDING_SETTINGS),
      switchMap(() => {
        return this.getBuildingSettings();
      })
    )
  );

  getBuildingSettings(): Observable<Action> {
    return this.configService.getBuildingSettings()
      .pipe(
        mergeMap(buildingSettings => of(fetchBuildingSettingsSuccess({ buildingSettings }))), 
        catchError(() => of(fetchBuildingSettingsFail()))
      );
  }

  fetchIsSelectionsSetupIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_IS_SELECTIONS_SETUP_IF_NEEDED),
      withLatestFrom(this.store.select('buildingSettings')),
      switchMap(([action, { isSelectionsSetup }]) => {
        if(isSelectionsSetup.isSelectionsSetup !== null || isSelectionsSetup.isLoading)
          return of(genericNoopAction())

        return of(fetchIsSelectionsSetup());
      })
    )
  );

  fetchIsSelectionsSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_IS_SELECTIONS_SETUP),
      switchMap(() => {
        return this.getIsSelectionsSetup();
      })
    )
  );

  getIsSelectionsSetup(): Observable<Action> {
    return this.authService.getIsSelectionsActive() 
      .pipe(
        mergeMap(isSelectionsSetup => of(fetchIsSelectionsSetupSuccess({ isSelectionsSetup }))), 
        catchError(() => of(fetchIsSelectionsSetupFail()))
      );
  }
}
