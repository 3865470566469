import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FileData } from '@avesdo-common/src/lib/models/bulk-file-upload/file-data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-file-upload-summary',
  templateUrl: './file-upload-summary.component.html',
  styleUrls: ['./file-upload-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FileUploadSummaryComponent implements OnInit {
  @Input() uploadingFilesList: FileData[] = [];
  @Output() onCancelTheFlow: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onCompletion: EventEmitter<boolean> = new EventEmitter<boolean>();

  uploadSuccessTranslation: string;
  uploadFailTranslation: string;
  uploadingProgress: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService
      .get([
        'BULKFILEUPLOAD.FILE_UPLOAD_SUMMARY_UPLOADING',
        'BULKFILEUPLOAD.FILEUPLOAD_UPLOAD_SUMMARY_FAIL',
        'BULKFILEUPLOAD.FILEUPLOAD_UPLOAD_SUMMARY_SUCCESS',
      ])
      .subscribe((translation) => {
        this.uploadFailTranslation =
          translation['BULKFILEUPLOAD.FILEUPLOAD_UPLOAD_SUMMARY_FAIL'];
        this.uploadSuccessTranslation =
          translation['BULKFILEUPLOAD.FILEUPLOAD_UPLOAD_SUMMARY_SUCCESS'];
        this.uploadingProgress =
          translation['BULKFILEUPLOAD.FILE_UPLOAD_SUMMARY_UPLOADING'];
      });
  }

  get getProgressMessage() {
    if (
      this.uploadingFilesList.find(
        (x) => x.uploadingProgress.isInProgress === true
      )
    )
      return this.uploadingProgress.replace(
        '<count>',
        ` ${this.uploadingFilesList.length.toString()} `
      );

    const uploadSucessItems = this.uploadingFilesList.filter(
      (file: FileData) => !file.uploadingProgress.hasError
    ).length;
    if (uploadSucessItems !== this.uploadingFilesList.length)
      return [
        this.uploadSuccessTranslation.replace(
          '<count>',
          ` ${uploadSucessItems.toString()} `
        ),
        this.uploadFailTranslation.replace(
          '<count>',
          ` ${(this.uploadingFilesList.length - uploadSucessItems).toString()} `
        ),
      ].join(' ');
    return this.uploadSuccessTranslation.replace('<count>',` ${uploadSucessItems.toString()} `);
  }

  onCancel(): void {
    this.onCancelTheFlow.emit(true);
  }

  onFinish(): void {
    this.onCompletion.emit(true);
  }
}
