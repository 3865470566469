import { AvTableColumnConfig, AvTableSearchState, FilterTypes } from "../feature/av-table/components/av-table.component";
import { range as lRange } from 'lodash';
import { SortDirection } from "@angular/material/sort";

export class AvesdoCompartors {
  static ascendingSort(a, b) {
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  }
  static descendingSort(a, b) {
    if (a < b) {
      return 1;
    }
    if (b < a) {
      return -1;
    }
    return 0;
  }


  static sortFloorsAscending(a, b) {
    const parsedA = parseInt(a);
    const parsedB = parseInt(b);
    //Send all non number floors to bottom of list
    if (isNaN(parsedA) && !isNaN(parsedB))
      return 1;

    if (!isNaN(parsedA) && isNaN(parsedB))
      return -1;

    if (isNaN(parsedA) && isNaN(parsedB)) {
      return this.ascendingSort(a, b);
    }

    return this.ascendingSort(parsedA, parsedB);
  }

  static sortFloorsDecending(a, b) {
    const parsedA = parseInt(a);
    const parsedB = parseInt(b);
    //Send all non number floors to bottom of list
    if (isNaN(parsedA) && !isNaN(parsedB))
      return 1;

    if (!isNaN(parsedA) && isNaN(parsedB))
      return -1;

    if (isNaN(parsedA) && isNaN(parsedB)) {
      return this.descendingSort(a, b);
    }

    return this.descendingSort(parsedA, parsedB);
  }

  static filterAndSortTable(data: any[], searchState: AvTableSearchState, columns: AvTableColumnConfig[]) {
    if (!searchState) return data;
    const basicFilters = columns.map(column => {
      return {
        propertyName: column.propertyName,
        filterType: column.filterType,
        filter: searchState.filters[column.propertyName]
      }
    });
    const { estimatedCompletionInDays: estDaysFilter } = searchState.filters;
    let { active: activeProperty, direction } = searchState.sort;
    let active = columns.find(column => column.propertyName === activeProperty)?.sortName ?? activeProperty;

    return data.filter((item) => {
      for (let obj of basicFilters) {
        const { propertyName, filterType, filter } = obj;
        
        switch (filterType) {
          case FilterTypes.multiSelect:
          case FilterTypes.multiSelectLarge:
            const selected = Object.keys(filter)
            .filter((key) => filter[key]);
  
            if (selected.length && !selected.includes(item[propertyName]?.toString()))
              return false;
            break;
          case FilterTypes.number:
          case FilterTypes.text:
            if (filter?.length > 0 && !item[propertyName]?.toString().toLowerCase().includes(filter.toLowerCase()))
              return false;
            break;
        }
      }

      if (estDaysFilter) {
        const range = Object.keys(estDaysFilter).reduce((range, key) => {
          switch (parseInt(key)) {
            case 30:
              range = estDaysFilter[key] ? range.concat(lRange(0, 30)) : range;
              break;
            case 60:
              range = estDaysFilter[key] ? range.concat(lRange(30, 61)) : range;
              break;
            case 90:
              range = estDaysFilter[key] ? range.concat(lRange(61, 91)) : range;
              break;
            case 91:
              range = estDaysFilter[key] ? range.concat(lRange(91, 3650)) : range;
              break;
            default:
              break;
          }

          return range;

        }, []);

        const estimatedCompletionInDays = item.closing?.estimatedCompletionInDays;
  
        if (range.length && !range.includes(estimatedCompletionInDays))
          return false;
      }

      return true;
    }).sort((a, b) => {
        let c, d;

        if (`${a[active]}`.match(/\/Date\(-?[\d].+\)\//g)) {
          c = parseInt(a[active].replace('/Date(', '').replace(')/', ''));
        }
        if (`${b[active]}`.match(/\/Date\(-?[\d].+\)\//g)) {
          d = parseInt(b[active].replace('/Date(', '').replace(')/', ''));
        }

        if (!isNaN(parseInt(a[active])) && !isNaN(parseInt(b[active]))) {
          c = parseInt(a[active]);
          d = parseInt(b[active]);
        } else if (active === 'estimatedCompletionInDays') {
          c = a.closing.estimatedCompletionInDays;
          d = b.closing.estimatedCompletionInDays;
        }
        else {
          c = a[active] ?? '';
          d = b[active] ?? '';
        }

        if (c === '') {
          return 1;
        }
        if (d === '') {
          return -1;
        }
        if (direction === <SortDirection>'desc') {
          if (typeof c === 'string' && typeof d === 'string') {
            return d.toLowerCase() > c.toLowerCase() ? 1 : -1;
          }
          return d > c ? 1 : -1;
        }
        if (typeof c === 'string' && typeof d === 'string') {
          return d.toLowerCase() > c.toLowerCase() ? -1 : 1;
        }
        return d > c ? -1 : 1;
      });
  }

  static paginateTable(data: any[], searchState: AvTableSearchState) {
    return searchState?.page ? data.slice(searchState.page.pageIndex * searchState.page.pageSize, searchState.page.pageIndex * searchState.page.pageSize + searchState.page.pageSize - 1) : data
  }
}
