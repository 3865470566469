import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userLabelRole'
})
export class UserLabelRolePipe implements PipeTransform {

  transform(label: string, summary?: boolean): unknown {
    if (label == null)
        return '';

    var labelFirst = label.substring(0, 1).toLowerCase();

    if (labelFirst === 'c')
        return summary ? 'P' : 'Purchaser';

    if (labelFirst === 'r')
        return summary ? 'R' : 'Realtor';

    if (labelFirst === 'x')
        return summary ? 'C' : 'Custom User';

    if (labelFirst === 'o')
        return summary ? 'A' : 'Assignee';
    return '';
  }

}
