import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationType } from '@avesdo-common/src/lib/enums/OrganizationType';
import { IBuilding } from '@avesdo-common/src/lib/models/building-models/Building';
import { IOrganization } from '@avesdo-common/src/lib/models/building-models/Organization';
import { NotificationService } from '@avesdo-common/src/lib/services/notifications/notification.service';
import { AdminService } from '@ng-new/src/app/shared/services/admin.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ManageFeaturePermissionsComponent } from '../../dialogs/manage-feature-permissions/manage-feature-permissions.component';
import {
  AddMarketingOrganizationComponent
} from "@ng-new/src/app/feature/contracts/modules/super-admin/dialogs/add-marketing-org/add-marketing-organization.component";

@Component({
  selector: 'app-manage-organizations',
  templateUrl: './manage-organizations.component.html',
  styleUrls: ['./manage-organizations.component.scss']
})
export class ManageOrganizationsComponent implements OnInit {
  organizationTypes: string[] = [];
  organizations: IOrganization[] = [];
  typeForm = new FormControl('');
  organizationForm = new FormControl('');
  getOrganizations$: Observable<any>;
  typeFilteredOptions$: Observable<string[]>;
  organizationFilteredOptions$: Observable<IOrganization[]>;
  selectedOrganization: IOrganization;
  typeDict = {};
  shareContactsAcrossProjects: boolean;
  saveLoading: boolean = false;
  showAll = false;
  features: any[];
  selectedOrgType: string;

  constructor(
    private adminService: AdminService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) { }

  openAutofill(event, trigger: MatAutocompleteTrigger, input) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      return;
    }
    this.showAll = true;
    trigger.openPanel();
    input.select();
  }

  getFilteredOrganizations() {
    return this.organizations.filter(org => this.typeDict[org.organizationTypeValue].toLowerCase() === this.typeForm.value.toLowerCase());
  }

  openProject(project: IBuilding) {
    this.dialog.open(ManageFeaturePermissionsComponent, {
      panelClass: 'full-divider-dialog',
      width: '512px',
      data: {
        features: this.features,
        project,
        organizationId: this.selectedOrganization.id
      }
    })
  }

  openAddMarketingOrg(project: IBuilding) {
    this.dialog.open(AddMarketingOrganizationComponent, {
      panelClass: 'full-divider-dialog',
      width: '512px',
      data: {
        marketingOrganizations: this.organizations.filter(org => org.organizationTypeValue.toLowerCase() === "marketing_company"),
        project,
        organizationId: this.selectedOrganization.id
      }
    }).afterClosed().subscribe(() => {
      this.getOrganizations$.subscribe();
    });
  }

  onSave(organization: IOrganization) {
    this.saveLoading = true;
    this.adminService.updateOrganizations([{
      organizationId: organization.id,
      shareContactsAcrossProjects: this.shareContactsAcrossProjects
    }]).subscribe(resp => {
      if (resp.Success) {
        this.notificationService.success(this.translateService.instant('SELECTIONS.SAVED'));
        organization.shareContactsAcrossProjects = this.shareContactsAcrossProjects;
      } else {
        this.notificationService.error(this.translateService.instant('ERRORS.ERROR'));
      }
      this.saveLoading = false;
    }, () => {
      this.notificationService.error(this.translateService.instant('ERRORS.ERROR'));
      this.saveLoading = false;
    });
  }

  ngOnInit(): void {
    this.organizationForm.disable();
    this.getOrganizations$ = this.adminService.getOrganizationsWithProjects().pipe(
      map(result => {
        this.organizations = result.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        this.organizationTypes = result.map(r => r.organizationTypeValue).reduce((prev, cur) => {
          if (!prev.find(type => type === this.typeDict[cur])) {
            if (OrganizationType.Developer === cur) {
              this.typeDict[cur] = this.translateService.instant('GENERIC.DEVELOPER');
            } else if (OrganizationType.MarketingCompany === cur) {
              this.typeDict[cur] = this.translateService.instant('GENERIC.MARKETING_COMPANY');
            }
            prev.push(this.typeDict[cur]);
          }
          return prev;
        }, []).sort((a, b) => a > b ? 1 : -1);
      })
    );
    this.getOrganizations$.subscribe();
    this.typeFilteredOptions$ = this.typeForm.valueChanges.pipe(
      startWith(''),
      tap(value => {
        const showAll = this.showAll;
        if (this.organizationTypes.find(type => type.toLowerCase() === value?.toLowerCase())) {
          this.organizationForm.enable();
          this.selectedOrgType = value;
        } else {
          this.organizationForm.setValue('');
          this.organizationForm.disable();
        }
        this.showAll = showAll;
      }),
      map(value => this.organizationTypes.filter(type => type.toLowerCase().includes(value?.toLowerCase()) || this.showAll)),
      tap(() => {
        this.showAll = false;
      })
    );
    this.organizationFilteredOptions$ = this.organizationForm.valueChanges.pipe(
      startWith(''),
      tap(value => {
        this.selectedOrganization = this.getFilteredOrganizations().find(org => org.name.toLowerCase() === value?.toLowerCase())
        this.saveLoading = false;
        this.shareContactsAcrossProjects = this.selectedOrganization?.shareContactsAcrossProjects;
      }),
      map(value => this.getFilteredOrganizations().filter(org => org.name.toLowerCase().includes(value?.toLowerCase()) || this.showAll)),
      tap(() => {
        this.showAll = false;
      })
    );
    this.adminService.getAllFeatures().subscribe(data => {
      this.features = data
    });
  }
}
