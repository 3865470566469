import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-av-tab-button',
  templateUrl: './av-tab-button.component.html',
  styleUrls: ['./av-tab-button.component.scss']
})
export class AvTabButtonComponent {
  @Input() active = false;

  constructor() { }
}
