import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FloorPlan } from '../../../models/FloorPlan';
import { FloorPlanService } from '../../../services/floor-plan.service';
import { FloorRange } from '@avesdo-common/src/lib/feature/selection/models/FloorRange';

interface LevelOptions {
  min: number,
  max: number,
  selected: boolean
}
interface FloorRangeOptions extends FloorRange {
  selected: boolean
}
interface UnitTypes {
  bed: number,
  den: number,
  selected: boolean
}
@Component({
  selector: 'lib-unit-picker-filter',
  templateUrl: './unit-picker-filter.component.html',
  styleUrls: ['./unit-picker-filter.component.scss']
})
export class UnitPickerFilterComponent implements OnInit {
  @Input() floorPlans: FloorPlan[];
  @Input() isFloorPlanBased: boolean;
  @Input() floorRanges: FloorRange[];
  @Output() close = new EventEmitter();
  @Output() filterfloorPlans = new EventEmitter<FloorPlan[]>();
  levelOptions: LevelOptions[] = [];
  floorRangeOptions: FloorRangeOptions[] = [];
  unitTypes: UnitTypes[] = [];
  maxPrice: FormControl;
  selectedFloorPlans: any[];
  noMatchFound: boolean = false;
  state = {
    floorRangeOptions: [],
    levelOptions: [],
    unitTypes: [],
    maxPrice: undefined,
    selectedFloorPlans: []
  }

  constructor(private floorPlanService: FloorPlanService) { }

  get minFloorPlanPrice(): number {
    return Math.min(...this.floorPlans.map(floorPlan => floorPlan.floorPlanFromPrice));
  }

  get isFiltering() {
    return Object.values(this.state).find((item: any) => Array.isArray(item) ? item.filter(v => v).length > 0 : item);
  }

  get filterCount() {
    return Object.values(this.state).filter((item: any) => Array.isArray(item) ? item.filter(v => v).length > 0 : item).length;
  }

  formatUnitTypeText(unitType) {
    return this.floorPlanService.formatBedroomText({
      bedrooms: unitType.bed,
      den: unitType.den
    });
  }

  apply() {
    if (this.maxPrice.hasError('min')) {
      return;
    }
    let data;
    if (this.selectedFloorPlans.find(floorPlan => floorPlan.selected)) {
      data = this.floorPlans.filter(floorPlan => (
        this.selectedFloorPlans.find(selectedFloorPlan => selectedFloorPlan.id == floorPlan.id && selectedFloorPlan.selected)
      ));
    } else {
      data = this.floorPlans;
    }
    data = this.getFloorPlansByPrice(data);
    data = this.getFloorPlansByUnitType(data);
    data = this.isFloorPlanBased ? this.getAllSelectedFloorRanges(data) : this.getAllselectedLevels(data);
    if (data.length === 0) {
      this.noMatchFound = true;
    } else {
      this.saveState();
      this.filterfloorPlans.emit(data);
      this.close.emit();
    }
  }

  resetToState() {
    this.noMatchFound = false;
    this.maxPrice.setValue(this.state.maxPrice);
    this.unitTypes.forEach((type, i) => type.selected = this.state.unitTypes?.[i]);
    this.selectedFloorPlans.forEach((floorPlan, i) => floorPlan.selected = this.state.selectedFloorPlans?.[i]);
    this.levelOptions.forEach((level, i) => level.selected = this.state.levelOptions?.[i]);
    this.floorRangeOptions.forEach((level, i) => level.selected = this.state.floorRangeOptions?.[i]);
  }

  reset(clearState: boolean = true) {
    if (clearState) {
      this.state = {
        floorRangeOptions: [],
        levelOptions: [],
        unitTypes: [],
        maxPrice: undefined,
        selectedFloorPlans: []
      }
    }
    this.noMatchFound = false;
    this.maxPrice.reset();
    this.unitTypes.forEach(type => type.selected = false);
    this.selectedFloorPlans.forEach(floorPlan => floorPlan.selected = false);
    this.levelOptions.forEach(level => level.selected = false);
    this.floorRangeOptions.forEach(level => level.selected = false);
  }

  private saveState() {
    this.state.maxPrice = this.maxPrice.value;
    this.unitTypes.forEach((type, i)  => this.state.unitTypes[i] = type.selected);
    this.selectedFloorPlans.forEach((floorPlan, i) => this.state.selectedFloorPlans[i] = floorPlan.selected);
    this.levelOptions.forEach((level, i) => this.state.levelOptions[i] = level.selected);
    this.floorRangeOptions.forEach((level, i) => this.state.floorRangeOptions[i] = level.selected);
  }

  private getFloorPlansByPrice(floorPlans: FloorPlan[]): FloorPlan[] {
    return this.maxPrice.valid && this.maxPrice.value != undefined
      ? floorPlans.filter(floorPlan => floorPlan.floorPlanFromPrice <= this.maxPrice.value)
      : floorPlans;
  }

  private getFloorPlansByUnitType(floorPlans: FloorPlan[]): FloorPlan[] {
    if (this.unitTypes.some(type => type.selected)) {
      return floorPlans.filter(floorPlan => {
        return this.unitTypes.find(type => type.selected && floorPlan.bedrooms === type.bed && floorPlan.den === type.den);
      });
    }
    return floorPlans;
  }

  private getFloorPlansByLevel(floorPlans: FloorPlan[], { min, max }): FloorPlan[] {
    return floorPlans.filter(floorPlan => floorPlan.floorSections.find(level => parseInt(level) >= min && parseInt(level) <= max));
  }

  private getAllselectedLevels(floorPlans: FloorPlan[]) {
    const floorPlansByLevel = [];
    if (this.levelOptions.some(level => level.selected)) {
      this.levelOptions.filter(level => level.selected).forEach(level => {
        floorPlansByLevel.push(...this.getFloorPlansByLevel(floorPlans, level));
      });
      return floorPlansByLevel;
    }
    return floorPlans;
  }

  private getAllSelectedFloorRanges(floorPlans: FloorPlan[]) {
    const selectedFloorRanges = this.floorRangeOptions.filter(fr => fr.selected);
    if (selectedFloorRanges.length > 0) {
      return floorPlans.filter(floorPlan => floorPlan.floorSections.some(level => {
        return selectedFloorRanges.flatMap(fr => fr.floorSections).includes(level);
      }));
    }
    return floorPlans;
  }

  private initializeLevelOptions() {
    const maxFloor = Math.max(
      ...this.floorPlans.reduce((levels, floorPlan) => levels.concat(floorPlan.floorSections), [])
      .map(level => parseInt(level))
      .filter(level => !Number.isNaN(level))
    );
    for (let i = 0; i < maxFloor; i += 10) {
      this.levelOptions.push({
        min: i + 1,
        max: i + 10,
        selected: false
      });
    }
  }

  private initializeFloorRangeOptions() {
    this.floorRangeOptions = this.floorRanges.map(fr => ({...fr, selected: false}));
  }

  private initializeUnitTypes() {
    this.unitTypes = this.floorPlans.map(floorPlan => {
      return {
        bed: floorPlan.bedrooms,
        den: floorPlan.den,
        selected: false
      }
    }).filter((value, index, array) => index === array.findIndex((t) => (
      t.bed === value.bed && t.den === value.den
    )));
    this.unitTypes.sort((a, b) => {
      if (a.bed < b.bed) {
        return -1;
      } else if (a.bed < b.bed) {
        return 1;
      } else {
        return a.den < b.den ? -1 : 1;
      }
    });
  }

  ngOnInit(): void {
    if (this.isFloorPlanBased) {
      this.initializeFloorRangeOptions();
    } else {
      this.initializeLevelOptions();
    }
    this.initializeUnitTypes();
    this.maxPrice = new FormControl(undefined, [Validators.min(this.minFloorPlanPrice)]);
    this.selectedFloorPlans = this.floorPlans.map(floorPlan => {
      return {...floorPlan, selected: false}
    });
  }

}
