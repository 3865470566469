import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsFormat'
})
export class ThousandsFormatPipe implements PipeTransform {

  transform(value: number): string {
    const suffix = ['', 'K', 'M', 'B'];
    let i = 0;
    let newValue = value;
    while (newValue > 1000 && i < suffix.length - 1) {
      newValue = Math.floor(newValue / 1000);
      i++;
    }
    return `$ ${newValue} ${suffix[i]}`;
  }

}
