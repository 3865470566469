import {
  Injectable,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { BaseInjectorConstructor } from 'ng-mix';
import { Option } from '../../models/generic/Option';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { HasSubscriptionMixin } from '../../mixins/has-subscription.mixin';

export const AvTypeaheadMixin = <Tbase extends BaseInjectorConstructor>(superClass: Tbase) => {

  @Injectable()
  abstract class AvTypeahead extends HasSubscriptionMixin(superClass) implements OnInit {
    @Input() placeholder: string;
    @Input() options: Option<any>[];
    @Input() selectedOptions?: Option<any>[];
    @Input() loadingOptions? = false;
    @Input() debounceTime = 500;

    @Output() typeaheadChange = new EventEmitter<string>();

    searchControl = new FormControl();
    filteredOptions$: Observable<any[]>;

    private _filter(value: string): Option<any>[] {
      if (typeof value !== 'string' || !this.options) {
        return undefined;
      }

      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.label.toLowerCase().includes(filterValue));
    }

    ngOnInit(): void {
      super.ngOnInit();

      this.filteredOptions$ = this.searchControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );

      this.subscription.add(this.searchControl.valueChanges.pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
      ).subscribe((value) => {
        this.typeaheadChange.emit(value);
      }));
    }		
  }

  return AvTypeahead;
}