import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationDialogService } from '../../services/dialogs/confirmation-dialog.service';
import { MediaBreakpoints } from '../../enums/MediaBreakpoints';

@Component({
  selector: 'lib-main-side-nav',
  templateUrl: './main-side-nav.component.html',
  styleUrls: ['./main-side-nav.component.scss']
})
export class MainSideNavComponent implements OnInit {

  @Input() title: string;
  @Input() navItems: INavigationItem[];
  @Output() logout: EventEmitter<any> = new EventEmitter();

  menuCollapsed = window.matchMedia(
    `(max-width: ${MediaBreakpoints.desktop - 1}px)`
  ).matches;
  hoveredNavItem: INavigationItem;
  clickedNavItem: INavigationItem;
  currentNavElement: any; // nav submenu dynamically displayed on click
  newHeight = null;

  constructor(
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  toggleSideNav(): void {
    this.menuCollapsed = !this.menuCollapsed;
    if (this.menuCollapsed) {
      this.navItems.map(navItem=>{
        navItem.isExpanded = false;
      })
    } else {
      this.hoveredNavItem = null;
      this.clickedNavItem = null;
      this.currentNavElement = null;
    }
  }

  onLogout(): void {
    this.confirmationDialogService.openDialog({
      title: 'GENERIC.LOG_OUT_QUESTION',
      message: 'GENERIC.LOG_OUT_CONFIRMATION',
      no: 'GENERIC.NO'
    }).afterClosed().subscribe(response => {
      if (response) {
        this.logout.emit();
      }
    })
  }

  onClickCollapsable(navItem: INavigationItem): void {
    if (this.menuCollapsed) {
      if (this.clickedNavItem === navItem) {
        this.clickedNavItem = null;
        this.hoveredNavItem = null;
      } else {
        this.clickedNavItem = navItem;
        this.hoveredNavItem = navItem;
      }
    } else {
      navItem.isExpanded=!navItem.isExpanded
    }
  }

  onMouseOver(navItem: INavigationItem): void {
    if (!this.menuCollapsed) return;
    if (this.clickedNavItem && this.clickedNavItem !== navItem) {
      // user hovered on another icon other than what was clicked
      this.clickedNavItem = null;
    }
    this.hoveredNavItem = navItem;
  }
  onMouseOut(navItem: INavigationItem): void {
    if (!this.menuCollapsed) return;

    if (this.clickedNavItem === navItem) {
      // retain
    } else {
      // reset
      this.hoveredNavItem = null;
      this.currentNavElement = null;
      this.newHeight = null;
    }
  }

  getStyleOnHover(navItem: INavigationItem): any {

    if (this.hoveredNavItem === navItem) {
      return {
        'position': 'absolute',
        'left': '56px',
        'display': 'block',
        'margin-top': '-40px',
        'z-index': '999',
        'padding': '8px 20px',
        'background-color': 'white',
        'box-shadow': '0 4px 8px 0 rgba(192, 190, 190, 0.5)'
      }

    };
    return {
      'display': 'none'
    }

  }

  getStyleOnClick(navItem: INavigationItem) {
    if (this.clickedNavItem === navItem) {

      // allow DOM repaint to determine height
      setTimeout(() => {
        this.getCurrentNavElementHeight(navItem);
      }, 10);

      return {
        'overflow-y': 'auto',
        'position': 'absolute',
        'left': '56px',
        'display': 'block',
        'margin-top': '6px',
        'margin-bottom': '12px',
        'z-index': '999',
        'padding': '8px 20px',
        'background-color': 'white',
        'box-shadow': '0 4px 8px 0 rgba(192, 190, 190, 0.5)',
        'height': this.newHeight
      }
    }
    return {
      'display': 'none'
    }
  } 

  getCurrentNavElementHeight(navItem: INavigationItem): void {
    if (this.newHeight) return;
    let height = 'auto';
    if (this.clickedNavItem === navItem) {
      if (!this.currentNavElement) {
        // query elements after runtime re-render
        const navSubItems = document.querySelectorAll('div.nav-sub-item');
        navSubItems.forEach((item)=>{
          if (item.id === navItem.id) {
            this.currentNavElement = item;
          }
        })
        const maxHeight = document.documentElement.clientHeight - this.currentNavElement.offsetTop - 68;
        if (this.currentNavElement.offsetHeight > maxHeight) {
          height = maxHeight + 'px';
        }
      }
    }
    this.newHeight = height;
  }

  getWidth(): Object {
    return {'width': this.menuCollapsed ? '64px' : '243px'}
  }

  ngOnInit(): void {
  }

}

export interface INavigationItem {
  id?: string;
  tagId?: string;
  label?: string;
  type: 'basic' | 'group' | 'collapsable' | 'divider';
  isHidden?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  tooltip?: string;
  onClick?: (navItem: INavigationItem) => void;
  link?: string;
  isExternalLink?: boolean;
  target?: '_blank' | '_self' | '_top' | 'parent'
  icon?: string;
  children?: INavigationItem[];
  meta?: any;
  isExpanded?: boolean;
}