export const environment = {
  production: 'false',
  authority: 'https://avesdo-sso.azurewebsites.net', // SSO Server URL
  analyticsUrl: 'https://avesdo-analytics.azurewebsites.net', // Analytics API URL
  coreBaseUrl: 'https://avesdo.net', // Core Avesdo URL
  imgServerUrl: 'https://yongleprodcanada.blob.core.windows.net',
  mktplaceBaseUrl: 'https://realtor.offplan.homes',
  mktplaceApiUrl: 'https://avesdo-offers-api.azurewebsites.net/api', // MP API URL
  coreMarketplaceApiUrl: 'https://avesdo-core-mp-api.azurewebsites.net',
  crmBaseUrl: 'https://crm.avesdo.net',
  crmApiUrl: 'https://avesdo-apigateway.azurewebsites.net',
  environmentType: 'Prod'
};
