import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disclosure-email-reroute',
  template: '',
  styleUrls: ['./disclosure-email-reroute.component.scss']
})
export class DisclosureEmailRerouteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const { search } = window.location;

    //Prod hotfix to redirect
    window.location.href = `https://yongleprod.azurewebsites.net/Disclosure/EmailLink${search}`
  }

}
