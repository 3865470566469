import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-av-router-link',
  templateUrl: './av-router-link.component.html',
  styleUrls: ['./av-router-link.component.css']
})
export class AvRouterLinkComponent implements OnInit {
  @Input() componentConfig: AvRouterLinkComponentConfig
  @Input() label: any;
  @Input() route: any;
  constructor() { }

  ngOnInit(): void {
    if (!this.label) {
      this.label = '\xa0\xa0\xa0\xa0\xa0';// Add &nbsp; so link is still clickable
    }
  }
}

export interface AvRouterLinkComponentConfig {
  route: string[];
  label: string;
}
