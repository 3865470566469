import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-currency-display',
  templateUrl: './currency-display.component.html',
  styleUrls: ['./currency-display.component.scss']
})
export class CurrencyDisplayComponent implements OnInit {

  @Input() displayData: number;
  @Input() showDecimal: 'always' | 'never' | 'auto' = 'auto';

  constructor() { }

  get digitsInfo() {
    switch (this.showDecimal) {
      case 'always':
        return '1.2-2';
      case 'never':
        return '1.0-0';
      case 'auto':
      default:
        return '1.0';
    }
  }

  ngOnInit(): void {
  }

}
