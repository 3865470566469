import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BasicImageComponent } from '../../components/basic-image/basic-image.component';

@Injectable({
  providedIn: 'root',
})
export class ImagePreviewService {
  constructor(private dialog: MatDialog) {}

  openBasicImage(imgSrc: string, imgAltName: string) {
    this.dialog.open(BasicImageComponent, {
      data: {
        imageSrc: imgSrc,
        imageAltName: imgAltName,
      },
      panelClass: 'av-image-preview-container',
      backdropClass: 'av-image-preview-backdrop',
    });
  }
}
