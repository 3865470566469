import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatOptgroup, MatOption, MatOptionParentComponent, MatPseudoCheckboxState, MAT_OPTION_PARENT_COMPONENT } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-mat-select-all-option',
  templateUrl: './mat-select-all-option.component.html',
  styleUrls: ['./mat-select-all-option.component.css']
})
export class MatSelectAllOptionComponent extends MatOption implements OnInit {

  protected unsubscribe: Subject<any>;
  title: string = 'GENERIC.SELECT_ALL'

  @Input() control: AbstractControl;;
  @Input() values: any[] = [];
  @Output() toggleValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class') cssClass = 'mat-option';

  @HostListener('click') toggleSelection(): void {
    this.toggleValue.emit(this.selected);
  }

  constructor(elementRef: ElementRef<HTMLElement>,
              changeDetectorRef: ChangeDetectorRef,
              @Optional() @Inject(MAT_OPTION_PARENT_COMPONENT) parent: MatOptionParentComponent,
              @Optional() group: MatOptgroup) {
    super(elementRef, changeDetectorRef, parent, group);
  }

  ngOnInit(): void {
    this.unsubscribe = new Subject<any>();

    this.refresh();

    this.control.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.refresh();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get selectedItemsCount(): number {
    return this.control && Array.isArray(this.control.value) ? this.control.value.filter(el => el !== null).length : 0;
  }

  get selectedAll(): boolean {
    return this.selectedItemsCount === this.values.length;
  }

  get selectedPartially(): boolean {
    const selectedItemsCount = this.selectedItemsCount;

    return selectedItemsCount > 0 && selectedItemsCount < this.values.length;
  }

  get checkboxState(): MatPseudoCheckboxState {
    let state: MatPseudoCheckboxState = 'unchecked';
    this.title = 'GENERIC.SELECT_ALL';

    if (this.selectedAll) {
      state = 'checked';
      this.title = 'HOME.UNSELECT_ALL';
    } else if (this.selectedPartially) {
      state = 'indeterminate';
      this.title = 'GENERIC.SELECT_ALL';
    }

    return state;
  }

  refresh(): void {
    if (this.selectedItemsCount > 0) {
      this.select();
    } else {
      this.deselect();
    }
  }

}
