import { Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioButtonGroupComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RadioButtonGroupComponent
    },
  ]



})
export class RadioButtonGroupComponent implements ControlValueAccessor, OnDestroy, Validator, OnInit  {

  @Input() groupLabel: string;
  @Input() customControlOptions: any[];
  @Input() customControlDisplayKey?: string;
  @Input() initialValue: any;
  @Input() isRequired: boolean = true;
  @Output() change: EventEmitter<any> = new EventEmitter();

  rbControl = new FormControl();
  
  selectedOption: any = null;
  onChange = (option) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  
  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor() { }
  
  validate(control: AbstractControl): ValidationErrors {
    if (!this.isRequired) return;
    const selectedValue = control.value;
    if (!selectedValue) {
      return {
        required: true
      };
    }
  }
  
  writeValue(obj: any): void {
    this.selectedOption = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  
  ngOnInit(): void {
    this.selectedOption = this.initialValue;
    this.rbControl.setValue(this.selectedOption);

    this.rbControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value=>{
      this.onChange(value);
      this.change.emit(value);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
}
