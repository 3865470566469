import { Action, createReducer, on } from '@ngrx/store';
import {
  fetchPermissionFail,
  fetchPermissionSuccess,
  fetchUserClaimsFail,
  fetchUserClaimsSuccess,
  loadApplicationPermission,
  logInSuccess,
  logOut,
  propogateUserClaims,
  refreshToken,
  refreshUser,
  refreshUserDeveloperId,
} from './auth.actions';
import { AuthUser } from '@avesdo-common/src/lib/models/user-models/AuthUser';
import { fetchBuildingsByOrganizationSuccess } from '../../feature/sales/modules/shared/redux/BuildingOrganization/building-organization.actions';
import { Permission } from '@avesdo-common/src/lib/models/user-models/Permission';
import { UserClaimsModel } from '@avesdo-common/src/lib/models/user-models/UserClaimsModel';
import { IAuthState } from '@avesdo-common/src/lib/models/states/auth/auth-state.interface';

export const initialState: IAuthState = {
  isLoggedIn: false,
  isLoading: false,
  user: new AuthUser(),
  permission: new Permission(),
  claims: new UserClaimsModel(),
};

const authReducer = createReducer(
  initialState,
  on(logInSuccess, (state, payload) => ({
    ...state,
    user: payload.user,
    isLoading: false,
    isLoggedIn: true,
  })),

  on(logOut, (state) => ({ ...state, isLoading: true })),

  on(refreshToken, (state) => ({ ...state, isLoading: true })),

  on(fetchBuildingsByOrganizationSuccess, (state, payload) => {
    const developerId = state.user.developerId;

    const building = payload.buildings.find((bu) => {
      return bu.developerId.toString() === developerId;
    });

    const user: AuthUser = Object.assign(new AuthUser(), state.user);
    user.building = building;

    return { ...state, user };
  }),
  on(refreshUser, (state, payload) => {
    const user: AuthUser = Object.assign(new AuthUser(), state.user);
    user.developerId = payload.building.developerId;
    user.building = payload.building;
    return { ...state, user };
  }),
  on(refreshUserDeveloperId, (state, payload) => {
    const user: AuthUser = Object.assign(new AuthUser(), state.user);
    user.developerId = payload.developerId;
    return { ...state, user };
  }),
  on(loadApplicationPermission, (state) => ({
    ...state,
  })),

  on(fetchPermissionSuccess, (state, payload) => {
    return {
      ...state,
      permission: payload.permission,
    };
  }),
  on(fetchPermissionFail, (state) => {
    return {
      ...state,
      permission: new Permission(),
    };
  }),
  on(propogateUserClaims, (state) => ({ ...state })),
  on(fetchUserClaimsSuccess, (state, payload) => {
    return {
      ...state,
      claims: payload.claims,
    };
  }),
  on(fetchUserClaimsFail, (state) => {
    return {
      ...state,
      userpermissions: new UserClaimsModel(),
    };
  })
);

export function reducer(state: IAuthState | undefined, action: Action) {
  return authReducer(state, action);
}
