import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lib-av-table-textfield',
  templateUrl: './av-table-textfield.component.html',
  styleUrls: ['./av-table-textfield.component.scss']
})
export class AvTableTextfieldComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() hide?: boolean;
  @Input() formControl: FormControl;
  @Input() errorMessages?: {[errorCode: string]: string};
  @Input() disabled?: boolean;
  @Input() onChangeData?: any;
  @Output() change = new EventEmitter<any>();

  constructor() { }

  get data() {
    return {formControl: this.formControl, data: this.onChangeData}
  }

  private setDisabled() {
    if (this.disabled) {
      this.formControl?.disable();
      return;
    }
    this.formControl?.enable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.setDisabled();
    }
  }

  ngOnInit(): void {
    this.setDisabled();
  }

}
