import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { genericNoopAction } from '@avesdo-common/src/lib/redux/generic.actions';
import {
  fetchPublicItems,
  FETCH_PUBLIC_ITEMS,
  FETCH_PUBLIC_ITEMS_IF_NEEDED,
  fetchPublicItemsSuccess,
  fetchPublicItemsFail
} from './public-items.actions';
import { FormItemsService } from '@avesdo-common/src/lib/feature/custom-forms/services/form-items.service';
import { getPublicItemsState } from './public-items.selectors';

@Injectable()
export class PublicItemsEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private formItemsSrvc: FormItemsService
  ) { }

  fetchPublicItemsIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_PUBLIC_ITEMS_IF_NEEDED),
      withLatestFrom(this.store.select(getPublicItemsState)),
      switchMap(([{ id }, { publicItemsById, isLoadingById }]) => {
        if (publicItemsById[id] || isLoadingById[id])
          return of(genericNoopAction())

        return of(fetchPublicItems({ id }));
      })
    )
  );

  fetchPublicItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_PUBLIC_ITEMS),
      switchMap(({ id }) => {
        return this.getPublicItems(id);
      })
    )
  );

  getPublicItems(id: string): Observable<Action> {
    return this.formItemsSrvc.getPublicItems(id)
      .pipe(
        mergeMap(publicItems => of(fetchPublicItemsSuccess({ id, publicItems }))), 
        catchError(() => of(fetchPublicItemsFail({ id })))
      );
  }
}
