import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'lib-heatmap-toggle',
  templateUrl: './heatmap-toggle.component.html',
  styleUrls: ['./heatmap-toggle.component.scss']
})
export class HeatmapToggleComponent implements OnInit {

  @Input() displayMode?: 'row' | 'col' = 'row';
  @Input() selectionFormsMode?:boolean = false;
  @Input() selectionForms?: any[];
  @Output() selectChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() checkboxChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() optionsChanged: EventEmitter<AllOptions> = new EventEmitter();

  entireProject = false;
  includeZero = true;
  defaultSelection = {id: null};
  selection = null;

  constructor() { }

  getDisplayClass(): string {
    if (this.displayMode==='row') {
      return 'd-flex flex-row align-items-center';
    } else {
      return 'd-flex flex-column';
    }
  }

  onSelectChanged(newOption: MatSelectChange) {
    this.entireProject = newOption.value;
    this.selectChanged.emit(this.entireProject);
    this.emitAllChanges();
  }

  onCheckboxChanged(newOption: MatCheckboxChange) {
    this.includeZero = newOption.checked;
    this.checkboxChanged.emit(this.includeZero);
    this.emitAllChanges();
  }

  onSelectionFormChanged(selection: any): void {
    this.selection = selection;
    this.emitAllChanges();
  }

  private emitAllChanges() {
    this.optionsChanged.emit({entireProject: this.entireProject, includeZero: this.includeZero, selection: this.selection});
  }

  ngOnInit(): void {
  }

}

export interface AllOptions {
  entireProject: boolean,
  includeZero: boolean,
  selection?: any,
}