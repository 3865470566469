import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Base } from 'ng-mix';
import { AvFormControlMixin } from '../../mixins/form-control.mixin';

@Component({
  selector: 'lib-currency-form-input',
  templateUrl: './currency-form-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CurrencyFormInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CurrencyFormInputComponent
    },
  ],
  inputs: [
    'customFormControl',
    'initialValue',
    'isRequired',
    'placeholder',
  ]
})
export class CurrencyFormInputComponent extends AvFormControlMixin(Base) implements OnInit {

  @Input() currencyPrefix: string = '';
  @Output() currencyChange = new EventEmitter<Number>();

  constructor(public inj: Injector) { super(inj); }

  ngOnInit(): void {
    super.ngOnInit();
    this.middlewareFunction = (val) =>{
      return +val;
    }
  }

}
