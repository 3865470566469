import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-av-backdrop',
  templateUrl: './av-backdrop.component.html',
  styleUrls: ['./av-backdrop.component.scss']
})
export class AvBackdropComponent {
  @Input() active = false;
  @Output() backdropClick = new EventEmitter();

  constructor() { }
}
