import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvTableMenuButton } from '../../models/AvTableMenuButton';

export interface AvTableEllipsisEvent {
  button: AvTableMenuButton;
  data: any;
}

@Component({
  selector: 'lib-av-table-ellipsis',
  templateUrl: './av-table-ellipsis.component.html',
  styleUrls: ['./av-table-ellipsis.component.scss']
})
export class AvTableEllipsisComponent implements OnInit {
  @Input() data: any;
  @Input() buttons: AvTableMenuButton[];
  @Output() btnClick = new EventEmitter<AvTableEllipsisEvent>();

  constructor() { }

  clickButton(button) {
    this.btnClick.emit({
      button,
      data: this.data
    });
  }

  clickInnerButton({button, data}) {
    this.btnClick.emit({
      button,
      data
    });
  }

  ngOnInit(): void {
  }
}
