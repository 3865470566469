export class OutputFactory<T, S> {
	private factoryFunction:(datum: T, event$: S) => void ;

	constructor(factoryFunction: (datum: T, event$: S) => void) {
		this.factoryFunction = factoryFunction;
	}

	onEvent(datum: T, event: S) {
		this.factoryFunction(datum, event);
	}
} 