import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@ng-new/src/app/shared/services/config.service';

@Pipe({
  name: 'translateLocale',
})
@Injectable({
  providedIn: 'root',
})
export class TranslateLocalePipe implements PipeTransform {

  constructor(private translateService: TranslateService, private configService: ConfigService) {
  }

  transform(value: string | {default: string, [area: string]: string}, area?: string) {
    area = area ?? this.configService.yongleAppConstant.area;
    if (typeof value === 'string' || value instanceof String) {
      return this.transformStr(value as string, area);
    } else {
      return this.transformObj(value, area);
    }
  }

  transformStr(str: string, area: string) {
    const key = str + '.' + area;
    const translation = this.translateService.instant(key);
    if (translation === key)
      return this.translateService.instant(str);
    return translation;
  }

  transformObj(obj: {default: string, [area: string]: string}, area: string) {
    const key = obj[area] ?? '.';
    const translation = this.translateService.instant(key);
    if (translation === key)
      return this.translateService.instant(obj.default);
    return translation;
  }
}
