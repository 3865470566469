import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormioModule } from 'angular-formio';
import { FormRendererComponent } from './components/form-renderer/form-renderer.component';
import {TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MaterialModule } from '@avesdo-common/src/lib/material.module';
import { AvCustomFieldsComponent } from './components/av-custom-fields/av-custom-fields.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';

//export Required for Aot
export function CustomLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/locale/', '.json');
}


// @dynamic
@NgModule({
  declarations: [
    FormRendererComponent,
    AvCustomFieldsComponent
  ],
  imports: [
    CommonModule,
    FormioModule,
    MaterialModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CustomLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports:[
    FormRendererComponent,
    AvCustomFieldsComponent
  ]
})
export class CustomFormsModule { }
