import { Component, Injector } from '@angular/core';
import { UserpilotMixin } from '@avesdo-common/src/lib/mixins/userpilot.mixin';
import { Base } from 'ng-mix';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends UserpilotMixin(Base) {
  title = 'ng-new';

  constructor(private inj: Injector) { super(inj) }
}
