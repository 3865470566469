import { Component, Input } from '@angular/core';
import { ImagePreviewService } from '../../services/images/image-preview.service';

@Component({
  selector: 'lib-image-preview-link',
  templateUrl: './image-preview-link.component.html',
  styleUrls: ['./image-preview-link.component.css']
})
export class ImagePreviewLinkComponent {
  @Input() componentConfig: ImagePreviewLinkComponentConfig;

  previewImg() {
    const { imgSrc, imgAltName } = this.componentConfig;
    this.imagePreviewService.openBasicImage(imgSrc, imgAltName);
  }

  constructor(public imagePreviewService: ImagePreviewService) {}
}

export interface ImagePreviewLinkComponentConfig {
  imgSrc: string;
  imgAltName: string;
}
