import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AvTableComponent} from './components/av-table.component';
import {AvTableCellDirective} from './directives/av-table-cell.directive';
import {AvTableFilterDirective} from './directives/av-table-filter.directive';
import {AvTextFilterComponent} from './components/av-text-filter/av-text-filter.component';
import {AvMultiSelectFilterComponent} from './components/av-multi-select-filter/av-multi-select-filter.component';
import {AvRangeFilterComponent} from './components/av-range-filter/av-range-filter.component';
import {AvTableChipComponent} from './components/av-table-chip/av-table-chip.component';
import {AvRangeChipsComponent} from './components/av-range-chips/av-range-chips.component';
import {AvMultiSelectChipComponent} from './components/av-multi-select-chip/av-multi-select-chip.component';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { AvMultiSelectLargeComponent } from './components/av-multi-select-large/av-multi-select-large.component';
import { AvTableButtonComponent } from './components/av-table-button/av-table-button.component';
import { AvTableEllipsisComponent } from './components/av-table-ellipsis/av-table-ellipsis.component';
import { AvPlainTextComponent } from './components/av-plain-text/av-plain-text.component';
import { AvTextButtonComponent } from './components/av-text-button/av-text-button.component';
import { AvDynamicHtmlBlockComponent } from '../../components/av-dynamic-html-block/av-dynamic-html-block.component';
import { MatRippleModule } from '@angular/material/core';
import { AvTableDateComponent } from './components/av-table-date/av-table-date.component';
import { AvUserDetailsComponent } from './components/av-user-details/av-user-details.component';
import { AvTableTextfieldComponent } from './components/av-table-textfield/av-table-textfield.component';
import { AvTableSelectComponent } from './components/av-table-select/av-table-select.component';
import { AvTableToggleComponent } from './components/av-table-toggle/av-table-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

//export Required for Aot
export function LoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/locale/', '.json');
}

const entryComponents = [
  AvTextFilterComponent,
  AvMultiSelectFilterComponent,
  AvRangeFilterComponent,
  AvTableChipComponent,
  AvMultiSelectLargeComponent
];

export const materialModules = [
  MatInputModule,
  MatButtonModule,
  MatSelectModule,
  MatCardModule,
  MatRadioModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatMenuModule,
  MatIconModule,
  MatTableModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatPaginatorModule,
  MatSortModule,
  MatRippleModule
];

// @dynamic
@NgModule({
  declarations: [
    AvTableComponent,
    AvTableCellDirective,
    AvTableFilterDirective,
    AvTextFilterComponent,
    AvMultiSelectFilterComponent,
    AvRangeFilterComponent,
    AvTableChipComponent,
    AvRangeChipsComponent,
    AvMultiSelectChipComponent,
    AvMultiSelectLargeComponent,
    AvTableButtonComponent,
    AvTableEllipsisComponent,
    AvPlainTextComponent,
    AvTextButtonComponent,
    AvDynamicHtmlBlockComponent,
    AvTableDateComponent,
    AvUserDetailsComponent,
    AvTableTextfieldComponent,
    AvTableSelectComponent,
    AvTableToggleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LoaderFactory,
        deps: [HttpClient],
      },
    }),
    ...materialModules
  ],
  exports: [
    AvTableComponent,
    AvTableCellDirective,
    AvTableFilterDirective,
    AvTextFilterComponent,
    AvMultiSelectFilterComponent,
    AvRangeFilterComponent,
    AvTableChipComponent,
    AvRangeChipsComponent,
    AvMultiSelectChipComponent,
    AvTableButtonComponent,
    AvPlainTextComponent,
    AvDynamicHtmlBlockComponent,
    ...materialModules
  ],
  entryComponents
})
export class AvTableModule { }
