import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FloorPlan } from '@avesdo-common/src/lib/feature/selection/models/FloorPlan';
import { FloorPlanService } from '../../../services/floor-plan.service';

@Component({
  selector: 'app-floor-plan-card',
  templateUrl: './floor-plan-card.component.html',
  styleUrls: ['./floor-plan-card.component.scss']
})
export class FloorPlanCardComponent implements OnInit {
  @Input() floorPlan: FloorPlan;
  @Input() province?: string;

  @Output() openDetails = new EventEmitter<FloorPlan>();

  constructor(private floorPlanService: FloorPlanService) { }

  formatBedroomText(floorPlan: FloorPlan) {
    return this.floorPlanService.formatBedroomText(floorPlan);
  }

  ngOnInit(): void {
  }

}
