import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { fetchFeaturePermissionsIfNeeded } from '../redux/FeaturePermissions/feature-permissions.actions';
import { canActivateModule } from '../redux/FeaturePermissions/feature-permissions.selectors';
import { ModuleNames } from '../enums/ModuleNames';

export class ModulePermissionGuard {
  static forModule(moduleName: ModuleNames) {

    @Injectable({
      providedIn: 'root'
    })
    class ModulePermissionCheck implements CanActivate {

      constructor(private store: Store<any>) {}

      canActivate(): Observable<boolean> {
        this.store.dispatch(fetchFeaturePermissionsIfNeeded());

        return this.store.select(canActivateModule(moduleName))
          .pipe(filter((result) => result !== undefined));
      }
    }

    return ModulePermissionCheck

  }

}

