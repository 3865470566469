import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';
import { ConfirmationDialogService } from '@avesdo-common/src/lib/services/dialogs/confirmation-dialog.service';
import { FloorPlan } from '../../../models/FloorPlan';
import { FloorPlate } from '../../../models/FloorPlate';
import { PropertyDetail } from '../../../models/PropertyDetail';
import { MediaBreakpoints } from '@avesdo-common/src/lib/enums/MediaBreakpoints';
import { SelectionChoice } from '../../../models/SelectionChoice';
import { TranslateService } from '@ngx-translate/core';
import { FloorPlanService } from '../../../services/floor-plan.service';
import { FloorRange } from '@avesdo-common/src/lib/feature/selection/models/FloorRange';
import { CfgFloorRangeService } from '../../../services/cfg-floor-range.service';

@Component({
  selector: 'app-floor-plan-details',
  templateUrl: './floor-plan-details.component.html',
  styleUrls: ['./floor-plan-details.component.scss']
})
export class FloorPlanDetailsComponent implements OnInit {
  @Input() floorPlan: FloorPlan;
  @Input() floorPlanProperties: PropertyDetail[];
  @Input() floorPlates: FloorPlate[];
  @Input() isFloorPlanBased: boolean;
  @Input() floorRanges: FloorRange[];
  @Input() province?: string;
  @Input() viewOnly?: boolean = false;
  @Input() editChoice?: SelectionChoice;
  @Output() close = new EventEmitter();
  @Output() addChoice = new EventEmitter<SelectionChoice>();
  @Output() saveChoice = new EventEmitter<SelectionChoice>();
  
  showFloorPlan = new FormControl();
  budget: FormControl;
  selectedProperty: FormControl;
  budgetOptions = AvHelperService.generatePriceRange();
  isMobile;

  get displayImage() {
    if (this.showFloorPlan.value) {
      return {
        label: this.floorPlan.label,
        url: this.floorPlan?.floorPlanImage
      }
    } else {
      return {
        label: this.formatPropertyDisplay(this.selectedProperty.value),
        url: this.floorPlates.find(floorPlate => floorPlate.floorSection === this.selectedProperty.value.floorSection)?.plateUrl
      }
    }
  }

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private translate: TranslateService,
    private floorPlanService: FloorPlanService,
    private floorRangeService: CfgFloorRangeService
  ) { }

  @HostListener('window:resize')
  checkForDevice() {
    this.isMobile = window.matchMedia(
      `(max-width: ${MediaBreakpoints.tablet - 1}px)`
    ).matches;
  }

  formatBedroomText(floorPlan: FloorPlan) {
    return this.floorPlanService.formatBedroomText(floorPlan);
  }

  expandImage() {
    this.confirmationDialogService.openDialog({
      title: this.displayImage.label,
      message: `<img class="w-100" src="${this.displayImage.url}"/>`,
      yes: 'GENERIC.CLOSE'
    },
    window.innerWidth * 0.9
    );
  }

  formatPropertyDisplay(property: PropertyDetail) {
    if (this.isFloorPlanBased)
      return this.floorRangeService.getFloorRangeByProperty(this.floorRanges, property).floorRangeLabel;
    return `${this.translate.instant('GENERIC.UNIT')} ${property.unitNumber}, ${this.translate.instant('HOME.FLOOR')} ${property.floorSection}`;
  }

  onSubmit(): void {
    const selectionChoice: SelectionChoice = {
      propertyId: this.selectedProperty.value.id,
      budget: this.budget.value
    }
    if (this.editChoice) {
      selectionChoice.order = this.editChoice.order;
      this.saveChoice.emit(selectionChoice);
    } else {
      this.addChoice.emit(selectionChoice);
    }
  }

  ngOnInit(): void {
    this.selectedProperty = new FormControl(undefined, Validators.required);
    this.budget = new FormControl(undefined, Validators.required);
    this.checkForDevice();
    this.showFloorPlan.setValue(true);
  }

}
