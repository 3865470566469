import { Injectable } from '@angular/core';
import { environment } from '@ng-new/src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient
    ) { }

  getOrganizationAndMarketingCompany(): Observable<[]> {
    const url = `${environment.crmApiUrl}/Contracts/Admin/OrganizationAndMarketingCompany`;

    return this.http.get<any>(url).pipe(
      handleCoreApiResponse,
      map(properties => properties.data),
      catchError(err => throwError(err)));
  }

  getOrganizationsWithProjects(): Observable<any[]> {
    const url = `${environment.crmApiUrl}/SalesAggregator/Organization/marketing`;

    return this.http.get<any>(url).pipe(
      handleCoreApiResponse,
      map(properties => properties.data),
      catchError(err => throwError(err)));
  }

  updateOrganizations(data: any): Observable<any> {
    const url = `${environment.crmApiUrl}/Contracts/Admin/UpdateOrganizations`;

    return this.http.post<any>(url, data);
  }

  getAllFeatures(): Observable<any[]> {
    const url = `${environment.crmApiUrl}/Contracts/Feature/GetAll`;

    return this.http.get<any>(url).pipe(
      handleCoreApiResponse,
      map(response => response.data),
      catchError(err => throwError(err)));
  }

  getFeaturesById(organizationId: string, developerId: string): any {
    const url = `${environment.crmApiUrl}/Contracts/FeatureOrganizationProjectAssociation/GetByOrganizationAndDeveloperId`;
    const params = {
      organizationId,
      developerId
    }

    return this.http.post<any>(url, params).pipe(
      handleCoreApiResponse,
      map(response => response.data),
      catchError(err => throwError(err)));
  }

  updateOrganizationFeatures(organizationId: string, developerId: string, FeatureIds: number[]) {
    const url = `${environment.crmApiUrl}/Contracts/FeatureOrganizationProjectAssociation/UpsertFeatures`;
    const params = {
      command: {
        DeveloperId: developerId,
        OrganizationId: organizationId,
        FeatureIds
      }
    }

    return this.http.post<any>(url, params).pipe(
      handleCoreApiResponse,
      catchError(err => throwError(err)));
  }

  getUserLookup(email: string): any {
    const url = `${environment.crmApiUrl}/SalesAggregator/UserClaim/lookup`;
    const params = {
      email: email
    }

    return this.http.post<any>(url, params).pipe(
      handleCoreApiResponse,
      map(response => response.data),
      catchError(err => throwError(err)));
  }
}
