import { Component } from '@angular/core';
import { ImagePreviewService } from '../../../services/images/image-preview.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'lib-ag-img-cell-popup',
  templateUrl: './ag-img-cell-popup.component.html',
  styleUrls: ['./ag-img-cell-popup.component.scss']
})
export class AgImgCellPopupComponent implements ICellRendererAngularComp {
  private params: any;
  imgSrc: string;

  constructor(private imagePreviewServ: ImagePreviewService) {}

  agInit(params: any): void {
    this.params = params;
    this.imgSrc = this.params.value;
  }

  openImage() {
    // magic string 'image popup' is just for aria label
    this.imagePreviewServ.openBasicImage(this.imgSrc, 'image popup');
  }

  onChange(checked: boolean) {}

  refresh(params: any): boolean {
    return false;
  }
}
