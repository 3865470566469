import {
    Component, 
    Injector
  } from '@angular/core';
  import { AsyncButtonMixin } from './async-button.mixin';
  import { Base } from 'ng-mix';
  
  @Component({
    selector: 'lib-async-button-stroked',
    templateUrl: './async-button-stroked.component.html',
    styleUrls: ['./async-button.component.scss'],
    inputs: ['disabled', 'isLoading', 'color'],
    outputs: ['btnClick']
  })
  export class AsyncButtonStrokedComponent extends AsyncButtonMixin(Base) {
    constructor(inj: Injector) { super(inj); }
  }
  