import { createReducer, on, Action } from '@ngrx/store';
import { PublicItem } from '@avesdo-common/src/lib/feature/custom-forms/models/PublicItem';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import {
  fetchPublicItems,
  fetchPublicItemsSuccess,
  fetchPublicItemsFail
} from './public-items.actions';

export interface IPublicItemsState {
  isLoadingById: Dictionary<boolean>;
  publicItemsById: Dictionary<PublicItem[]>;
}

export const initialState: IPublicItemsState = {
  isLoadingById: {},
  publicItemsById: {}
};

export const publicItemsReducer = createReducer(
  initialState,
  on(fetchPublicItems, (state, payload) => {
    const loading = {};
    loading[payload.id] = true;

    return {
      ...state,
      isLoadingById: {
        ...state.isLoadingById,
        ...loading
      }
    };
  }),
  on(fetchPublicItemsSuccess, (state, payload) => {
    const isLoadingById = {
      ...state.isLoadingById
    };;

    isLoadingById[payload.id] = false;

    const publicItemsById = {
      ...state.publicItemsById
    };

    publicItemsById[payload.id] = payload.publicItems;

    return {
      isLoadingById,
      publicItemsById
    };
  }),
  on(fetchPublicItemsFail, (state, payload) => {
    const isLoadingById = {};
    isLoadingById[payload.id] = false;

    return {
      ...state,
      ...isLoadingById
    };
  })
);

export function PublicItemsFn(
  state: IPublicItemsState | undefined,
  action: Action
) {
  return publicItemsReducer(state, action);
}
