import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularjsWrapperComponent } from '@ng-new/src/app/components/angularjs-wrapper/angularjs-wrapper.component';
import { MainComponent } from '@ng-new/src/app/main/main.component';
import { legacyRoutes } from '@ng-new/src/app/legacyRoutes';
import { SelectBuildingComponent } from './components/select-building/select-building.component';
import { NgNewWrapperComponent } from './components/ng-new-wrapper/ng-new-wrapper.component';
import { ModulePermissionGuard } from './shared/guards/module-permission.guard';
import { ModuleNames } from './shared/enums/ModuleNames';
import { AuthGuard } from './guards/auth.guard';
import { BuildingSelectedGuard } from './guards/building-selected.guard';
import { CallbackComponent } from './components/callback/callback.component';
import { RequiredSettingsResolver } from './resolvers/required-settings.resolver';
import { SilentRenewComponent } from './components/silentRenew/silentRenew.component';
import { ExternalRegisterInfoResolver } from './shared/resolvers/external-register-info.resolver';
import { VirtualLineUpInfoResolver } from './shared/resolvers/virtual-line-up-info.resolver';
import { LoginComponent } from './auth/components/login/login.component';
import { DisclosureEmailRerouteComponent } from './components/disclosure-email-reroute/disclosure-email-reroute.component';
import { RedirectHomeComponent } from './components/redirect-home/redirect-home.component';
import { PostSaleFeatureGuard } from './shared/guards/post-sale-feature.guard';
import { SurveyComponent } from './components/survey/survey.component';

const routes: Routes = [
  {
    path: 'Disclosure/EmailLink',
    component: DisclosureEmailRerouteComponent
  },
  {
    path: 'Account/Login',
    component: LoginComponent
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'silentRenew',
    component: SilentRenewComponent,
  },
  {
    path: 'redirectHome/:pageType',
    children: [{
      path: '',
      component: RedirectHomeComponent
    }, {
      path: ':id/:securityToken',
      component: RedirectHomeComponent
    }]
  },
  {
    path: 'survey',
    component: SurveyComponent
  },
  {
    path: 'Register/NewUser',
    component: AngularjsWrapperComponent,
    resolve: {
      externalRegisterInfo: ExternalRegisterInfoResolver,
    },
    data: {
      showRealtor: true
    }
  },
  {
    path: 'Register/NewPurchaser',
    component: AngularjsWrapperComponent,
    resolve: {
      externalRegisterInfo: ExternalRegisterInfoResolver,
    },
    data: {
      showRealtor: false
    }
  },
  {
    path: 'Register/VirtualLineUp',
    component: AngularjsWrapperComponent,
    resolve: {
      virtualLineUpInfo: VirtualLineUpInfoResolver
    },
  },
  {
    path: 'select-building',
    component: SelectBuildingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'leads/forms',
    loadChildren: () => import('./feature/leads/modules/forms/forms.module').then(m => m.FormsModule)
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, BuildingSelectedGuard],
    resolve: {
      requiredSettings: RequiredSettingsResolver
    },
    children: [
      {
        path: '',
        component: AngularjsWrapperComponent,
        children: legacyRoutes,
      },
      {
        path: '',
        component: NgNewWrapperComponent,
        children: [
          {
            path: 'closing',
            canActivate: [ModulePermissionGuard.forModule(ModuleNames.Closing)],
            loadChildren: () => import('./feature/closing/closing.module').then(m => m.ClosingModule)
          },
          {
            path: 'sales',
            canActivate: [ModulePermissionGuard.forModule(ModuleNames.Sales)],
            loadChildren: () => import('./feature/sales/sales.module').then(m => m.SalesModule)
          },
          {
            path: 'leads',
            canActivate: [ModulePermissionGuard.forModule(ModuleNames.Leads)],
            loadChildren: () => import('./feature/leads/leads.module').then(m => m.LeadsModule)
          },
          {
            path: 'contracts',
            loadChildren: () => import('./feature/contracts-new/contracts.module').then(m=>m.ContractsModule)
          },
          {
            path: 'post-sale',
            // canActivate: [PostSaleFeatureGuard],
            loadChildren: () => import('./feature/post-sale/post-sale.module').then(m=>m.PostSaleModule)
          }
        ],
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
