import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  OnDestroy
} from '@angular/core';

@Directive({
  selector: '[libMatTooltipOnEllipsis]'
})
export class MatTooltipOnEllipsisDirective implements OnDestroy {
  tooltipMessage: any = null;
  placement = 'bottom';
  delay = 100;
  tooltip: HTMLElement;
  offset = 10;
  isHidden = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @Input()
  get libMatTooltipOnEllipsis() {
    return this.tooltipMessage;
  }
  set libMatTooltipOnEllipsis(value: string) {
    this.tooltipMessage = value;
  }

  @Input()
  get libMatTooltipPlacement() {
    return this.placement;
  }
  set libMatTooltipPlacement(value: string) {
    this.placement = value;
  }

  @Input()
  get libMatTooltipHidden() {
    return this.isHidden;
  }
  set libMatTooltipHidden(value: boolean) {
    this.isHidden = value;
    if (value) this.hide();
  }

  @HostListener('mouseenter') onMouseEnter() {
    setTimeout(() => {
      this.show();
    }, this.delay);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hide();
  }

  show() {
    if (this.isHidden) return;
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  create() {
    if (this.isHidden) return;
    this.tooltip = this.renderer.createElement('div');

    // this.renderer.appendChild(
    //   this.tooltip,
    //   this.renderer.createText(this.tooltipMessage)
    // );
    this.tooltip.innerHTML = this.tooltipMessage;

    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
  }

  hide() {
    window.setTimeout(() => {
      if (this.tooltip) {
        this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
        this.renderer.removeChild(document.body, this.tooltip);
        this.tooltip = null;
      }
    }, this.delay);
  }

  setPosition() {
    const hostPos = this.elementRef.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // tslint:disable-next-line:one-variable-per-declaration
    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset + 20;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
