import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../environments/environment';

export type LegacyImageFolderType = 
  'buildingimages'
  | 'blob-snapshots'
  | 'blob-source'
  | 'blob-contract'
  | 'blob-pic-id'
  | 'blob-disclosure'
  | 'blob-floorplan'
  | 'blob-reports'
  | 'blob-mortgages'
  | 'blob-email'
  | 'plans'
  | 'floorplate'
  | 'buildingimages'
  | 'wizardimages'
  | 'acimages'
  | 'buildingmedia';

@Pipe({
  name: 'legacyImage'
})
export class LegacyImagePipe implements PipeTransform {

  transform(url: string, folderType: LegacyImageFolderType): string {
    return `${environment.crmApiUrl}/Contracts/Image/GetImage?url=${url}&folderType=${folderType}` ;
  }
}
