import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, buffer, debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[libDragDropZone]',
})
export class DragDropZoneDirective {
  @Input() allowDirectory: boolean = true;
  @Output('dropped') dropped = new EventEmitter<File[]>();
  @Output('hovered') hovered = new EventEmitter<boolean>();

  addFile = new BehaviorSubject(undefined);

  constructor() {
    const debounceAddItem$ = this.addFile.pipe(debounceTime(250));
    this.addFile.pipe(
      filter(file => file),
      buffer(debounceAddItem$)
    ).subscribe((files: File[]) => {
      if (files.length > 0)
        this.dropped.emit(files);
    });
  }

  @HostListener('drop', ['$event']) // listen to drop event from browser
  onDrop($event: any) {
    $event.preventDefault(); // prevent browser from opening new tab

    //  https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/webkitGetAsEntry
    for (let i = 0; i < $event.dataTransfer.items.length; i++) {
      let item = $event.dataTransfer.items[i].webkitGetAsEntry();
      this.scanFiles(item);
    }
    this.hovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: any) {
    $event.preventDefault();
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: any) {
    $event.preventDefault();
    this.hovered.emit(false);
  }


  private scanFiles(item: any) {
    if (item.isDirectory) {
      if (!this.allowDirectory) {
        return;
      }
      let directoryReader = item.createReader();
      directoryReader.readEntries(
        (
          entries: { name: string; isDirectory: any; createReader: () => any }[]
        ) => {
          entries.forEach(
            (entry: {
              name: string;
              isDirectory: any;
              createReader: () => any;
            }) => {
              this.scanFiles(entry);
            }
          );
        }
      );
      return;
    }
    this.convertFileEntryAsFile(item)
    return;
  }

  private convertFileEntryAsFile(item: {
    isDirectory: any;
    file: (arg0: (file: File) => void) => void;
  }): void {
    item.file((file: File) => {
      this.addFile.next(file);
    });
  }
}
