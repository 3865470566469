
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { genericNoopAction } from '@avesdo-common/src/lib/redux/generic.actions';
import {
  FETCH_FEATURE_PERMISSIONS,
  FETCH_FEATURE_PERMISSIONS_IF_NEEDED,
  fetchFeaturePermissions,
  fetchFeaturePermissionsFail,
  fetchFeaturePermissionsSuccess
} from '@ng-new/src/app/shared/redux/FeaturePermissions/feature-permissions.actions';
import { FeatureService } from '../../services/feature.service';

@Injectable()
export class FeaturePermissionsEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private featureService: FeatureService
  ) { }

  fetchFeaturePermissionsIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_FEATURE_PERMISSIONS_IF_NEEDED),
      withLatestFrom(this.store.select('featurePermissions')),
      switchMap(([action, state]) => {
        if (state.featurePermissions || state.isLoading)
          return of(genericNoopAction())

        return of(fetchFeaturePermissions());
      })
    )
  );

  fetchFeaturePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_FEATURE_PERMISSIONS),
      switchMap(() => {
        return this.getFeaturePermissions();
      })
    )
  );

  getFeaturePermissions(): Observable<Action> {
    return this.featureService.getFeaturePermissions()
      .pipe(
        mergeMap(featurePermissions => of(fetchFeaturePermissionsSuccess({ featurePermissions }))), 
        catchError(() => of(fetchFeaturePermissionsFail()))
      );
  }
}
