import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ng-new-wrapper',
  templateUrl: './ng-new-wrapper.component.html',
  styleUrls: ['./ng-new-wrapper.component.scss']
})
export class NgNewWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
