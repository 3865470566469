import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@avesdo-common/src/lib/models/generic/ApiResponse';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';
import { environment } from '@ng-new/src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EftPayment } from '@ng-new/src/app/shared/models/EftPayment';

@Injectable({
  providedIn: 'root'
})
export class EftPaymentService {

  constructor(private httpClient: HttpClient) { }

  getAllEftPayments(projectId: number): Observable<any> {
    const url = `${environment.crmApiUrl}/Payment/EftPayment/project/${projectId}`;

    return this.httpClient.get<ApiResponse<any>>(url).pipe(
      handleCoreApiResponse,
      map((response) => response.data.map(payment => new EftPayment(payment))),
      catchError((error) => throwError(error))
    );
  }

  getEftPayments(projectId: number, packageId: number): Observable<any> {
    const uri = `${environment.crmApiUrl}/SalesAggregator/EftPayment/project/${projectId}/package/${packageId}`;
    return this.httpClient.get<ApiResponse<any>>(uri).pipe(
      handleCoreApiResponse,
      map((response) => response.data.map(payment => new EftPayment(payment))),
      catchError((error) => throwError(error))
    );
  }

  createEftPayments(projectId: number, packageId: number, eftPayments): Observable<any> {
    const uri = `${environment.crmApiUrl}/SalesAggregator/EftPayment/project/${projectId}/package/${packageId}`;
    const params = {
      ProjectId: projectId,
      PackageId: packageId,
      EftPayments: eftPayments
    };
    return this.httpClient.post<ApiResponse<any>>(uri, params).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  updateEftPayments(projectId: number, packageId: number, eftPayments): Observable<any> {
    const uri = `${environment.crmApiUrl}/SalesAggregator/EftPayment/project/${projectId}/package/${packageId}`;
    const params = {
      ProjectId: projectId,
      PackageId: packageId,
      EftPayments: eftPayments
    };
    return this.httpClient.put<ApiResponse<any>>(uri, params).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  checkPaymentPayor(projectId: number, email: string): Observable<any> {
    const uri = `${environment.crmApiUrl}/SalesAggregator/EftPayment/activePaymentPayor/`;
    const params = {
      ProjectId: projectId,
      Email: email
    };
    return this.httpClient.post<ApiResponse<any>>(uri, params).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  acceptPadAgreement(id: string, securityToken: string, bankAccountId?: string): Observable<{success: boolean, message: string, hasDueDatePassed: boolean, hasBankAccountConnected: boolean}> {
    const uri = `${environment.crmApiUrl}/SalesAggregator/EftPayment/acceptPadAgreement`;
    const params = {paymentId: id, securityToken, bankAccountId};
    return this.httpClient.post<ApiResponse<any>>(uri, params).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  checkCancelable(projectId: number, eftPaymentId: number): Observable<boolean> {
    const uri = `${environment.crmApiUrl}/SalesAggregator/EftPayment/cancelable/${projectId}/${eftPaymentId}`;
    return this.httpClient.get<ApiResponse<any>>(uri).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  sendReminder(projectId: number, eftPaymentId: number): Observable<any> {
    const uri = `${environment.crmApiUrl}/SalesAggregator/EftPayment/sendReminder/${eftPaymentId}/project/${projectId}`;
    return this.httpClient.post<ApiResponse<any>>(uri, {}).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }
}
