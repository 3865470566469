import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Injector,
  Input
} from '@angular/core';
import { Option } from '../../models/generic/Option';
import { AvTypeaheadMixin } from './av-typeahead.mixin';
import { Base } from 'ng-mix';

@Component({
  selector: 'lib-av-typeahead-multi-select',
  templateUrl: './av-typeahead-multi-select.component.html',
  styleUrls: ['./av-typeahead-multi-select.component.scss'],
  inputs: [
    'placeholder',
    'options',
    'selectedOptions',
    'loadingOptions',
    'optionToggled',
    'debounceTime',
    'enableSelectAll'
  ],
  outputs: ['typeaheadChange', 'optionToggled', 'clearOptions', 'selectAllOptions']
})
export class AvTypeaheadMultiSelectComponent extends AvTypeaheadMixin(Base) implements OnInit {
  @Input() enableSelectAll = false;
  @Output() optionToggled = new EventEmitter<Option<any>>();
  @Output() clearOptions = new EventEmitter<Option<any>>();
  @Output() selectAllOptions = new EventEmitter<Option<any>>();
  constructor(public inj: Injector) { super(inj); }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.enableSelectAll) {
      this.selectAll();
    }
  }

  selectAll(): void {
    this.options.map(option => {
      this.optionToggled.emit(option);
    })
  }

}
