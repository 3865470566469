import { createReducer, on, Action } from '@ngrx/store';
import { updateActiveModule, hideCommonHeader, showCommonHeader } from './shared.actions';
import { ModuleNames } from '../enums/ModuleNames';

export interface ISharedState {
	activeModule: ModuleNames;
	hideCommonHeader: boolean;
};

export const initialState: ISharedState = {
	activeModule: null,
	hideCommonHeader: false
};

export const sharedReducer = createReducer(
	initialState,
	on(updateActiveModule, (state, action) => {
		return {
			...state,
			activeModule: action.activeModule
		}
	}),
	on(hideCommonHeader, (state, action) => {
		return {
			...state,
			hideCommonHeader: true,
		}
	}),
	on(showCommonHeader, (state, action) => {
		return {
			...state,
			hideCommonHeader: false
		}
	}),
);

export function SharedFn(
  state: ISharedState | undefined,
  action: Action
) {
  return sharedReducer(state, action);
}