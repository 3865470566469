import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lib-av-table-toggle',
  templateUrl: './av-table-toggle.component.html',
  styleUrls: ['./av-table-toggle.component.scss']
})
export class AvTableToggleComponent implements OnInit {
  @Input() label?: string = '';
  @Input() disabled?: boolean;
  @Input() formControl: FormControl;
  @Input() hide?: boolean;
  @Input() onChangeData?: any;
  @Output() change = new EventEmitter<any>();

  constructor() { }

  get data() {
    return {formControl: this.formControl, data: this.onChangeData}
  }

  ngOnInit(): void {
  }

}
