import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthUser } from '../../models/user-models/AuthUser';
import { IBuilding } from '../../models/building-models/Building';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../services/feature-toggle/app-config.service';
declare const hbspt;

export interface HeaderComponentConfig {
  user: AuthUser;
  showCrm: boolean;
  showMarketPlace: boolean;
  showClosing: boolean;
  showSelection: boolean;
  canAccessAdminPage: boolean;
  buildings$?: Observable<IBuilding[]>;
  fromMp?: boolean;
  highlightCrm?: boolean;
  highlightCore?: boolean;
  highlightOffplan?: boolean;
  highlightPostSale?: boolean;
  highlightClosing?: boolean;
  selectionsLabel?: string;
  hideHeader?: boolean;
}

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() componentConfig: HeaderComponentConfig;
  @Output() logout: EventEmitter<any> = new EventEmitter();
  @Output() goToCore: EventEmitter<any> = new EventEmitter();
  @Output() goToMarketPlace: EventEmitter<any> = new EventEmitter();
  @Output() goToCrm: EventEmitter<any> = new EventEmitter();
  @Output() goToPostSale: EventEmitter<any> = new EventEmitter();
  @Output() goToAdminPage: EventEmitter<any> = new EventEmitter();
  @Output() downloadMasterReport: EventEmitter<any> = new EventEmitter();
  @Output() goToClosing: EventEmitter<any> = new EventEmitter();
  @Output() showLink: EventEmitter<any> = new EventEmitter();
  @Output() selectBuilding: EventEmitter<IBuilding> = new EventEmitter();
  @Output() goToProfile: EventEmitter<any> = new EventEmitter();
  @Output() goToTeams: EventEmitter<any> = new EventEmitter();
  @Input() isAsideViewOpened?: boolean;
  @Input() isValidRealtorProfile?: boolean;
  highlightTeams;
  showSimplifiedRealtorRegistration;
  showPostSaleModule;
  showClosingModule;
  showSelections;
  showPostSaleDisclosure;
  showPostSaleDeposits;
  showPostSaleMasterReport;


  constructor(private appConfigService: AppConfigService) { }

  ngOnInit() {
    this.showSimplifiedRealtorRegistration = this.appConfigService.settings.showSimplifiedRealtorRegistration;
    this.showPostSaleModule = this.appConfigService.settings.showPostSaleModule;
    this.showClosingModule = this.appConfigService.settings.showClosingModule;
    this.showSelections = this.appConfigService.settings.selectionForms;
    this.showPostSaleDisclosure = this.appConfigService.settings.postSale.disclosure;
    this.showPostSaleDeposits = this.appConfigService.settings.postSale.deposits;
    this.showPostSaleMasterReport = this.appConfigService.settings.postSale.masterReport;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.highlightTeams = changes.isAsideViewOpened ? changes.isAsideViewOpened.currentValue : false;
  }

  onGoToCore() {
    this.goToCore.emit();
  }

  onGoToCrm() {
    this.goToCrm.emit();
  }

  onGoToMarketPlace(tab) {
    this.goToMarketPlace.emit(tab);
  }

  onGoToPostSale(tab) {
    this.goToPostSale.emit(tab);
  }

  onGoToAdminPage() {
    this.goToAdminPage.emit();
  }

  onDownloadMasterReport() {
    this.downloadMasterReport.emit();
  }

  onGoToClosing() {
    this.goToClosing.emit();
  }

  openTeams() {
    this.highlightTeams = this.isAsideViewOpened;
    this.goToTeams.emit();
  }

  onBuildingSelect(building: IBuilding) {
    this.selectBuilding.emit(building);
  }

  onHelpForm(event) {
    event.stopPropagation(); 
    hbspt.forms.create({
      region: "na1",
      portalId: "6064560",
      formId: "fc6136b4-a6e2-482e-9ea3-b6199aeef48c",
      target: '.header-help-menu'
    });
  }

  onLogout() {
    this.logout.emit();
  }

  redirectToProfile() {
    this.goToProfile.emit();
  }
}
