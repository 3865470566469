import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appHandleNoImage]'
})
export class HandleNoImageDirective {

  @Output() onNoImage: EventEmitter<any> = new EventEmitter();

  constructor(el: ElementRef) {
    const { nativeElement } = el;

    nativeElement.onerror = () => {
      nativeElement.onerror = null;
      nativeElement.src = 'assets/images/no_image_available.png';
      this.onNoImage.emit();
    }
  }
}
