import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-content-projection-wrapper',
  templateUrl: './content-projection-wrapper.component.html',
})
export class ContentProjectionWrapperComponent<T> implements OnInit {

  @Input() idObject: T; // can be object or primitive

  constructor(
    public elementRef: ElementRef
  ) { }

  ngOnInit(): void {
  }

}
