import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notApplicableIfEmpty'
})
export class NotApplicableIfEmptyPipe implements PipeTransform {

  transform(value: unknown): unknown {
    if (value === undefined || value === null || value === '') return 'N/A';

    return value;
  }
}
