import { CoreApiResponse } from "@avesdo-common/src/lib/models/generic/CoreApiResponse";
import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { AvHelperService } from "@avesdo-common/src/lib/services/avHelper/av-helper.service";

export const handleCoreApiResponse = (source: Observable<CoreApiResponse<any>>) => {
	return source.pipe(
		map((response) => new AvHelperService().toCamel(response)),
		tap((response) => {
		if (!response.success) throw(response.userMessage); 
	}));
}