import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AvesdoValidator } from '@avesdo-common/src/lib/validators/avesdo-validator';
import { AdminService } from '@ng-new/src/app/shared/services/admin.service';

@Component({
  selector: 'app-user-lookup',
  templateUrl: './user-lookup.component.html',
  styleUrls: ['./user-lookup.component.scss']
})
export class UserLookupComponent implements OnInit {
  emailFormControl = new FormControl('', [Validators.required, AvesdoValidator.isNotValidEmail]);
  userClaims = [];
  isLoading = false;
  noUser = false;

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
  }

  onSearch() {
    const email = this.emailFormControl.value;
    this.isLoading = true;
    this.noUser = false;
    this.adminService.getUserLookup(email).subscribe(result => {
      this.isLoading = false;
      if(!result || result.count == 0){
        this.noUser = true;
      } else {
        this.noUser = false;
        result.forEach(e => {
          if(!e.buildingName) e.buildingName = '';
        });

        result.sort((a, b) => a.buildingName?.localeCompare(b?.buildingName));
      }
      this.userClaims = result;
    });
  }

  onClear() {
    this.isLoading = false;
    this.noUser = false;
    this.userClaims = [];
    this.emailFormControl.setValue('');
  }

}
