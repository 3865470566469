import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-unit-floor-plan-column',
  templateUrl: './unit-floor-plan-column.component.html',
  styleUrls: ['./unit-floor-plan-column.component.scss']
})
export class UnitFloorPlanColumnComponent implements OnInit {

  @Input() choicesProperties: any[];
  @Input() choicesBudget: number[];
  @Input() choicesColors: any[];
  @Input() isFloorPlanBased: boolean;
  @Output() clickChoice: EventEmitter<number> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  onClickChoice(choiceIndex: number) {
    this.clickChoice.emit(choiceIndex);
  }

}
