import { AfterViewInit, Component, ElementRef, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import Chart, { ChartConfiguration } from 'chart.js/auto';

@Component({
  selector: 'av-chart',
  templateUrl: './av-chart.component.html',
  styleUrls: ['./av-chart.component.scss']
})
export class AvChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') canvas: ElementRef;
  
  @Input() chartConfigurations: ExtendedChartConfigurations = null;
  chart: Chart;

  constructor() {}

  ngAfterViewInit(): void {
    this.constructGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.chart || !changes.chartConfigurations) {
      return;
    }
    const newConfig = changes.chartConfigurations.currentValue;
    this.chart.data = newConfig.data;
    if (newConfig.options) {
      this.chart.options = newConfig.options;
    }
    this.chart.update();
  }

  private constructGraph(): void {
    const canvas = this.canvas.nativeElement as HTMLCanvasElement | null;
    const ctx = canvas?.getContext('2d');

    this.chart = new Chart(ctx, {
      type: this.chartConfigurations.type,
      data: this.chartConfigurations.data,
      options: this.chartConfigurations.options ? this.chartConfigurations.options : {
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
      },
    });
  }

}

export interface ExtendedChartConfigurations extends ChartConfiguration
{
  width?: string;
  height?: string;
  styleClass?: string;
  chartId: string;
}
