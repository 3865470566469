import { createReducer, on, Action } from '@ngrx/store';
import {
  fetchBuildingSettings, fetchBuildingSettingsFail, fetchBuildingSettingsSuccess,
  fetchIsSelectionsSetup, fetchIsSelectionsSetupFail, fetchIsSelectionsSetupSuccess
} from '@ng-new/src/app/shared/redux/BuildingSettings/building-settings.actions';
import { BuildingSettings } from '../../models/BuildingSettings';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';

export interface IBuildingSettingsState {
  isLoading: boolean;
  buildingSettings: BuildingSettings;
  statusColors: Dictionary<string>;
  isSelectionsSetup: {
    isLoading: boolean;
    isSelectionsSetup: boolean;
  };
}

export const initialState: IBuildingSettingsState = {
  isLoading: false,
  buildingSettings: null,
  statusColors: null,
  isSelectionsSetup: {
    isLoading: false,
    isSelectionsSetup: null
  }
};

const buildingSettingsReducer = createReducer(
  initialState,
  on(fetchBuildingSettings, state => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(fetchBuildingSettingsSuccess, (state, payload) => {
    const { buildingSettings } = payload;

    const statusColors = {
      'Pending': buildingSettings.priceModelColorPending,
      'Available': buildingSettings.priceModelColorAvailable,
      'Sold Rescission Period': buildingSettings.priceModelColorSoldRescissionPeriod,
      'Sold With Subjects': buildingSettings.priceModelColorSoldWithSubjects,
      'Sold Firm': buildingSettings.priceModelColorSoldFirm,
      'Unavailable': buildingSettings.priceModelColorUnavailable,
      'Reserved': buildingSettings.priceModelColorReserved,
      'Payment Hold': buildingSettings.priceModelColorPaymentHold,
      'Not Signed': buildingSettings.priceModelColorPendingNotSigned,
      'Sign In Progress': buildingSettings.priceModelColorPendingSigned,
      'Signed': buildingSettings.priceModelColorPendingExecuted,
      'Executed': buildingSettings.priceModelColorPendingExecuted,
      'Request': buildingSettings.priceModelColorPaymentHold,
      'Closed': buildingSettings.priceModelColorClosed,
      'In Closing': buildingSettings.priceModelColorInClosing
    };

    return {
      ...state,
      buildingSettings,
      statusColors,
      isLoading: false
    };
  }),
  on(fetchBuildingSettingsFail, state => ({
    ...state,
    isLoading: false
  })),
  on(fetchIsSelectionsSetup, state => {
    return {
      ...state,
      isSelectionsSetup: {
        ...state.isSelectionsSetup,
        isLoading: true
      }
    };
  }),
  on(fetchIsSelectionsSetupSuccess,(state, {isSelectionsSetup}) => {
    return {
      ...state,
      isSelectionsSetup: {
        isLoading: false,
        isSelectionsSetup
      }
    };
  }),
  on(fetchIsSelectionsSetupFail,state => {
    return {
      ...state,
      isSelectionsSetup: {
        ...state.isSelectionsSetup,
        isLoading: false,
      }
    };
  }),
);

export function BuildingSettingsFn(
  state: IBuildingSettingsState | undefined,
  action: Action
) {
  return buildingSettingsReducer(state, action);
}
