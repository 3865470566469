import {
  Directive,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { AvTableColumnConfig } from './../components/av-table.component';
import { InputFactory } from '../models/InputFactory';
import { OutputFactory } from '../models/OutputFactory';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[libAvTableCell]'
})
export class AvTableCellDirective implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  @Input() columnConfig: AvTableColumnConfig;
  @Input() datum: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
    const { viewContainerRef, columnConfig, datum } = this;
    const { component } = columnConfig;

    if (component) {
      const inputBindings = component.inputs || {};
      const outputEventHandlers = component.outputEventHandlers || {};

      const componentFactory = this.componentFactoryResolver
        .resolveComponentFactory(component.class);

      const componentRef = viewContainerRef.createComponent(componentFactory);

      for (let key in inputBindings) {
        let binding;
        if (inputBindings[key] instanceof InputFactory) {
          binding = (<InputFactory>inputBindings[key]).getInput(datum);
        } else if (inputBindings[key] === '*') { 
          binding = datum;
        } else if (typeof inputBindings[key] === 'boolean' || typeof inputBindings[key] === 'number') {
          //handle booleans & numbers
          binding = inputBindings[key];
        } else if (typeof inputBindings[key] === 'string' && (inputBindings[key].indexOf('"') >= 0)) {
          //handle strings
          binding = inputBindings[key].replace(/"/g, '');
        } else if (typeof inputBindings[key] === 'function' || typeof inputBindings[key] === 'object') {
          //handle functions, objects, arrays
          binding = inputBindings[key];
        } else {
          // bind property
          binding = AvTableCellDirective.getDescendantProp(datum, inputBindings[key]);
        }

        (<any>componentRef.instance)[key] = binding;
      }

      for (let key in outputEventHandlers) {
        this.subscriptions.add((<any>componentRef.instance)[key].subscribe((event$: any) => {
          if (outputEventHandlers[key] instanceof OutputFactory) {
            outputEventHandlers[key].onEvent(datum, event$);
          } else {
            outputEventHandlers[key](event$);
          }
        }));
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  static getDescendantProp(obj, desc) {
    var arr = desc.split('.');
    while (arr.length) {
      obj = obj[arr.shift()];
      if (obj === undefined || obj === null)
        return obj;
    }
    return obj;
  }
}
