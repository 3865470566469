import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-status-color',
  templateUrl: './status-color.component.html',
  styleUrls: ['./status-color.component.scss']
})
export class StatusColorComponent {

  @Input() label: any;
  @Input() color: any;
  constructor() { }
}
