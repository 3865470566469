import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-av-range-chips',
  templateUrl: './av-range-chips.component.html',
  styleUrls: ['./av-range-chips.component.scss']
})
export class AvRangeChipsComponent implements OnInit {
  @Input() componentConfig: {
    columnDisplayName: string;
    formGroup: FormGroup;
    isCurrency?: boolean;
  }

  minControl: AbstractControl;
  maxControl: AbstractControl;
  minLabel: string;
  maxLabel: string;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    const { formGroup, columnDisplayName } = this.componentConfig;
    this.minControl = formGroup.get('min');
    this.maxControl = formGroup.get('max');
    this.minLabel = this.translateService.instant(`${columnDisplayName}_MIN`);
    this.maxLabel = this.translateService.instant(`${columnDisplayName}_MAX`);
  }

}
