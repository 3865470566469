import {
  Component,
  Input,
  Output,
  HostListener,
  EventEmitter
} from '@angular/core';
import { FileUploadService } from '../../services/file-upload.service';
import { NotificationService } from '../../services/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-image-upload-button',
  templateUrl: './image-upload-button.component.html',
  styleUrls: ['./image-upload-button.component.scss']
})
export class ImageUploadButtonComponent {
  @Input() imageUrl: string;
  @Input() label: string;
  @Input() readonly: boolean;

  @Output() fileUploaded = new EventEmitter<string>();
  @Output() fileCleared = new EventEmitter();
  
  inputError: string = undefined;

  @HostListener('window:drop', ['$event'])
  @HostListener('window:dragover', ['$event'])
  handleWindowDrop(event) {
    event.preventDefault();
  }

  isUploading = false;

  constructor(
    private fileUploadSrvc: FileUploadService,
    private notificationSrvc: NotificationService,
    private translateSrvc: TranslateService
  ) { }

  onFileDrop(event: DragEvent) {
    if (this.readonly) return;
    this.inputError = undefined;

    event.preventDefault();
    const { dataTransfer } = event;

    if (dataTransfer.items.length > 1) {
      this.inputError = 'ERRORS.DROP_ONE_FILE_ONLY';
      this.notificationSrvc.error(this.translateSrvc.instant('ERRORS.DROP_ONE_FILE_ONLY'));
      return;
    }

    const file = dataTransfer.items[0].getAsFile();

    if (file.type.indexOf('image') < 0) {
      this.inputError = 'ERRORS.UNSUPPORTED_FILE_TYPE';
      this.notificationSrvc.error(
        this.translateSrvc.instant('ERRORS.IMAGE_TYPE_NOT_SUPPORTED', { fileName: file.name })
      );
      return;
    }
    if (file.size >= FileUploadService.MAX_FILE_UPLOAD_SIZE) {
      this.inputError = 'ERRORS.FILE_SIZE_EXCEEDED';
    }

    this.uploadFile(file);
  }

  onFileInput(fileList: FileList) {
    this.inputError = undefined;
    const file = fileList.item(0);

    if (file.type.indexOf('image') < 0) {
      this.inputError = 'ERRORS.UNSUPPORTED_FILE_TYPE';
      this.notificationSrvc.error(
        this.translateSrvc.instant('ERRORS.IMAGE_TYPE_NOT_SUPPORTED', { fileName: file.name })
      );
      return;
    }
    if (file.size >= FileUploadService.MAX_FILE_UPLOAD_SIZE) {
      this.inputError = 'ERRORS.FILE_SIZE_EXCEEDED';
    }
    this.uploadFile(file);
  }

  private uploadFile(file: File) {
    this.isUploading = true;

    this.fileUploadSrvc.uploadFile(file).subscribe(
      (imgUrl) => {
        this.isUploading = false;
        this.fileUploaded.emit(imgUrl)
      },
      (error) => {
        this.notificationSrvc.error(this.translateSrvc.instant(error || 'ERRORS.UNKNOWN'));
        this.isUploading = false;
      },
    )
  }
}
