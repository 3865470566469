import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';
import Glide from '@glidejs/glide';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';

@Component({
  selector: 'lib-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss'],
})
export class GalleryDialogComponent implements OnInit, AfterViewInit {
  glideInstance: any;
  loadedUrls: Dictionary<string> = {};

  constructor(
    public dialogRef: MatDialogRef<GalleryDialogComponent>,
    public avHelperSrvc: AvHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      imageUrls: string[];
      startIndex: number;
      sliderType?: 'slider' | 'carousel';
    }
  ) {}

  private updateLoadedUrls(index: number) {
    const { imageUrls } = this.data;

    const lastIndex = imageUrls.length - 1;

    const leftIndex = index === 0 ? lastIndex : index - 1;
    const rightIndex = index === lastIndex ? 0 : index + 1;

    this.loadedUrls[index] = imageUrls[index];
    this.loadedUrls[leftIndex] = imageUrls[leftIndex];
    this.loadedUrls[rightIndex] = imageUrls[rightIndex];
  }

  ngOnInit() {
    this.updateLoadedUrls(this.data.startIndex);
  }

  ngAfterViewInit() {
    const { startIndex, sliderType } = this.data;
    this.glideInstance = new Glide('#gallery-dialog-carousel', {
      startAt: startIndex,
      type: sliderType,
    }).mount();

    this.glideInstance.on('move.after', () => {
      this.updateLoadedUrls(this.glideInstance.index);
    });
  }
}

