import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@ng-new/src/environments/environment';
import { environment as avCommonEnvironment } from '@avesdo-common/src/lib/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthUser } from '@avesdo-common/src/lib/models/user-models/AuthUser';
import { UserRole } from '@avesdo-common/src/lib/enums/UserRole';
import { Subscription, Observable, from } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReloadPageModalComponent } from '@ng-new/src/app/components/reload-page-modal/reload-page-modal.component';
import { CookiesService } from '@ng-new/src/app/shared/services/cookies.service';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';
import { CoreApiResponse } from '@avesdo-common/src/lib/models/generic/CoreApiResponse';
import { Store } from '@ngrx/store';
import { logInSuccess } from '../redux/auth.actions';
import { UserManager, UserManagerSettings, User as OicdUser } from 'oidc-client';
import { SsoClientSetting } from '../../shared/clientSetting/ssoClientSetting';
import { LocalStorageNames } from '../../shared/enums/LocalStorageNames';
import { SelectBuildingItem } from '../../shared/models/SelectBuildingItem';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { DemandLevelSettings } from '@avesdo-common/src/lib/models/heatmap/DemandLevelSettings';
import { Router } from '@angular/router';
import { logoutUserpilot, setUserpilotMetadata } from '../../feature/sales/modules/shared/components/userpilot/userpilot';
import { AppConfigService } from '@avesdo-common/src/lib/services/feature-toggle/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private coreToMpRolesMap: Dictionary<UserRole> = {
    sales: UserRole.Sales,
    developer: UserRole.Developer,
    admin: UserRole.Admin,
    realtor: UserRole.Realtor,
    avesdoAdvisor: UserRole.Developer,
    avesdoCS: UserRole.Admin,
    avesdoReporting : UserRole.AvesdoReporting
  }

  private developerIdInterval: any;
  private subscriptions: Subscription;
  private user = new AuthUser();
  private jwtToken: string;

  private manager: UserManager;
  private oicdUser: OicdUser | null;

  developerId: number;
  buildingId: number;
  developerOrganizationName: string;

  constructor(
    private http: HttpClient,
    private cookiesService: CookiesService,
    private appConfig: AppConfigService,
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
  ) {
    const currentUrl = window.location.href;
    const clientSetting = (new SsoClientSetting(currentUrl)) as UserManagerSettings;
    this.manager = new UserManager(clientSetting);
  }

  setAuthUserSettings(
    userSettings: any,
    developerInfo: {
      id: number,
      loginAction: string,
      name: string
    },
    buildings: any[]
  ) {
    const { firstName, lastName, userRole, email, colorPreference } = userSettings;
    const { id: developerId, name: developerName } = developerInfo;

    this.user.name = `${firstName} ${lastName}`;
    this.user.role = [this.coreToMpRolesMap[userRole]];
    this.user.legacyRole = userRole;
    this.user.developerId = developerId.toString();
    this.user.developerName = developerName;
    this.user.email = email;
    this.user.building = buildings.find((building) => building.developerId === developerId);
    this.user.userPreferences.colorPreference = colorPreference;

    this.buildingId = this.user.building.id;
    this.store.dispatch(logInSuccess({ user: this.user }));

    this.setLocalStorageBuildingId(this.buildingId.toString());
  }

  //#region new sso methods

  isLoggedIn(): Observable<boolean> {
    return from(this.manager.getUser()).pipe(
      map((oicdUser) => {
        const isLoggedIn = Boolean(oicdUser && !oicdUser.expired);

        if (isLoggedIn) {
          this.oicdUser = oicdUser;
          this.setJwtToken(oicdUser.access_token);

          const currentDeveloperId = localStorage.getItem(LocalStorageNames.CoreDeveloperId);
          if(currentDeveloperId)
          {
            if(typeof oicdUser.profile.DeveloperIds === 'string' || oicdUser.profile.DeveloperIds instanceof String)
            {
              this.reroute(oicdUser.profile.DeveloperIds, currentDeveloperId);
            } else {
              for(let developerIds of oicdUser.profile.DeveloperIds)
              {
                this.reroute(developerIds, currentDeveloperId);
              }
            }
          }
          setUserpilotMetadata({
            name: oicdUser.profile.name,
            email: oicdUser.profile.email,
            role: oicdUser.profile.role,
            developerId: currentDeveloperId,
            developerName: this.user.developerName ?? this.getBuildingName(),
            orgId: oicdUser.profile.organizationId
          }, this.appConfig.environment);
        } else {
          this.login();
        }

        return isLoggedIn;
      })
    );
  }

  reroute(developerIds, currentDeveloperId)
  {
    if (!developerIds.split(',').includes(currentDeveloperId)) {
      this.resetDeveloperId();
      this.router.navigate(['select-building']);
    }
  }

  login() {
    const { pathname, search } = window.location;
    //Don't affect the redirect path and root paths needed for SSO
    if (!pathname.includes('redirect') && pathname !== '/') {
      const newPath = `${pathname.replace(/\/$/, '')}${search}`;
      //need to remove trailing slashes in url so angular router will recognize it on return
      localStorage.setItem(LocalStorageNames.CoreReturnUrl, newPath);
    }

    this.manager.signinRedirect();
  }

  logout() {
    logoutUserpilot();
    localStorage.clear();
    this.manager.signoutRedirect();
  }

  completeAuthentication() {
    return this.manager.signinRedirectCallback().then((oicdUser: OicdUser) => {
      if (oicdUser) {
        this.oicdUser = oicdUser;
        this.setJwtToken(oicdUser.access_token);
      }
      return oicdUser;
    });
  }

  refreshToken() {
    return this.manager.signinSilentCallback();
  }

  //#endregion

  private startDeveloperIntervalCheck() {
    this.developerIdInterval && clearInterval(this.developerIdInterval);

    //Start the interval check for change in project based on cookie
    this.developerIdInterval = setInterval(() => {
      const currentDeveloperId = localStorage.getItem(LocalStorageNames.CoreDeveloperId);

      if (currentDeveloperId && currentDeveloperId !== this.developerId.toString()) {
        const dialogRef = this.dialog.open(ReloadPageModalComponent);

        this.subscriptions = dialogRef.afterClosed().subscribe(() => {
          window.location.reload();
        });

        clearInterval(this.developerIdInterval);
      }
    }, 1500);
  }

  getAuthorizedUser() {
    return this.user;
  }

  getJwtToken() {
    return this.jwtToken;
  }

  setJwtToken(token: string) {
    this.jwtToken = token;
  }

  setLocalStorageDeveloperId(developerId: string) {
    localStorage.setItem(LocalStorageNames.CoreDeveloperId, developerId);
  }

  setLocalStorageBuildingId(buildingId: string) {
    localStorage.setItem(LocalStorageNames.CoreBuildingId, buildingId);
  }
  
  setBuildingName(buildingName: string) {
    return localStorage.setItem(LocalStorageNames.CoreBuildingName, buildingName);
  }
  
  getBuildingName() {
    return localStorage.getItem(LocalStorageNames.CoreBuildingName);
  }

  getUserPermission() {
    return this.http
      .get<any>(`${environment.crmApiUrl}/Contracts/MarketPlace/IsAllowedUser`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getIsSelectionsActive(): Observable<boolean> {
    return this.http
      .get<CoreApiResponse<boolean>>(`${environment.crmApiUrl}/Contracts/MarketPlace/SelectionsActive`)
      .pipe(
        handleCoreApiResponse,
        map((data) => data.data),
        catchError((error: any) => throwError(error))
      );
  }

  getProjectList() {
    return this.http
      .get<any>(`${environment.crmApiUrl}/Contracts/Account/GetProjectList`)
      .pipe(map(res => {
        if (res) {
          return res.buildings;
        }
        return null;
      }), catchError((error: any) => throwError(error)));
  }

  getBuildingList(): Observable<SelectBuildingItem[]> {
    return this.http
      .post<any>(`${environment.crmApiUrl}/Contracts/Account/GetBuildingList`, {})
      .pipe(
        handleCoreApiResponse,
        map(res => res.data),
        catchError((error: any) => throwError(error))
      );
  }

  //Used in sales module only
  loadApplicationPermissions() {
    const url = `${avCommonEnvironment.crmApiUrl}/Common/ApplicationPermissions?developerId=${this.developerId}`;
    return this.http.get<any>(url).pipe(
      catchError((error: any) => throwError(error))
    );
  }

  getDeveloperId() {
    return this.developerId;
  }

  setDeveloperId(developerId: number) {
    this.developerId = developerId;
    this.setLocalStorageDeveloperId(developerId.toString());
    this.startDeveloperIntervalCheck();
  }
  resetDeveloperId() {
    localStorage.removeItem(LocalStorageNames.CoreDeveloperId);
    clearInterval(this.developerIdInterval);
  }

  setDeveloperOrganizationName(organizationName: string) {
    this.developerOrganizationName = organizationName;
  }

  getDeveloperOrganizationName() {
    return this.developerOrganizationName;
  }

  setUserDemandLevelSettings(demandLevelSettings: Dictionary<DemandLevelSettings>) {
    this.user.userPreferences.heatmapDemandSettings = demandLevelSettings;
  }

  getUserClaims() {
    const url = `${environment.crmApiUrl}/Contracts/Membership/GetUserPermission`;
    return this.http.post<any>(url, {}).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error: any) => throwError(error))
    );
  }

  ngOnDestroy() {
    clearInterval(this.developerIdInterval);
    if (this.subscriptions)
      this.subscriptions.unsubscribe();
  }
}
