export const LeadFormStyleConstants = {
    labelColor: '#58778b',
    fontFamily: 'Roboto',
    buttonColor: '#547184',
    buttonRadius: '4',
    inputRadius: '4',
    inputBorderColor: '#d8d8d8',
    buttonBorderColor: '#547184',
    buttonFontCase: 'Uppercase',
    buttonFontColor: '#ffffff',
    buttonFontSize: '14',
    buttonColorOnHover: '#547184',
    buttonFontColorOnHover: '#ffffff',
    buttonBorderColorOnHover: '#547184',
    styles: ['Roboto', 'Arial', 'Verdana', 'Helvetica', 'Tahoma', 'Trebuchet', 'Times New Roman',
        'Georgia', 'Garamond', 'Courier New', 'Brush Script MT', 'Montserrat'],
    fontCase: ['Uppercase', 'Lowercase', 'Capitalize'],
    fontWeight: ['Normal', 'Bold'],
    formContainerWidth: '1000px',
    formPaddingTop: '24',
    formPaddingLeft: '24',
    formPaddingRight: '24',
    formPaddingBottom: '24',
    labelFontCase: 'Capitalize',
    labelFontSize: '14',
    inputBorderColorOnHover: '#d8d8d8',
    labelFontWeight: 'Normal',
    buttonAlignment: 'Right',
    formBackgroundColor: '#ffffff',
    alignmentOptions: ['Left', 'Right', 'Center']
};
