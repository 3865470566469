import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ButtonToggleGroupComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ButtonToggleGroupComponent
    },
  ]
})
export class ButtonToggleGroupComponent implements ControlValueAccessor, Validator, OnDestroy, OnInit  {

  @Input() customControlOptions?: Array<{label: any, icon?: string, value: any}> = [];
  @Input() groupLabel?: string = '';
  @Input() selectMultiple?: boolean = false;
  @Input() initialValue?: any;
  @Input() upperCaseLabels?: boolean = false;
  @Input() showLabels?: boolean = true;
  @Output() change: EventEmitter<any> = new EventEmitter();
  
  buttonToggleControl = new FormControl();

  selectedOption: any = null;
  onChange = (option) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  
  private unsubscribe$: Subject<any> = new Subject<any>();
  
  constructor(private translateService: TranslateService) { }

  validate(control: AbstractControl): ValidationErrors {
    const selectedValue = control.value;
    if (!selectedValue) {
      return {
        required: true
      };
    }
  }
 
  writeValue(obj: any): void {
    this.selectedOption = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  getDefaultYesNo() {
    this.translateService.get(['GENERIC.YES', 'GENERIC.NO']).subscribe(resp => {
      this.customControlOptions.push({label: resp['GENERIC.YES'], value: resp['GENERIC.YES']}, {label: resp['GENERIC.NO'], value: resp['GENERIC.NO']});
      if (!this.initialValue) this.selectedOption = resp['GENERIC.YES'];
    });
  }

  ngOnInit(): void {
    if (this.customControlOptions.length===0) this.getDefaultYesNo();

    if (this.initialValue || this.initialValue === 0) this.selectedOption = this.initialValue;
    this.buttonToggleControl.setValue(this.selectedOption);

    this.buttonToggleControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value=>{
      this.onChange(value);
      this.change.emit(value);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
