import { createAction, props } from '@ngrx/store';
import { PublicItem } from '@avesdo-common/src/lib/feature/custom-forms/models/PublicItem';

export const FETCH_PUBLIC_ITEMS_IF_NEEDED = '[PublicItems] Fetch Public Items If Needed';
export const FETCH_PUBLIC_ITEMS = '[PublicItems] Fetch Public Items Detail';
export const FETCH_PUBLIC_ITEMS_SUCCESS = '[PublicItems] Fetch Public Items success';
export const FETCH_PUBLIC_ITEMS_FAIL = '[PublicItems] Fetch Public Items  fail';

export const fetchPublicItems = createAction(
  FETCH_PUBLIC_ITEMS,
  props<{ id: string }>()
);

export const fetchPublicItemsIfNeeded = createAction(
  FETCH_PUBLIC_ITEMS_IF_NEEDED,
  props<{ id: string }>()
  );

export const fetchPublicItemsSuccess = createAction(
  FETCH_PUBLIC_ITEMS_SUCCESS,
  props<{
   id: string,
   publicItems: PublicItem[],
   }>()
);

export const fetchPublicItemsFail = createAction(
  FETCH_PUBLIC_ITEMS_FAIL,
  props<{ id: string }>()
  );
