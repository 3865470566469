import {
  Directive,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  Output,
  Input,
  EventEmitter,
  ComponentFactoryResolver
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface WizardLoaderDirectiveConfig {
  formGroup: FormGroup;
  startComponent: any;
  [key: string]: any;
}

export interface WizardPage {
  componentConfig: WizardLoaderDirectiveConfig;
  loadComponent: EventEmitter<any>;
  submitForm?: EventEmitter<any>;
}

@Directive({
  selector: '[libWizardLoader]'
})
export class WizardLoaderDirective implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  @Input() wizardConfig: WizardLoaderDirectiveConfig;
  @Output() submitForm = new EventEmitter<any>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public viewContainerRef: ViewContainerRef
  ) { }

  loadComponent(component: any) {
    const {
      viewContainerRef,
      wizardConfig,
      componentFactoryResolver,
      submitForm
    } = this;

    if (this.subscriptions)
      this.subscriptions.unsubscribe();

    viewContainerRef.clear();

    const componentFactory = componentFactoryResolver
      .resolveComponentFactory(component);

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const loadedComponent = (<any>componentRef.instance);

    loadedComponent.componentConfig = wizardConfig;

    const ctx = this;
    this.subscriptions = loadedComponent.loadComponent.subscribe((component) => {
      ctx.loadComponent(component);
    });

    if (loadedComponent.submitForm)
      this.subscriptions.add(loadedComponent.submitForm.subscribe(() => {
        submitForm.emit();
      }));
  }

  ngOnInit() {
    this.loadComponent(this.wizardConfig.startComponent);
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.unsubscribe();
  }
}

