import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Injector
} from '@angular/core';
import { map } from 'rxjs/operators';
import { Option } from '../../models/generic/Option';
import { AvTypeaheadMixin } from './av-typeahead.mixin';
import { Base } from 'ng-mix';

@Component({
  selector: 'lib-av-typeahead',
  templateUrl: './av-typeahead.component.html',
  styleUrls: ['./av-typeahead.component.scss'],
  inputs: [
    'placeholder',
    'options',
    'selectedOptions',
    'loadingOptions',
    'debounceTime'
  ],
  outputs: ['typeaheadChange', 'optionSelected']
})
export class AvTypeaheadComponent extends AvTypeaheadMixin(Base) implements OnInit {
  @Output() optionSelected = new EventEmitter<Option<any>>();

  constructor(public inj: Injector) { super(inj); }

  onSelectionChange(option: Option<any>) {
    this.searchControl.setValue('');
    this.optionSelected.emit(option);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filteredOptions$ = this.filteredOptions$.pipe(
      map((options) => options?.filter((option) => !this.selectedOptions?.includes(option)))
    );
  }
}
