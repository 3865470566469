import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.scss']
})
export class AuthorizedComponent {
  @Input() headerHeight: number;
  @Input() isContentHeightAuto: boolean;
  @Input() isAsideViewOpened: boolean;
  @Input() isAsideMenuOpened?: boolean;
  @Input() contentPadding?: boolean;
  @Input() isAsideViewOpenedForRealtor?: boolean;
  @Input() disableAsideViewClose = false;

  @Output() backdrop: EventEmitter<any> = new EventEmitter();

  constructor() {
    if (this.isAsideMenuOpened === null) {
      this.isAsideMenuOpened = true;
    }
    if (this.contentPadding === null) {
      this.contentPadding = true;
    }
  }

  onBackdrop() {
    if(!this.disableAsideViewClose)
      this.backdrop.emit();
  }
}
