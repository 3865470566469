import { createAction, props } from '@ngrx/store';
import { BuildingSettings } from '../../models/BuildingSettings';

export const FETCH_BUILDING_SETTINGS_IF_NEEDED = '[BuildingSettings] Fetch Building Settings If Needed';
export const FETCH_BUILDING_SETTINGS = '[BuildingSettings] Fetch Building Settings';
export const FETCH_BUILDING_SETTINGS_SUCCESS = '[BuildingSettings] Fetch Building Settings success';
export const FETCH_BUILDING_SETTINGS_FAIL = '[BuildingSettings] Fetch Building Settings fail';

export const FETCH_IS_SELECTIONS_SETUP_IF_NEEDED = '[BuildingSettings] Fetch IsSelectionsSetup If Needed';
export const FETCH_IS_SELECTIONS_SETUP = '[BuildingSettings] Fetch IsSelectionsSetup';

export const FETCH_IS_SELECTIONS_SETUP_SUCCESS = '[BuildingSettings] Fetch IsSelectionsSetup success';
export const FETCH_IS_SELECTIONS_SETUP_FAIL = '[BuildingSettings] Fetch IsSelectionsSetup fail';

export const fetchBuildingSettings = createAction(FETCH_BUILDING_SETTINGS);
export const fetchBuildingSettingsIfNeeded = createAction(FETCH_BUILDING_SETTINGS_IF_NEEDED);

export const fetchBuildingSettingsSuccess = createAction(
  FETCH_BUILDING_SETTINGS_SUCCESS,
  props<{ buildingSettings: BuildingSettings }>()
);

export const fetchBuildingSettingsFail = createAction(FETCH_BUILDING_SETTINGS_FAIL);

export const fetchIsSelectionsSetup = createAction(FETCH_IS_SELECTIONS_SETUP);
export const fetchIsSelectionsSetupIfNeeded = createAction(FETCH_IS_SELECTIONS_SETUP_IF_NEEDED);

export const fetchIsSelectionsSetupSuccess = createAction(
  FETCH_IS_SELECTIONS_SETUP_SUCCESS,
  props<{ isSelectionsSetup: boolean }>()
);

export const fetchIsSelectionsSetupFail = createAction(FETCH_IS_SELECTIONS_SETUP_FAIL);
