export const environment = {
  production: 'false',
  authority: 'https://avesdo-sso.azurewebsites.net', // SSO Server URL
  analyticsUrl: 'https://avesdo-analytics.azurewebsites.net', // Analytics API URL
  coreBaseUrl: 'https://avesdo.net', // Core Avesdo URL,
  coreMarketplaceApiUrl: 'https://avesdo-core-mp-api.azurewebsites.net',
  imgServerUrl: 'https://yongleprodcanada.blob.core.windows.net',
  mktplaceBaseUrl: 'https://realtor.offplan.homes',
  mktplaceDeveloperBaseUrl: 'https://developer.offplan.homes',
  mktplaceApiUrl: 'https://avesdo-offers-api.azurewebsites.net/api', // MP API URL
  crmBaseUrl: 'https://crm.avesdo.net',
  crmApiUrl: 'https://avesdo-apigateway.azurewebsites.net',
  crmRerouteUrl : 'https://avesdo-reroute.azurewebsites.net',
  buyersPortalApiUrl: '',
  buyersPortalCookieDomain: '',
  marketplaceCookieDomain: 'realtor.offplan.homes',
  buyersPortalBaseUrl: '',
  utilityServiceUrl: 'https://avesdo-utilityservice.azurewebsites.net/api',

  stripeApiKey: 'pk_live_M64wpLKAoETdc4Zc6MgnuxyL00rGUmBCzk',
  //SSO variables
  googleClientId: '',
  microsoftClientId: '05fba5ca-df56-4528-9e2b-d30fca5169b8',
  microsoftAuthority: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  redirectUrl: 'https://avesdo.net/Account/EmailSettings',
  googleAuthority: '',
  googleApiScopeGmail: '',
  facebookAppId: '',
  customMsalClientId: '',
  msalSignUpAuthority: '',
  msalResetPasswordAuthority: '',

  //Google Maps
  googleMapApiKey: 'AIzaSyCGPhGXdo5fmhjM_kQDjsthS1vDLJyKAR0',
  hubSpotSrc: '//js.hs-scripts.com/7329967.js',
  googleAnalyticsId: '',

  //Signal R
  signalRUrl: 'https://avesdo-apigateway.azurewebsites.net/signalrhub/gridUpdate',

  environmentType: 'Prod'
};
