import { DragDropZoneDirective } from './directives/drag-drop-zone.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { MaterialModule } from './material.module';
import {TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { IconFilterComponent } from './components/icon-filter/icon-filter.component';
import { BadgePillToggleComponent } from './components/badge-pill-toggle/badge-pill-toggle.component';
import { AvTypeaheadComponent } from './components/av-typeahead/av-typeahead.component';
import { HighlightSearch } from '../lib/pipes/higlight.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { PublicUrlLinkComponent } from './components/public-url-link/public-url-link.component';
import { ImageUploadButtonComponent } from './components/image-upload-button/image-upload-button.component';
import { AvTypeaheadMultiSelectComponent } from './components/av-typeahead/av-typeahead-multi-select.component';
import { PhotoButtonComponent } from './components/photo-button/photo-button.component';
import { RadioButtonGroupComponent } from './components/radio-button-group/radio-button-group.component';
import { NumberFormatterDirective } from './directives/av-number-formatter.directive';
import { ButtonToggleGroupComponent } from './components/button-toggle-group/button-toggle-group.component';
import { ContentProjectionWrapperComponent } from './components/content-projection-wrapper/content-projection-wrapper.component';
import { NotApplicableIfEmptyPipe } from './pipes/not-applicable-if-empty.pipe';
import { LegacyImagePipe } from './pipes/legacy-image.pipe';
import { CurrencyFormInputComponent } from './components/currency-form-input/currency-form-input.component';
import { HandleNoImageDirective } from './directives/handle-no-image.directive';
import { DateDisplayComponent } from './components/date-display/date-display.component';
import { UnitFloorPlanColumnComponent } from './components/unit-floor-plan-column/unit-floor-plan-column.component';
import { SavedAllocationColumnComponent } from './components/saved-allocation-column/saved-allocation-column.component';
import { UploadProgressBarComponent } from './components/upload-progress-bar/upload-progress-bar.component';

import { PlainTextComponent } from './components/plain-text/plain-text.component';
import { MainSideNavComponent } from './components/main-side-nav/main-side-nav.component';
import { DocCancelledFilterPipe } from './pipes/doc-cancelled-filter.pipe';
import { CurrencyDisplayComponent } from './components/currency-display/currency-display.component';
import { AvBulkFileUploadComponent } from './components/av-bulk-file-upload/av-bulk-file-upload.component';
import { FilesSummaryGridComponent } from './components/av-bulk-file-upload/files-summary-grid/files-summary-grid.component';
import { FileUploadSummaryComponent } from './components/av-bulk-file-upload/file-upload-summary/file-upload-summary.component';
import { IconDisplayComponent } from './components/icon-display/icon-display.component';
import { RemoveFileExtensionPipe } from './pipes/av-remove-file-extension.pipe';
import { TranslateLocalePipe } from './pipes/translate-locale.pipe';
import { AvFileUploadContainerComponent } from './components/av-file-upload-container/av-file-upload-container.component';

//export Required for Aot
export function loadFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/locale/', '.json');
}

//TODO: we should begin moving top level (common) components in the library into this module
//so we can import into other modules in this library

const COMPONENTS = [
  StarRatingComponent,
  IconFilterComponent,
  BadgePillToggleComponent,
  AvTypeaheadComponent,
  ConfirmationDialogComponent,
  PublicUrlLinkComponent,
  ImageUploadButtonComponent,
  AvTypeaheadMultiSelectComponent,
  PhotoButtonComponent,
  RadioButtonGroupComponent,
  ButtonToggleGroupComponent,
  ContentProjectionWrapperComponent,
  CurrencyFormInputComponent,
  DateDisplayComponent,
  UnitFloorPlanColumnComponent,
  SavedAllocationColumnComponent,
  PlainTextComponent,
  UploadProgressBarComponent,
  MainSideNavComponent,
  CurrencyDisplayComponent,
  AvBulkFileUploadComponent,
  AvFileUploadContainerComponent,
  FilesSummaryGridComponent,
  FileUploadSummaryComponent,
  IconDisplayComponent,
];

const PIPES = [
  HighlightSearch,
  NotApplicableIfEmptyPipe,
  LegacyImagePipe,
  DocCancelledFilterPipe,
  RemoveFileExtensionPipe,
  TranslateLocalePipe
];

const DIRECTIVES = [
  NumberFormatterDirective,
  HandleNoImageDirective,
  DragDropZoneDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule, ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: loadFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
  ],
  providers: [DecimalPipe]
})
export class SharedModule { }
