import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AvTableColumnConfig } from '../av-table.component';

@Component({
  selector: 'lib-av-multi-select-chip',
  templateUrl: './av-multi-select-chip.component.html',
  styleUrls: ['./av-multi-select-chip.component.scss']
})
export class AvMultiSelectChipComponent implements OnInit, OnDestroy {
  @Input() componentConfig: {
    formGroup: FormGroup;
    columnConfig: AvTableColumnConfig;
  }

  private subscription: Subscription;

  selectedItems: string[];
  label: string;

  constructor() { }

  setSelectedItemsAndLabel() {
    const { controls } = this.componentConfig.formGroup;

    this.selectedItems = Object.keys(controls).reduce((selectedItems, key) => {
      if (controls[key].value)
        selectedItems.push(key)
      return selectedItems;
    }, []);

    const { multiSelectItems } = this.componentConfig.columnConfig;

    this.label = this.selectedItems
      .map(
        (key) => multiSelectItems
          .find((selectItem) => selectItem.value.toString() === key).label
      )
      .join(', ');
  }

  clearSelectedItems() {
    for (let key in this.componentConfig.formGroup.controls) {
      this.componentConfig.formGroup.controls[key].setValue(false);
    }
  }

  ngOnInit() {
    this.setSelectedItemsAndLabel();
    this.subscription = this.componentConfig.formGroup.valueChanges.subscribe(() => {
      this.setSelectedItemsAndLabel();
    });
  }

  ngOnDestroy() { this.subscription.unsubscribe() };

}
