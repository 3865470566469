import { Component, Input, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CustomDatePipe } from '@avesdo-common/src/lib/pipes/custom-date.pipe';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'lib-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit {
  @Input() date: string;
  @Input() data: any;
  @Input() disabled = false;
  @Input() timezone?: string;
  @Input() unit: string;
  @Input() confirmDialog: TemplateRef<any>;
  @Input() confirmDialogConfig?: any;
  @Output() dateChanged = new EventEmitter<DateInputChangeEvent>();

  dateControl = new FormControl();

  constructor(private dialog: MatDialog) { }

  private getNormalizedDate(date) {
    return new Date(new CustomDatePipe().transform(date, 'MM/DD/YY', this.timezone, false));
  }

  ngOnInit() {
    this.dateControl.setValue(this.getNormalizedDate(this.date ?? undefined));
  }
  
  confirm(newDate) {
    if (this.confirmDialog) {
      this.dateControl.setValue(this.getNormalizedDate(this.date ?? undefined));
      this.dialog.open(this.confirmDialog, {
        data: {
          date: newDate,
          unitNumbers: [this.unit]
        },
        ...this.confirmDialogConfig
      }).afterClosed().subscribe(result => {
        if (result) {
          this.dateChanged.emit({ data: this.data, newDate: newDate });
          this.dateControl.setValue(newDate);
        }
      });
    } else {
      this.dateChanged.emit({ data: this.data, newDate: newDate });
    }
  }
}

export interface DateInputChangeEvent {
  newDate?: Date;
  data: any;
}
