import { Component, OnInit, Input } from '@angular/core';
import { CustomDatePipe } from '@avesdo-common/src/public-api';

@Component({
  selector: 'lib-av-table-date',
  templateUrl: './av-table-date.component.html',
  styleUrls: ['./av-table-date.component.scss']
})
export class AvTableDateComponent implements OnInit {
  @Input() date: any;
  @Input() format: string = 'MMM DD YYYY';
  @Input() timezone?: any;
  label: string;

  constructor() { }

  ngOnInit(): void {
    this.label = new CustomDatePipe().transform(this.date, this.format, this.timezone, false);
  }

}
