import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class LanguageService {
  data: any = {};
  supportedLanguages: any = {
    en: 'LANGUAGE.EN',
    fr: 'LANGUAGE.FR',
    zh: 'LANGUAGE.ZH'
  };

  constructor(private translate: TranslateService) {
    this.initialize();
  }
  setDefault(lang: string) {
    this.translate.setDefaultLang(lang || 'en');
  }
  getCurrentLanguage() {
    return this.translate.currentLang;
  }
  getSupportedLanguages() {
    return this.supportedLanguages;
  }
  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      this.translate.use(lang);
      localStorage.setItem('lang', lang);
    });
  }
  initialize() {
    this.translate.langs = ['en', 'fr', 'zh'];
    this.translate.setDefaultLang(localStorage.getItem('lang') || 'en');
  }

  getTmsLanguageParams() {
    return this.translate.instant('CONSTANTS.TMS');
  }
  getOffPlanLanguageParams() {
    return this.translate.instant('CONSTANTS.OFFPLAN');
  }
  getTmsLanguageParams$(): Observable<any> {
    return this.translate.get('CONSTANTS.TMS');
  }
  getOffPlanLanguageParams$(): Observable<any> {
    return this.translate.get('CONSTANTS.OFFPLAN');
  }
}
