import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-av-table-button',
  templateUrl: './av-table-button.component.html',
  styleUrls: ['./av-table-button.component.scss']
})
export class AvTableButtonComponent {
  @Input() text: string;
  @Input() data: any;
  @Input() buttonType: string = 'stroked';
  @Input() hide: boolean = false;
  @Input() disabled: boolean = false;
  @Output() btnClick = new EventEmitter();

  constructor() { }

  clickButton() {
    this.btnClick.emit({
      data: this.data
    });
  }
}
