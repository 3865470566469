import {
  FormControl,
  FormArray,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { IAddress } from '../models/user-models/Contact';

//    Validators.pattern('(?=.*[A-Z])'),

export class AvesdoValidator {
  static oneUppercase(control: AbstractControl): ValidationErrors | null {
    const regex: RegExp = /(?=.*[A-Z])/;
    const isNotValid = !regex.test(control.value);
    return isNotValid ? { oneUppercase: 'oneUppercase' } : null;
  }

  static oneLowercase(control: AbstractControl): ValidationErrors | null {
    const regex: RegExp = /(?=.*[a-z])/;
    const isNotValid = !regex.test(control.value);
    return isNotValid ? { oneLowercase: 'oneLowercase' } : null;
  }

  static oneDigit(control: AbstractControl): ValidationErrors | null {
    const regex: RegExp = /(?=.*[0-9])/;
    const isNotValid = !regex.test(control.value);
    return isNotValid ? { oneDigit: 'oneDigit' } : null;
  }

  static oneSymbol(control: AbstractControl): ValidationErrors | null {
    const regex: RegExp = /(?=.*[^a-zA-Z0-9 ])/;
    const isNotValid = !regex.test(control.value);
    return isNotValid ? { oneSymbol: 'oneSymbol' } : null;
  }

  static isNumber(control: AbstractControl): ValidationErrors | null {
    return isNaN(control.value) ? { isNumber: 'isNumber' } : null;
  }

  static isInteger(control: AbstractControl): ValidationErrors | null {
    // tslint:disable-next-line: radix
    return !Number.isInteger(parseFloat(control.value))
      ? { isInteger: 'isInteger' }
      : null;
  }

  static isNotEmptyArray(control: AbstractControl): ValidationErrors | null {
    return control.value && control.value.length === 0
      ? { isNotEmptyArray: 'isNotEmptyArray' }
      : null;
  }

  static isPositiveNumber(control: AbstractControl): ValidationErrors | null {
    // tslint:disable-next-line: radix
    return parseFloat(control.value) < 0
      ? { isPositiveNumber: 'isPositiveNumber' }
      : null;
  }

  static isPhone(control: AbstractControl): ValidationErrors | null {
    if (!control.value)
      return null;

    const MIN_PHONE_LENGTH = 7; // 123 45 67
    let value =
      control.value && control.value[0] === '+' // allow first character to be +
        ? control.value.substring(1, control.value.length)
        : control.value;

    value = value.replace(/\s/g, ''); // remove all white space for validation

    return value && (isNaN(value) || value.length < MIN_PHONE_LENGTH)
      ? { isPhone: 'isPhone' }
      : null;
  }

  static isPostalCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const regex: RegExp = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/;
    const normalizedValue = control.value.replace(/\s/g, '').toUpperCase();
    const isNotValid = !regex.test(normalizedValue);

    return isNotValid ? { isPostalCode: 'isPostalCode' } : null;
  }

  static isZipCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const regex: RegExp = /^\d{5}(?:[-\s]\d{4})?$/;
    const isNotValid = !regex.test(control.value);
    return isNotValid ? { isZipCode: 'isZipCode' } : null;
  }

  static isNotZero(control: AbstractControl): ValidationErrors | null {
    return control.value === 0 ? { isNotZero: 'isNotZero' } : null;
  }

  static atLeastOneAddressFieldIsFilled(address: IAddress) {
    return (
      address.unit ||
      address.street ||
      address.city ||
      address.countryId ||
      address.provinceId ||
      address.postalCode
    );
  }

  static noWhitespace(control: FormControl) {
    const isNotValid = (control.value || '').trim().length === 0;
    return isNotValid ? { noWhitespace: true } : null;
  }

  static isBlank(control: FormControl) {
    const isNotValid = !control.value || /^\s*$/.test(control.value);
    return isNotValid ? { isBlank: true } : null;
  }

  static isNotValidEmail(control: FormControl) {
    if (!control.value)
      return null;

    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isNotValid = !regex.test(String(control.value));
    return isNotValid ? { isNotValidEmail: true } : null;
  }

  static emptySelectionModel(control: AbstractControl): ValidationErrors | null {
    return !control.value.selected.length ? { emptySelectionModel: true } : null;
  }

  static isNotValidFileName(control: FormControl) {
    if (!control.value)
      return null;

    const re = /^[^"]*$/;
    const isNotValid = !re.test(String(control.value));
    return isNotValid ? { isNotValidFileName: true } : null;
  }

  static conditionalValidator(condition: boolean, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (condition) {
        return validator(control);
      }
    }
  }

  // static arrayLengthRequired(formArray: FormArray): ValidationErrors | null {
  //       if (!(formArray instanceof FormArray)) return;
  //       return formArray.length > 0 ? { betweenLength: true } : null;
  // }
}
