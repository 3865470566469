import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
    providedIn: 'root',
  })
  
  export class HubSpotApiChatService {
    private CHAT_INSTANCE_ID = 'hs-script-loader';

    public injectHubSpotScript(): void {
        if (this.isExist()) return;
        const hubSpotscript = document.createElement('script');
        hubSpotscript.type = 'text/javascript';
        hubSpotscript.src = environment.hubSpotSrc;
        hubSpotscript.defer = true;
        hubSpotscript.async = true;
        hubSpotscript.id = this.CHAT_INSTANCE_ID;
        document.body.appendChild(hubSpotscript);
    }

    private isExist(): boolean {
        const existingChatInstance = document.getElementById(this.CHAT_INSTANCE_ID);
       return (existingChatInstance !== null)
    }
      
  }