import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBuilding } from '@avesdo-common/src/lib/models/building-models/Building';
import {IOrganization} from "@avesdo-common/src/lib/models/building-models/Organization";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FormControl} from "@angular/forms";
import {map, startWith, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {OrganizationService} from "@ng-new/src/app/feature/sales/modules/shared/services/organization.service";
import {NotificationService} from "@avesdo-common/src/lib/services/notifications/notification.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-marketing-organization',
  templateUrl: './add-marketing-organization.component.html',
  styleUrls: ['./add-marketing-organization.component.scss']
})
export class AddMarketingOrganizationComponent implements OnInit {
  saveDisabled: boolean = true;
  isSaving: boolean = false;
  isLoading: boolean = true;
  saveLoading: boolean = false;
  showAll: boolean = false;
  organizationFilteredOptions$: Observable<IOrganization[]>;
  selectedOrganization: IOrganization;

  addMarketingForm = new FormControl('');
  constructor(
    public dialogRef: MatDialogRef<AddMarketingOrganizationComponent>,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
    private translateService: TranslateService,

    @Inject(MAT_DIALOG_DATA) public data: {project: IBuilding, organizationId: string, marketingOrganizations: IOrganization[]}
  ) { }

  openAutofill(event, trigger: MatAutocompleteTrigger, input) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      return;
    }
    this.showAll = true;
    trigger.openPanel();
    input.select();
  }


  save() {
    this.saveLoading = true;
    this.organizationService.createNewMarketingOrganizationAssociation(this.data.project.id, this.data.organizationId, this.addMarketingForm.value).subscribe(resp => {
      if (resp.success) {
        this.notificationService.success(this.translateService.instant('SELECTIONS.SAVED'));
        this.dialogRef.close()
      } else {
        this.notificationService.error(this.translateService.instant('ERRORS.ERROR'));
      }
      this.saveLoading = false;
    }, () => {
      this.notificationService.error(this.translateService.instant('ERRORS.ERROR'));
      this.saveLoading = false;
    });
  }


  ngOnInit(): void {
    this.organizationFilteredOptions$ = this.addMarketingForm.valueChanges.pipe(
      startWith(''),
      tap(value => {
        this.selectedOrganization = this.data.marketingOrganizations.find(org => org.name.toLowerCase() === value?.toLowerCase())
        this.saveLoading = false;
        this.saveDisabled = value.length <= 0;
      }),
      map(value => this.data.marketingOrganizations.filter(org => org.name.toLowerCase().includes(value?.toLowerCase()) || this.showAll)),
      tap(() => {
        this.showAll = false;
      })
    );
  }

}
