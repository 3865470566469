import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAvesdoCommonState } from '@avesdo-common/src/lib/redux/avesdo-common.reducer';

export const getAvesdoCommonState = createFeatureSelector<IAvesdoCommonState>(
  'avesdoCommon'
);

export const getPublicItemsState = createSelector(
  getAvesdoCommonState,
  (state: IAvesdoCommonState) => state.publicItems
   
);