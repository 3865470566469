import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({ selector: '[libCopyClipboard]' })
export class CopyClipboardDirective {
  @Input() public textToCopy: string;
  @Output() public copied: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  @HostListener('click')
  public onClick(): void {

    if (!this.textToCopy) {
      return;
    }
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.left = '0';
    textarea.style.top = '0';
    textarea.style.opacity = '0';
    textarea.value = this.textToCopy;
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.copied.emit(true);
  }
}
