import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketingAssetteType } from '@avesdo-common/src/lib/enums/MarketingAssetteType';
import { FileData } from '@avesdo-common/src/lib/models/bulk-file-upload/file-data';
import { FileSummaryGridComponentData, SummaryGridColumn } from '@avesdo-common/src/lib/models/bulk-file-upload/file-upload-component-data';
import { RemoveFileExtensionPipe } from '@avesdo-common/src/lib/pipes/av-remove-file-extension.pipe';
import { AvesdoValidator } from '@avesdo-common/src/lib/validators/avesdo-validator';

@Component({
  selector: 'lib-files-summary-grid',
  templateUrl: './files-summary-grid.component.html',
  styleUrls: ['./files-summary-grid.component.scss'],
  providers: [RemoveFileExtensionPipe]
})
export class FilesSummaryGridComponent implements OnInit, OnDestroy {
  @Input() filesList: File[] = [];
  @Input() marketingAssetteType: MarketingAssetteType;
  @Input() componentData: FileSummaryGridComponentData;

  @Output() toUploadFilesList: EventEmitter<FileData[]> = new EventEmitter<
    FileData[]
  >();
  @Output() onCancelTheFlow: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  selectedFilesToUpload: FileData[] = [];
  uploadFilesSummaryGrid: FormArray;
  showPriceColumn: boolean = false;
  inputDataColumns: SummaryGridColumn[];
  requiredFieldsSubHeading: string = '';

  constructor(private fb: FormBuilder, private removeFileExtension: RemoveFileExtensionPipe) {
    this.uploadFilesSummaryGrid = this.fb.array([]);
  }

  ngOnInit(): void {
    this.initComponentData();
    this.constructSummaryGrid(this.filesList);
  }

  removeFile(index: number): void {
    this.uploadFilesSummaryGrid.removeAt(index);
  }

  uploadFiles(): void {
    let isValidGridForm: boolean = true;
    this.uploadFilesSummaryGrid.markAllAsTouched();
    this.uploadFilesSummaryGrid.controls.forEach((x) => {
      x.markAsTouched();
      if (!x.valid) isValidGridForm = false;
    });
    if (!isValidGridForm) return;
    this.toUploadFilesList.emit(this.uploadFilesSummaryGrid.value);
  }

  onCancel(): void {
    this.onCancelTheFlow.emit(true);
  }

  ngOnDestroy(): void {
    this.showPriceColumn = false;
    this.filesList = [];
  }

  private initComponentData(): void {
    this.inputDataColumns = this.componentData.inputColumns;
    this.requiredFieldsSubHeading = this.componentData.requiredFieldsSubHeading;
  }

  private constructSummaryGrid(files: File[]): void {
    files.forEach((file) => {
      const dataRow = this.generateFormGroupByColumns(file);
      this.uploadFilesSummaryGrid.push(dataRow);
    });
  }

  private generateFormGroupByColumns(file: File): FormGroup {
    let controlObject: any = {};
    this.componentData.inputColumns.forEach((column: any) => {
      controlObject[column.name] = column.isRequired 
      ? new FormControl(this.getFieldDefaultValue(column.type, file.name ), [ Validators.required, AvesdoValidator.isNotValidFileName ])
      : new FormControl(this.getFieldDefaultValue(column.type, file.name));
    });
    this.injectedStandardRequiredControlFields(controlObject, file);
    return new FormGroup(controlObject);
  }


  private injectedStandardRequiredControlFields(controlObject: any, file: File): void {
    // Bind standard required Columns to continue the Flow
    controlObject.rowFile = new FormControl(file);
    controlObject.uploadingProgress = new FormControl(0);
  }

  private getFieldDefaultValue(fieldType: string, fileName: string): string | number {
    switch(fieldType) {
      case 'text': 
        return this.removeFileExtension.transform(fileName)
      case 'number':
        return 0;
      default: return '';
    }
  }

}
