import { Injectable } from '@angular/core';
import { Endpoint } from '../models/Endpoint';
import { WebhookSubmission } from '../models/WebhookSubmission';

@Injectable({
    providedIn: 'root'
})
export class FormatWebhook {

    constructor() { }

    static formatWebhookSubmission(webhooks: any[], buildingId: number): WebhookSubmission
    {
        var result: WebhookSubmission = {projectId : buildingId , endpointDtos:[]};
        var endpoints: Endpoint[] = [];
        webhooks.forEach((webhook) => {
            if (webhook.id !== undefined) {
                var endpoint: Endpoint = {
                    id : webhook.id, 
                    destinationUrl : webhook.destinationUrl, 
                    projectId : buildingId, 
                    recordStatus : webhook.recordStatus ? 1 : 0
                }
               endpoints.push(endpoint);
            } else {
                var endpoint: Endpoint = {
                    destinationUrl : webhook.destinationUrl, 
                    projectId : buildingId, 
                    recordStatus : webhook.recordStatus ? 1 : 0,
                    endpointEventDtos : [{'eventType': webhook.eventType}]
                }
                endpoints.push(endpoint);
            }
        })
        result.endpointDtos = endpoints;
        return result;
    }
}