import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { LocalStorageNames } from '../shared/enums/LocalStorageNames';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AccountService } from '../shared/services/account.service';


@Injectable({
  providedIn: 'root'
})
export class BuildingSelectedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(): boolean | Observable<boolean> {
    //DeveloperId already set
    if (this.authService.developerId) { return true; }

    //Coming from Login Action with Dev parameter
    const { dev: loginAction } = this.route.snapshot.queryParams;

    if (loginAction) {
      this.authService.resetDeveloperId();
      return this.accountService.getDeveloperIdFromLoginAction(loginAction).pipe(
        catchError(() => { return of(null); }),
        tap((developerId) => {
          if (developerId) {
            this.authService.setDeveloperId(developerId)
          } else {
            this.router.navigate(['select-building']);
          }
        }),
        map((developerId) => {
          return !!developerId;
        })
      );
    }

    //DeveloperId already in local storage
    const storageDeveloperId = localStorage.getItem(LocalStorageNames.CoreDeveloperId);

    if (storageDeveloperId) {
      this.authService.setDeveloperId(parseInt(storageDeveloperId))
      return true;
    }

    //No developer selected
    this.router.navigate(['select-building']);

    return false;

  }
}
