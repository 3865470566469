import { Component, OnInit, Input, Injector } from '@angular/core';
import { CustomDatePipe } from '@avesdo-common/src/public-api';
import { BuildingTimezoneMixin } from '../../mixins/building-timezone.mixin';
import { Base } from 'ng-mix';

@Component({
  selector: 'lib-av-table-date',
  templateUrl: './av-table-date.component.html',
  styleUrls: ['./av-table-date.component.scss']
})
export class AvTableDateComponent extends BuildingTimezoneMixin(Base) implements OnInit {
  @Input() date: any;
  @Input() format: string = 'MMM DD YYYY';
  @Input() timezone?: any;

  constructor(public inj: Injector) { super(inj); }

  getLabel(timezone): string {
    return new CustomDatePipe().transform(this.date, this.format, timezone, false);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
