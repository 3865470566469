import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-bed-bath-label',
  templateUrl: './bed-bath-label.component.html',
  styleUrls: ['./bed-bath-label.component.scss']
})
export class BedBathLabelComponent {
  @Input() count: number;
  @Input() icon: 'bed' | 'bath' = 'bed';

  constructor() { }
}
