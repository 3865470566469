import { PackageSigner } from "@avesdo-common/src/lib/models/package/PackageSigner";
import { Deposit } from "./Deposit";
import { UtilityServices } from "@avesdo-common/src/lib/services/utility.service";

export class EftPayment {
  id: number;
  packageDepositId: number;
  packageDeposit: Deposit;
  status: number;
  paymentUrl: string;
  amount: number;
  payor: PackageSigner;
  payorId?: number;
  payorEmail?: string;
  minimumDueDate?: Date;
  canPackageDepositBeModified?: boolean;
  isCancelable?: boolean;
  isCompleted?: boolean;
  isCancelled?: boolean;
  isNotStarted?: boolean;
  isPending?: boolean;
  isActiveInProgress?: boolean;
  isPassiveInProgress?: boolean;
  isFailed?: boolean;
  isTentative?: boolean;

  constructor(data) {
    const util = new UtilityServices();
    this.id = data.id;
    this.packageDepositId = data.packageDepositId ?? data.packageDeposit?.id;
    this.packageDeposit = new Deposit(data.packageDeposit);
    this.status = data.status;
    this.paymentUrl = data.paymentUrl;
    this.amount = data.amount;
    this.payor = data.payor ? new PackageSigner(data.payor) : undefined;
    this.payorId = data.payorId ?? data.payor?.id;
    this.payorEmail = data.payor?.email ?? data.payorEmail;
    this.minimumDueDate = util.fromMVCDate(data.minimumDueDate);
    this.canPackageDepositBeModified = data.canPackageDepositBeModified;
    this.isCancelable = data.isCancelable;
    this.isCompleted = data.isCompleted;
    this.isCancelled = data.isCancelled;
    this.isNotStarted = data.isNotStarted;
    this.isPending = data.isPending;
    this.isActiveInProgress = data.isActiveInProgress;
    this.isPassiveInProgress = data.isPassiveInProgress;
    this.isFailed = data.isFailed;

    // For payload when submitting
    this.isTentative = data.isTentative;
  }
}
