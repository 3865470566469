import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from '@avesdo-common/src/lib/models/generic/ApiResponse';
import { environment } from '@avesdo-common/src/lib/environments/environment';
import { FormatWebhook } from '../modules/webhook-setting/services/format-webhook.service'

@Injectable({
  providedIn: 'root'
})
export class WebhooksService {

  constructor(private http: HttpClient) { }

  getWebhooks(buildingId: number) : Observable<any[]> {
      const url = `${environment.crmApiUrl}/Endpoint/project/${buildingId}`
    
    return this.http.get<ApiResponse<any[]>>(url).pipe(
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  upsertWebhooks(buildingId: number, webhooks: any): Observable<any> {
    const url =`${environment.crmApiUrl}/Endpoint/project/${buildingId}`

    var params = FormatWebhook.formatWebhookSubmission(webhooks, buildingId);
    return this.http.post<ApiResponse<any>>(url, params).pipe(
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

}
