import { UserDetails } from "../user-models/UserDetails";

export class PackageSigner {
  id: number;
  userLabel: string;
  userPrefix: string;
  status: string;
  userDetail: UserDetails;
  mortgageConsented: any;
  canViewMortgageDocument: boolean;

  constructor(data) {
    this.id = data.id;
    this.userLabel = data.userLabel;
    this.userPrefix = data.userPrefix == null ? '' : data.userPrefix;
    this.status = data.status == null ? '' : data.status;
    this.userDetail = new UserDetails(data.userDetail);
    this.mortgageConsented = data.mortgageConsented;
    this.canViewMortgageDocument = data.canViewMortgageDocument;
  }

  getFullName(includeDisabledSuffix?: boolean) {
    return this.userDetail ? this.userDetail.getFullName(includeDisabledSuffix) : '';
  }

  isAssignee() {
    return this.userLabel.substring(0, 1) === 'o';
  };

  isPurchaser() {
    return this.userLabel.substring(0, 1) === 'c';
  };
}