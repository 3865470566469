import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AvTableColumnConfig } from '../av-table.component';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';

@Component({
  selector: 'lib-av-multi-select-filter',
  templateUrl: './av-multi-select-filter.component.html',
  styleUrls: ['./av-multi-select-filter.component.scss']
})
export class AvMultiSelectFilterComponent {
  @Input() componentConfig: {
    control: FormGroup;
    columnConfig: AvTableColumnConfig;
  }

  constructor(public avHelperService: AvHelperService) { }

  clearCheckboxes() {
    for (const key in this.componentConfig.control.controls) {
      this.componentConfig.control.controls[key].setValue(false);
    }
  }
}
