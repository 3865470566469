import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-av-table-icon',
  templateUrl: './av-table-icon.component.html',
  styleUrls: ['./av-table-icon.component.scss']
})
export class AvTableIconComponent implements OnInit {
  @Input() icon: any;
  @Input() text?: string;
  @Input() show?: boolean = true;
  @Input() data?: any;
  @Output() click = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
