import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Pipe({
  name: 'unitPriceFormat',
})
export class UnitPriceFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return formatCurrency(value, 'en-US', '$', 'USD', '1.0');
  }
}
