import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AvBulkFileUploadComponent } from '../../components/av-bulk-file-upload/av-bulk-file-upload.component';
import { FileUploadComponentData } from '../../models/bulk-file-upload/file-upload-component-data';

@Injectable({
  providedIn: 'root',
})
export class AvBulkFileUploadDialogService {
  constructor(private dialog: MatDialog) {
  }

  dialogRef: MatDialogRef<AvBulkFileUploadComponent, FileUploadComponentData> = null;

  openDialog(componentData: FileUploadComponentData): MatDialogRef<AvBulkFileUploadComponent, FileUploadComponentData> {
    this.dialogRef = this.dialog.open(AvBulkFileUploadComponent, {
      data:  componentData,
      width: `${512}px`,
      height: 'auto',
      panelClass: 'file-upload-dialog'
    });
    return this.dialogRef;
  }

  onUploadSuccess(): void {
    // This onFileUploadSucess method should be JS conroller or directive inside
    window['onFileUploadSucess']();
    this.dialogRef.close();
  }

}

