import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularjsWrapperComponent } from './components/angularjs-wrapper/angularjs-wrapper.component';
import { MainComponent } from './main/main.component';
import { EmptyComponent } from './legacyRoutes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ReloadPageModalComponent } from './components/reload-page-modal/reload-page-modal.component';
import { AppConfigService } from '@avesdo-common/src/lib/services/feature-toggle/app-config.service';
import { ConfigService } from './shared/services/config.service';
import { AuthService } from './auth/services/auth.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgNewWrapperComponent } from './components/ng-new-wrapper/ng-new-wrapper.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SuperAdminModule } from './feature/contracts/modules/super-admin/super-admin/super-admin.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { TokenInterceptor } from './auth/token.interceptor';
import { SelectBuildingComponent } from './components/select-building/select-building.component';
import { CallbackComponent } from './components/callback/callback.component';
import { SilentRenewComponent } from './components/silentRenew/silentRenew.component';
import { DisclosureEmailRerouteComponent } from './components/disclosure-email-reroute/disclosure-email-reroute.component';
import { ErrorCachingInterceptor } from '@avesdo-common/src/lib/services/interceptors/error-caching-interceptor.service';
import { RedirectHomeComponent } from './components/redirect-home/redirect-home.component';
import { Userpilot } from 'userpilot';
import { SurveyComponent } from './components/survey/survey.component';

// Initialize Userpilot
Userpilot.initialize('NX-8ef2ec5b');

// AoT requires an exported function for factories

export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    const configFileLocation = 'assets/config/config.json';
    return appConfigService.load(configFileLocation);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AngularjsWrapperComponent,
    MainComponent,
    EmptyComponent,
    ReloadPageModalComponent,
    NgNewWrapperComponent,
    SelectBuildingComponent,
    CallbackComponent,
    SilentRenewComponent,
    DisclosureEmailRerouteComponent,
    RedirectHomeComponent,
    SurveyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AuthModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    !(environment.production === 'true')
      ? StoreDevtoolsModule.instrument({ maxAge: 10 })
      : [],
    SuperAdminModule
  ],
  // entryComponents: [ReloadPageModalComponent],
  providers: [
    HttpClientModule,
    CurrencyPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCachingInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
