import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ERRORMESSAGES } from '../../constants/error-messages.constant';
import { NotificationService } from '../notifications/notification.service';

@Injectable()
export class ErrorCachingInterceptor implements HttpInterceptor {
  public hasError = new BehaviorSubject<boolean>(false);

  constructor(
    private notifcationSrvc: NotificationService,
    private translateSrvc: TranslateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.hasError.next(false);
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        this.hasError.next(true);
        this.constructErrorForSuccessFales(event);
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.hasError.next(true);
        this.construtErrorResponse(error);
        return EMPTY;
      })
    );
  }

  private constructErrorForSuccessFales(event: HttpEvent<any>): void {
    if (!(event instanceof HttpResponse)) return;
    if (event.body?.Success === undefined) return;
    if (!event.body.Success) {
      this.hasError.next(true);
      this.notifcationSrvc.error(event.body.UserMessage);
    }
  }

  private construtErrorResponse(error: HttpErrorResponse): string {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      errorMsg = this.translateSrvc.instant(ERRORMESSAGES.APPLICATIONERROR);
      return;
    }
    errorMsg = this.getHttpErrorMessage(error);
    this.notifcationSrvc.error(errorMsg);
  }

  private getHttpErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 401:
        return this.translateSrvc.instant(ERRORMESSAGES.UNAUTHORIZED);
      case 500:
        return this.translateSrvc.instant(ERRORMESSAGES.INTERNALSERVER);
      default:
        return this.translateSrvc.instant(ERRORMESSAGES.INTERNALSERVER);
    }
  }
}
