import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'unitSlName',
})
@Injectable({
  providedIn: 'root',
})
export class UnitSlNamePipe implements PipeTransform {
  unit: string;
  sl: string;
  floorPlan: string;
  level: string;
  suite: string;

  constructor(public translateService: TranslateService) {
    this.unit = translateService.instant('GENERIC.UNIT');
    this.sl = translateService.instant('GENERIC.STRATALOT');
    this.floorPlan = translateService.instant('HOME.FLOOR_PLAN').replace(' ', '');
    this.level = translateService.instant('GENERIC.LEVEL');
    this.suite = translateService.instant('GENERIC.SUITE');
  }

  transform(packageDetails: any, area: string, floorPlan?: string, type?: string) {
    if (!packageDetails) return;
    if (!packageDetails.property) return;

    const floorPlanTitle = 
        floorPlan === 'floorplan' && packageDetails.property.floorPlan
        ? ` ${this.floorPlan}. ${packageDetails.property.floorPlan}`
        : '';

    if (area === 'BC' || area === 'AB'|| area === 'AU_EST' || area == 'CO') {
      return this.forAreaBCAC(packageDetails, type, floorPlanTitle);
    }
    if (area === 'ON') {
      return this.forAreaON(packageDetails, type, floorPlanTitle);
    }
    return '';
  }

  private forAreaBCAC(packageDetails: any,type: string,floorPlanTitle: string): string {
    switch (type) {
      case 'sl':
        return `${this.sl}${packageDetails.property.strataLot}${floorPlanTitle}`;
      case 'shortBuilding':
        return `${packageDetails.building.buildingName} - ${this.unit}.${packageDetails.property.unitNumber ?? ''} ${this.sl}${packageDetails.property.strataLot}${floorPlanTitle}`;
      case 'unitSuite':
      default:
        return `${this.unit}: ${packageDetails.property.unitNumber} ${this.sl}${packageDetails.property.strataLot}${floorPlanTitle}`;
    }
  }

  private forAreaON(packageDetails: any,type: string,floorPlanTitle: string): string {
    switch (type) {
      case 'sl':
        return `${this.level} ${packageDetails.property.level} ${this.unit} ${packageDetails.property.strataLot}${floorPlanTitle}`;
      case 'shortBuilding':
        return `${packageDetails.property.unitNumber ? this.suite + '.' + packageDetails.property.unitNumber : ''} ${this.level}.${packageDetails.property.level} ${this.unit}.${packageDetails.property.lotUnit}${floorPlanTitle}`;
      case 'unitSuite':
        return `${this.suite}: ${packageDetails.property.unitNumber ?? ''}${floorPlanTitle}`;
      default:
        return `${this.suite}: ${packageDetails.property.unitNumber ?? ''} ${this.level}.${packageDetails.property.level} ${this.unit}.${packageDetails.property.lotUnit}${floorPlanTitle}`;
    }
  }
}
