import { EnvironmentName } from '@avesdo-common/src/lib/enums/EnvironmentName';
import { Userpilot } from 'userpilot';

export function setUserpilotMetadata(iAuthUser, environment: EnvironmentName) {
  Userpilot.identify(`${iAuthUser.email}_${environment}`, {
    name: iAuthUser.name,
    email: iAuthUser.email,
    environment,
    role: formatRole(iAuthUser.role),
    company: {
      id: iAuthUser.developerId !== undefined ? `${iAuthUser.developerId}_${environment}` : 'N/A',
      name: iAuthUser.developerName !== undefined ? iAuthUser.developerName : 'N/A',
      orgId: iAuthUser.orgId || null,
      environment
    }
  });
}

export function logoutUserpilot() {
  Userpilot.reset();
}

export function triggerUserpilotEvent(event) {
  Userpilot.trigger(event);
}

function formatRoleString(role: string): string {
  switch (role.toLowerCase()) {
    case 'mp_admin':
      return 'admin';
    case 'mp_sales':
      return 'sales';
    case 'mp_developer':
      return 'developer';
    case 'mp_realtor':
      return 'realtor';
    default:
      return role;
  }
}

function formatRole(roles: string | string[]): string {
  if(typeof roles === 'string' || roles instanceof String) {
    return formatRoleString(roles as string);
  }
  return roles.map(role => {
    return formatRoleString(role);
  }).filter(role => role.toLowerCase() !== 'core_user')[0] ?? '';
}
