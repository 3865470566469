import { createAction, props } from '@ngrx/store';
import { AuthUser } from '@avesdo-common/src/lib/models/user-models/AuthUser';
import { IBuilding } from '@avesdo-common/src/lib/models/building-models/Building';
import { Permission } from '@avesdo-common/src/lib/models/user-models/Permission';
import { UserClaimsModel } from '@avesdo-common/src/lib/models/user-models/UserClaimsModel';

export const LOG_IN_SUCCESS = '[Auth] Log in success';
export const LOG_OUT = '[Auth] Log out';

export const REFRESH_TOKEN = '[Auth] Refresh token';
export const REFRESH_USER = '[Auth] Refresh user';
export const REFRESH_USER_DEVELOPERID = '[Auth] Refresh developer Id';

export const LOAD_APPLICATION_PERMISSION = '[Auth] Load Application Permission';
export const LOAD_APPLICATION_PERMISSION_SUCCESS = '[Auth]  Load Application permission success';
export const LOAD_APPLICATION_PERMISSION_FAIL = '[Auth]  Load Application permission fail';

export const LOAD_USER_CLAIMS = '[Auth]  Load User Claims';
export const FETCH_USER_CLAIMS_SUCCESS = '[Auth]  Load User Claims success';
export const FETCH_USER_CLAIMS_FAIL = '[Auth]  Load User Claims fail';

export const logInSuccess = createAction(
  LOG_IN_SUCCESS,
  props<{
    user: AuthUser;
  }>()
);

export const logOut = createAction(LOG_OUT);
export const refreshToken = createAction(REFRESH_TOKEN);
export const refreshUser = createAction(
  REFRESH_USER,
  props<{
    building: IBuilding
  }>()
);
export const refreshUserDeveloperId = createAction(
  REFRESH_USER_DEVELOPERID,
  props<{
    developerId: string
  }>()
);


export const loadApplicationPermission = createAction(
  LOAD_APPLICATION_PERMISSION
);

export const fetchPermissionSuccess = createAction(
  LOAD_APPLICATION_PERMISSION_SUCCESS,
  props<{
    permission: Permission;
  }>()
);

export const fetchPermissionFail = createAction(
  LOAD_APPLICATION_PERMISSION_FAIL
);

export const propogateUserClaims = createAction(LOAD_USER_CLAIMS);

export const fetchUserClaimsSuccess = createAction(
  FETCH_USER_CLAIMS_SUCCESS,
  props<{
    claims: UserClaimsModel;
  }>()
);

export const fetchUserClaimsFail = createAction(
  FETCH_USER_CLAIMS_FAIL
);