export class InputFactory {
	private factoryFunction: Function;

	constructor(factoryFunction: Function) {
		this.factoryFunction = factoryFunction;
	}

	getInput(datum: any) {
		return this.factoryFunction(datum);
	}
} 