import { Directive, HostListener, Self, OnDestroy, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Directive({
  selector: '[libNumberFormatter]'
})
export class NumberFormatterDirective implements OnDestroy {

  @HostListener('focus')
  onFocus() {
    this.removeCommas(this.ngControl.value);
  }

  @HostListener('blur')
  onBlur() {
    let value = this.ngControl.value || '';
    !!value && this.formatPrice(value);
  }

  private numberFormatter: Intl.NumberFormat;
  private unsubscribe$ = new Subject();

  constructor(
    @Self() private ngControl: NgControl,
    private elementRef: ElementRef<HTMLInputElement>
    ) {
    // TODO: Revisit for other locales
    this.numberFormatter = new Intl.NumberFormat('en-CA', { maximumFractionDigits: 0, minimumFractionDigits: 0 });
  }

  updateValue(value) {
    let inputVal = String(value).replace(/[^0-9.]/g, '');
    this.setValue(!!inputVal ? this.validateDecimalValue(inputVal) : '');
  }

  formatPrice(price) {
    this.elementRef.nativeElement.value = price !== null ? this.numberFormatter.format(price) : '';
  }
  
  removeCommas(price) {
    const value = price?.replace(/[^\d.]/g, '');
    this.elementRef.nativeElement.value = price ? value : '';
  }

  validateDecimalValue(numberAsString: string) {
    if (Number.isNaN(Number(numberAsString))) {
      
      // eliminate last char === '.'
      const validNumber = numberAsString.slice(0, numberAsString.length - 1);
      // final check: if it fails, return empty string
      return Number.isNaN(Number(validNumber)) ? '' : validNumber;
    }
    return numberAsString;
  }

  setValue(numberAsString) {
    this.ngControl.control.setValue(numberAsString, { emitEvent: false })
  }

  ngAfterViewInit() {
    this.formatPrice(this.ngControl.value);
    this.ngControl.control.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.updateValue.bind(this));
  }

  ngOnDestroy() {
    this.removeCommas(this.ngControl.value);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}