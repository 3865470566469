import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBuildingSettingsState } from './building-settings.reducer';

export const getBuildingSettings = createFeatureSelector<IBuildingSettingsState>(
  'buildingSettings'
);

export const getBuildingSettingsWhenReady = createSelector(
  getBuildingSettings,
  (buildingSettings: IBuildingSettingsState) => buildingSettings.buildingSettings 
);

export const getStatusColors = createSelector(
  getBuildingSettings,
  (buildingSettings: IBuildingSettingsState) => buildingSettings.statusColors 
);

export const getBuildingTimezone = createSelector(
  getBuildingSettings,
  (buildingSettings: IBuildingSettingsState) => buildingSettings.buildingSettings?.timezoneJavascript 
);

export const isSelectionsSetup = createSelector(
  getBuildingSettings,
  (buildingSettings: IBuildingSettingsState) => buildingSettings.isSelectionsSetup.isSelectionsSetup
);