import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

    transform(list: any[], sortBasedOn: string, reverse: boolean = false): any[] {
        if (!list) {
            return [];
        }

        list = list.slice();

        if (sortBasedOn) {
            list.sort((a, b) => a[sortBasedOn] > b[sortBasedOn] ? 1 : -1);
        } else {
            list.sort((a, b) => a > b ? 1 : -1);
        }
        if (reverse) {
            list.reverse();
        }

        return list;
    }
}
