import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FloorPlan } from '../models/FloorPlan';

@Injectable({
  providedIn: 'root'
})
export class FloorPlanService {

  constructor(private translate: TranslateService) { }
  
  formatBedroomText(floorPlan: FloorPlan | {bedrooms: any, den: any}) {
    if (!floorPlan.bedrooms && !floorPlan.den) {
      return this.translate.instant('GENERIC.STUDIO');
    } else if (floorPlan.den) {
      return `${floorPlan.bedrooms} ${this.translate.instant('GENERIC.BED')} + ${floorPlan.den} ${this.translate.instant('GENERIC.DEN')}`;
    } else {
      return `${floorPlan.bedrooms} ${this.translate.instant('HOME.BEDROOM')}`;
    }
  }
}
