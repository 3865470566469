import { Component, Input, OnInit } from '@angular/core';
import { StringNullableChain } from 'lodash';

@Component({
  selector: 'lib-saved-allocation-column',
  templateUrl: './saved-allocation-column.component.html',
  styleUrls: ['./saved-allocation-column.component.scss']
})
export class SavedAllocationColumnComponent implements OnInit {

  @Input() savedAllocation: any;
  @Input() savedColor: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
