import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { Observable } from 'rxjs';
import { SelectBuildingItem } from '../../shared/models/SelectBuildingItem';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '@avesdo-common/src/public-api';
import { ConfirmationDialogService } from '@avesdo-common/src/lib/services/dialogs/confirmation-dialog.service';

@Component({
  selector: 'app-select-building',
  templateUrl: './select-building.component.html',
  styleUrls: ['./select-building.component.scss']
})
export class SelectBuildingComponent implements OnInit {
  buildingList$: Observable<SelectBuildingItem[]>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  selectBuilding(building: SelectBuildingItem) {
    this.authService.setDeveloperId(building.id);
    this.authService.setBuildingName(building.buildingName);
    this.router.navigate(['']);
  }

  ngOnInit(): void {
    this.buildingList$ = this.authService.getBuildingList();
  }

  onLogout(): void {
    this.confirmationDialogService.openDialog({
      title: 'GENERIC.LOG_OUT_QUESTION',
      message: 'GENERIC.LOG_OUT_CONFIRMATION',
      no: 'GENERIC.NO'
    }).afterClosed().subscribe(response => {
      if (response) {
        this.authService.logout();
      }
    })
  }
}
