import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  yes?: string;
  no?: string;
  publicUrl?: string;
  message?: string;
  title?: string;
}

/**
 *
  Usage:

  this.dialog.open(ConfirmationDialogComponent, {
    data: { message: 'MODALS.CONFIRMATION.DELETE_MESSAGE' }
  });
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

}
