import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AvTableColumnConfig } from '../av-table.component';

@Component({
  selector: 'lib-av-text-filter',
  templateUrl: './av-text-filter.component.html',
  styleUrls: ['./av-text-filter.component.scss']
})
export class AvTextFilterComponent implements OnInit {
  @Input() componentConfig: {
    control: FormControl;
    columnConfig: AvTableColumnConfig;
  }
  @Input() filterType: string = 'text';

  @ViewChild('textInput') textInput: ElementRef;

  focusInput() {
    this.textInput.nativeElement.focus();
  }

  constructor() { }

  ngOnInit() {
  }
}
