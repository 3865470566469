import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'lib-icon-filter',
  templateUrl: './icon-filter.component.html',
  styleUrls: ['./icon-filter.component.scss']
})
export class IconFilterComponent {
  @Input() formGroup: FormGroup;
  @Input() iconClassName? = 'fas fa-star' ;

  handleClick(control: FormControl) {
    control.setValue(!control.value);
  }

  constructor() { }
}
