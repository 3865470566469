import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
 } from '@angular/core';
 import { BehaviorSubject, Subscription } from 'rxjs';
 import { skip } from 'rxjs/operators';

@Component({
  selector: 'lib-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit, OnDestroy {
  @Input() initialRating?: number;
  @Input() iconSize? = 17;
  @Input() numberOfStars = 5;
  @Output() change = new EventEmitter<number>();

  //boolean used to disable css hover effect temporarily after clearing rating
  justCleared: boolean;
  rating$: BehaviorSubject<number>;

  subscription: Subscription;

  constructor() { }

  onStarClick(rating: number) {
    const nextValue = rating === this.rating$.value ? null : rating;
    this.rating$.next(nextValue);
  }

  ngOnInit(): void {
    this.rating$ = new BehaviorSubject<number>(this.initialRating);

    this.subscription = this.rating$.pipe(skip(1))
      .subscribe((rating) => {
        this.justCleared = rating === null;
        this.change.emit(rating);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
