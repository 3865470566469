import { UserRole } from '../../enums/UserRole';
import { IBuilding } from '../building-models/Building';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { DemandLevelSettings } from '../heatmap/DemandLevelSettings';
import { LegacyUserRole } from '../../enums/LegacyUserRole';

export interface IAuthUser {
  realtorId: number;
  name: string;
  email: string;
  role: UserRole[];
  developerId?: string;
  developerName?: string;
}

export class AuthUser implements IAuthUser {
  realtorId: number;
  name: string;
  email: string;
  role: UserRole[];
  legacyRole?: LegacyUserRole;
  developerId?: string;
  developerName?: string;
  userId?: string;
  building?: IBuilding;
  userPreferences?: {
    colorPreference?: string;
    heatmapDemandSettings?: Dictionary<DemandLevelSettings>;
  }

  constructor() {
    this.realtorId = null;
    this.name = '';
    this.email = '';
    this.role = null;
    this.developerId = null;
    this.userPreferences = {};
  }

  isRealtor(): boolean {
    return this.role && this.role.includes(UserRole.Realtor);
  }

  isDeveloper(): boolean {
    return this.role && this.role.includes(UserRole.Developer);
  }

  canViewPostSale(): boolean {
    const permittedRoles = [
      UserRole.Developer,
      UserRole.Admin,
      UserRole.Sales
    ];

    return this.role && this.role.some((role) => permittedRoles.includes(<string>role));
  }

  canViewCrmDmProjectList(): boolean {
    const permittedRoles = [
      UserRole.Developer,
      UserRole.Admin,
      UserRole.Sales,
      UserRole.CRMSales,
      UserRole.CRMDeveloper,
      UserRole.AllowedCRMRoles,
      UserRole.CoreUser
    ];

    return this.role && this.role.some((role) => permittedRoles.includes(<string>role));
  }
}
