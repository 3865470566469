import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-mat-checkbox',
  templateUrl: './ag-mat-checkbox.component.html',
  styleUrls: ['./ag-mat-checkbox.component.scss']
})
export class AgMatCheckboxComponent implements ICellRendererAngularComp {
  private params: any;

  checked = false;
  disabled = false;

  agInit(params: any): void {
    this.params = params;
    this.checked = this.params.value;
    this.disabled = !this.params.colDef.editable;
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange(checked: boolean) {
    this.checked = checked;
    this.params.node.setDataValue(this.params.colDef, this.checked);
  }

  refresh(params: any): boolean {
    return false;
  }
}
