import { combineReducers, ActionReducerMap } from "@ngrx/store";
import { publicItemsReducer, IPublicItemsState } from "../feature/custom-forms/redux/PublicItems/public-items.reducer";
import { InjectionToken } from '@angular/core';

export const avesdoCommonReducer = combineReducers({
	publicItems: publicItemsReducer 
});

export interface IAvesdoCommonState {
	publicItems: IPublicItemsState
}

export const avesdoCommonReducerToken = new InjectionToken<ActionReducerMap<IAvesdoCommonState>>(
  'AvesdoCommonReducers'
);

export const getAvesdoCommonReducers = () => {
  return avesdoCommonReducer;
}