import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@ng-new/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  getDeveloperIdFromLoginAction(loginAction: string): Observable<number> {

    const params = new HttpParams().set('dev', loginAction);

    return this.http
      .get<any>(`${environment.crmApiUrl}/contracts/Account/GetDeveloperIdFromLoginAction`, { params } )
      .pipe(
        map((resp) => resp.Data),
        catchError((error: any) => throwError(error))
      );
  }
}
