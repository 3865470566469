import { Component, Input } from '@angular/core';
import { Upload } from '../../models/generic/Upload';

@Component({
  selector: 'lib-upload-progress-bar',
  templateUrl: './upload-progress-bar.component.html',
  styleUrls: ['./upload-progress-bar.component.scss']
})
export class UploadProgressBarComponent {
  @Input() upload: Upload<any>;
}
