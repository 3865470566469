import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-plain-text',
  templateUrl: './plain-text.component.html',
  styleUrls: ['./plain-text.component.scss']
})
export class PlainTextComponent implements OnInit {

  @Input() displayText: any;
  @Input() clickData: any;
  @Input() displayStyle?: string;
  @Input() displayClass?: string;
  @Output() clickText: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickText() {
    this.clickText.emit(this.clickData);
  }

}
