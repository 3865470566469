import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegacyRouteChangeService {
  legacyRouteChange$ = new Subject<string>();

  constructor() { }

  changeLegacyRoute(path: string): void {
    this.legacyRouteChange$.next(path);
  }
}
