import { Injectable } from '@angular/core';
import {
    ILeadFormCustomStyle,
} from '../models/LeadFormCustomStyling';
import { ICustomStyle } from '../models/LeadFormTemplateResponse';
import { LeadFormStyleConstants } from '../constants/lead-form-style-constants';

@Injectable({
    providedIn: 'root',
})
export class LeadFormStylingService {
    constructor() { }

    setDefaultStyles(selectedTemplate, isFormStylingPage: boolean) {
        let labelColor = LeadFormStyleConstants.labelColor;
        let fontFamily = LeadFormStyleConstants.fontFamily;
        let buttonColor = LeadFormStyleConstants.buttonColor;
        let buttonRadius = LeadFormStyleConstants.buttonRadius;
        let inputRadius = LeadFormStyleConstants.inputRadius;
        let inputBorderColor = LeadFormStyleConstants.inputBorderColor;
        let buttonBorderColor = LeadFormStyleConstants.buttonBorderColor;
        let buttonFontCase = LeadFormStyleConstants.buttonFontCase;
        let buttonFontColor = LeadFormStyleConstants.buttonFontColor;
        let buttonFontSize = LeadFormStyleConstants.buttonFontSize;
        let buttonColorOnHover = LeadFormStyleConstants.buttonColorOnHover;
        let buttonFontColorOnHover = LeadFormStyleConstants.buttonFontColorOnHover;
        let buttonBorderColorOnHover =
            LeadFormStyleConstants.buttonBorderColorOnHover;
        let formContainerWidth = LeadFormStyleConstants.formContainerWidth;
        let formPaddingTop = LeadFormStyleConstants.formPaddingTop;
        let formPaddingLeft = LeadFormStyleConstants.formPaddingLeft;
        let formPaddingRight = LeadFormStyleConstants.formPaddingRight;
        let formPaddingBottom = LeadFormStyleConstants.formPaddingBottom;
        let labelFontCase = LeadFormStyleConstants.labelFontCase;
        let labelFontWeight = LeadFormStyleConstants.labelFontWeight;
        let labelFontSize = LeadFormStyleConstants.labelFontSize;
        let inputBorderColorOnHover = LeadFormStyleConstants.inputBorderColorOnHover;
        let formBackgroundColor = LeadFormStyleConstants.formBackgroundColor;
        let buttonAlignment = LeadFormStyleConstants.buttonAlignment;


        if (selectedTemplate.templateCss && selectedTemplate.templateCss.length > 0) {
            selectedTemplate.templateCss.forEach((element: ICustomStyle) => {
                const cssObject = JSON.parse(element.cssStyle);
                switch (element.controlType) {
                    case 'button': {
                        if (cssObject.hasOwnProperty('color')) {
                            buttonColor = cssObject.color;
                        }
                        if (cssObject.hasOwnProperty('border-radius')) {
                            buttonRadius = isFormStylingPage ? cssObject['border-radius'].replace('px', '') :
                                cssObject['border-radius'];
                        }
                        if (cssObject.hasOwnProperty('border-color')) {
                            buttonBorderColor = cssObject['border-color'];
                        }
                        if (cssObject.hasOwnProperty('font-color')) {
                            buttonFontColor = cssObject['font-color'];
                        }
                        if (cssObject.hasOwnProperty('font-size')) {
                            buttonFontSize = isFormStylingPage ? cssObject['font-size'].replace('px', '') :
                                cssObject['font-size'];
                        }
                        if (cssObject.hasOwnProperty('font-case')) {
                            buttonFontCase = cssObject['font-case'];
                        }
                        if (cssObject.hasOwnProperty('font-color-hover')) {
                            buttonFontColorOnHover = cssObject['font-color-hover'];
                        }
                        if (cssObject.hasOwnProperty('color-hover')) {
                            buttonColorOnHover = cssObject['color-hover'];
                        }
                        if (cssObject.hasOwnProperty('border-color-hover')) {
                            buttonBorderColorOnHover = cssObject['border-color-hover'];
                        }
                        if (cssObject.hasOwnProperty('alignment')) {
                            buttonAlignment = cssObject['alignment'];
                        }
                        break;
                    }
                    case 'label': {
                        if (cssObject.hasOwnProperty('color')) {
                            labelColor = cssObject.color;
                        }
                        if (cssObject.hasOwnProperty('font-case')) {
                            labelFontCase = cssObject['font-case'];
                        }
                        if (cssObject.hasOwnProperty('font-weight')) {
                            labelFontWeight = cssObject['font-weight'];
                        }
                        if (cssObject.hasOwnProperty('font-size')) {
                            labelFontSize = isFormStylingPage ? cssObject['font-size'].replace('px', '') :
                                cssObject['font-size'];
                        }
                        break;
                    }
                    case 'form': {
                        if (cssObject.hasOwnProperty('font-family')) {
                            fontFamily = cssObject['font-family'];
                        }
                        if (cssObject.hasOwnProperty('padding-top')) {
                            formPaddingTop = isFormStylingPage ? cssObject['padding-top'].replace('px', '') :
                                cssObject['padding-top'];
                        }
                        if (cssObject.hasOwnProperty('padding-bottom')) {
                            formPaddingBottom = isFormStylingPage ? cssObject['padding-bottom'].replace('px', '') :
                                cssObject['padding-bottom'];
                        }
                        if (cssObject.hasOwnProperty('padding-left')) {
                            formPaddingLeft = isFormStylingPage ? cssObject['padding-left'].replace('px', '') :
                                cssObject['padding-left'];
                        }
                        if (cssObject.hasOwnProperty('padding-right')) {
                            formPaddingRight = isFormStylingPage ? cssObject['padding-right'].replace('px', '') :
                                cssObject['padding-right'];
                        }
                        if (cssObject.hasOwnProperty('form-width')) {
                            formContainerWidth = isFormStylingPage ? cssObject['form-width'].replace('px', '') :
                                cssObject['form-width'];
                        }
                        if (cssObject.hasOwnProperty('background-color')) {
                            formBackgroundColor = cssObject['background-color'];
                        }
                        break;
                    }
                    case 'inputBox': {
                        if (cssObject.hasOwnProperty('border-radius')) {
                            inputRadius = isFormStylingPage ? cssObject['border-radius'].replace('px', '') :
                                cssObject['border-radius'];
                        }
                        if (cssObject.hasOwnProperty('border-color')) {
                            inputBorderColor = cssObject['border-color'];
                        }
                        if (cssObject.hasOwnProperty('border-color-onhover')) {
                            inputBorderColorOnHover = cssObject['border-color-onhover'];
                        }
                        break;
                    }
                }
            });

        }


        const styleResponse: ILeadFormCustomStyle = {
            labelColor,
            fontFamily,
            buttonColor,
            buttonRadius,
            inputRadius,
            inputBorderColor,
            buttonBorderColor,
            buttonFontCase,
            buttonFontColor,
            buttonFontSize,
            buttonColorOnHover,
            buttonBorderColorOnHover,
            buttonFontColorOnHover,
            formContainerWidth,
            formPaddingBottom,
            formPaddingTop,
            formPaddingRight,
            formPaddingLeft,
            labelFontCase,
            labelFontWeight,
            labelFontSize,
            inputBorderColorOnHover,
            buttonAlignment,
            formBackgroundColor
        };
        return styleResponse;
    }


    coversionForCssValues(fieldName, isFormStylingPage) {
        return isFormStylingPage ? fieldName.replace('px', '') :
            fieldName;
    }

    setButtonStyles(selectedStyle: ILeadFormCustomStyle) {
        const buttonStyles = JSON.stringify({
            'color': `${selectedStyle.buttonColor}`,
            'border-radius': `${selectedStyle.buttonRadius}` + 'px',
            'border-color': `${selectedStyle.buttonBorderColor}`,
            'font-case': `${selectedStyle.buttonFontCase}`,
            'font-size': `${selectedStyle.buttonFontSize}` + 'px',
            'font-color': `${selectedStyle.buttonFontColor}`,
            'font-color-hover': `${selectedStyle.buttonFontColorOnHover}`,
            'color-hover': `${selectedStyle.buttonColorOnHover}`,
            'border-color-hover': `${selectedStyle.buttonBorderColorOnHover}`,
            'alignment': `${selectedStyle.buttonAlignment}`,
        });

        const buttonStyle: ICustomStyle = {
            controlType: 'button',
            cssStyle: buttonStyles
        };
        return buttonStyle;
    }


    setInputStyles(selectedStyle: ILeadFormCustomStyle) {
        const inputStyles = JSON.stringify({
            'border-radius': `${selectedStyle.inputRadius}` + 'px',
            'border-color': `${selectedStyle.inputBorderColor}`,
            'border-color-onhover': `${selectedStyle.inputBorderColorOnHover}`,
        });
        const inputStyle: ICustomStyle = {
            controlType: 'inputBox',
            cssStyle: inputStyles
        };
        return inputStyle;
    }

    setLabelStyles(selectedStyle: ILeadFormCustomStyle) {
        const labelStyles = JSON.stringify({
            'color': `${selectedStyle.labelColor}`,
            'font-case': `${selectedStyle.labelFontCase}`,
            'font-weight': `${selectedStyle.labelFontWeight}`,
            'font-size': `${selectedStyle.labelFontSize}` + 'px',
        });
        const labelStyle: ICustomStyle = {
            controlType: 'label',
            cssStyle: labelStyles
        };
        return labelStyle;
    }

    setFormStyles(selectedStyle: ILeadFormCustomStyle) {
        const formStyles = JSON.stringify({
            'font-family': `${selectedStyle.fontFamily}`,
            'form-width': `${selectedStyle.formContainerWidth}` + 'px',
            'padding-top': `${selectedStyle.formPaddingTop}` + 'px',
            'padding-bottom': `${selectedStyle.formPaddingBottom}` + 'px',
            'padding-left': `${selectedStyle.formPaddingLeft}` + 'px',
            'padding-right': `${selectedStyle.formPaddingRight}` + 'px',
            'background-color': `${selectedStyle.formBackgroundColor}`
        });
        const formStyle: ICustomStyle = {
            controlType: 'form',
            cssStyle: formStyles
        };
        return formStyle;
    }


    setStylesViewToPreview(selectedStyle: ILeadFormCustomStyle) {
        document.documentElement.style.setProperty(
            '--form-button-color',
            selectedStyle.buttonColor
        );
        document.documentElement.style.setProperty(
            '--form-button-radius',
            selectedStyle.buttonRadius
        );
        document.documentElement.style.setProperty(
            '--form-button-border-color',
            selectedStyle.buttonBorderColor
        );
        document.documentElement.style.setProperty(
            '--form-button-font-size',
            selectedStyle.buttonFontSize
        );

        document.documentElement.style.setProperty(
            '--form-button-font-color',
            selectedStyle.buttonFontColor
        );

        document.documentElement.style.setProperty(
            '--form-button-font-case',
            selectedStyle.buttonFontCase
        );

        document.documentElement.style.setProperty(
            '--form-label-color',
            selectedStyle.labelColor
        );
        document.documentElement.style.setProperty(
            '--form-font-family',
            selectedStyle.fontFamily
        );
        document.documentElement.style.setProperty(
            '--form-input-border-color',
            selectedStyle.inputBorderColor
        );
        document.documentElement.style.setProperty(
            '--form-input-border-radius',
            selectedStyle.inputRadius
        );
        document.documentElement.style.setProperty(
            '--form-button-color-onhover',
            selectedStyle.buttonColorOnHover
        );
        document.documentElement.style.setProperty(
            '--form-button-font-color-onhover',
            selectedStyle.buttonFontColorOnHover
        );
        document.documentElement.style.setProperty(
            '--form-button-border-color-onhover',
            selectedStyle.buttonBorderColorOnHover
        );
        document.documentElement.style.setProperty(
            '--form-input-border-color-onhover',
            selectedStyle.inputBorderColorOnHover
        );
        document.documentElement.style.setProperty(
            '--form-label-font-case',
            selectedStyle.labelFontCase
        );
        document.documentElement.style.setProperty(
            '--form-label-font-weight',
            selectedStyle.labelFontWeight
        );
        document.documentElement.style.setProperty(
            '--form-label-font-size',
            selectedStyle.labelFontSize
        );
        document.documentElement.style.setProperty(
            '--form-width',
            selectedStyle.formContainerWidth
        );
        document.documentElement.style.setProperty(
            '--form-padding-top',
            selectedStyle.formPaddingTop
        );
        document.documentElement.style.setProperty(
            '--form-padding-bottom',
            selectedStyle.formPaddingBottom
        );
        document.documentElement.style.setProperty(
            '--form-padding-right',
            selectedStyle.formPaddingRight
        );
        document.documentElement.style.setProperty(
            '--form-padding-left',
            selectedStyle.formPaddingLeft
        );
        document.documentElement.style.setProperty(
            '--form-background-color',
            selectedStyle.formBackgroundColor
        );
        document.documentElement.style.setProperty(
            '--form-button-alignment',
            selectedStyle.buttonAlignment
        );


    }
}
