import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublicItem } from '../models/PublicItem';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@avesdo-common/src/lib/environments/environment';
import { ApiResponse } from '@avesdo-common/src/lib/models/generic/ApiResponse';
import { IClassificationItem } from '@avesdo-common/src/lib/models/classifications/ClassificationItem';

@Injectable({
  providedIn: 'root'
})
export class FormItemsService {

  constructor(private http: HttpClient) { }

  getPublicItems(key: string): Observable<PublicItem[]> {
    const url = `${environment.crmRerouteUrl}/Form/public/Items/${key}`;

    return this.http.get<PublicItem[]>(url).pipe(
      catchError((error) => throwError(error))
    );
  }

  getClassificationItems(classificationNames: string[]) {
    const url = `${environment.crmApiUrl}/items/classificationNames`;

    return this.http.post<ApiResponse<IClassificationItem[]>>(url, classificationNames).pipe(
      map((resp) => resp.data),
      catchError((error) => throwError(error))
    );
  }
}
