import { Injectable, OnDestroy } from '@angular/core';
import { BaseInjectorConstructor } from 'ng-mix';
import { Subscription } from 'rxjs';

export const HasSubscriptionMixin = <Tbase extends BaseInjectorConstructor>(superClass: Tbase) => {

  @Injectable()
  abstract class HasSubscription extends superClass implements OnDestroy {
    subscription = new Subscription();

    ngOnDestroy(): void { 
      super.ngOnDestroy();
      this.subscription.unsubscribe();
    }
  }

  return HasSubscription;
}