import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BulkFileUploadService {

  constructor(private http: HttpClient) {}

  public uploadBulkFiles(uploadUrl:string,formData: FormData): Observable<any> {
    const url = `${environment.crmApiUrl}/${uploadUrl}`;
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: "events",
    }).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
