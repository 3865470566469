import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { LOAD_APPLICATION_PERMISSION, fetchPermissionSuccess, fetchPermissionFail, fetchUserClaimsSuccess, fetchUserClaimsFail, LOAD_USER_CLAIMS, } from './auth.actions';
import { AuthService } from '../services/auth.service';
import { UserClaimsModel } from '@avesdo-common/src/lib/models/user-models/UserClaimsModel';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {}
  loadPermission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_APPLICATION_PERMISSION),
      switchMap(() => this.doFetchPermission())
    );
  });

  doFetchPermission() {
    return this.authService.loadApplicationPermissions().pipe(
      switchMap(result => {
        if (!!result) {
              return of(fetchPermissionSuccess({ permission: result }));
            }
          }),
          catchError(err => of(fetchPermissionFail()))
    );
  }


  loadUserClaims$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_USER_CLAIMS),
      switchMap(() => this.doFetchUserClaims())
    );
  });

  doFetchUserClaims() {
    return this.authService.getUserClaims().pipe(
      switchMap(result => {
        if (!!result) {
              return of(fetchUserClaimsSuccess({ claims: new UserClaimsModel(result) }));
            }
          }),
          catchError(err => of(fetchUserClaimsFail()))
    );
  }
}
