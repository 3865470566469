import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@ng-new/src/app/auth/services/auth.service';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '@avesdo-common/src/lib/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private httpClient: HttpClient,
              private authService: AuthService) { }

  fetchOrganizationAccount() {
    const params = {
      developerId: this.authService.developerId,
      paymentProviders: ['Stripe']
    };
    const url = `${environment.crmApiUrl}/OrganizationAccount/search`;

    return this.httpClient.post(url, params).pipe(
      map((resp: any) => resp.data),
      catchError((error: any) => throwError(error))
    );
  }

  createNewMarketingOrganizationAssociation(buildingId, parentOrganizationId, marketingOrgName) {
    const params = {
      buildingId,
      parentOrganizationId,
      names: [marketingOrgName]
    };
    const url = `${environment.crmApiUrl}/Organization/associations`;

    return this.httpClient.post(url, params).pipe(
      map((resp: any) => resp),
      catchError((error: any) => throwError(error))
    );
  }
}
