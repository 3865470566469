import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitHeaderName',
})
@Injectable({
  providedIn: 'root',
})
export class UnitHeaderNamePipe implements PipeTransform {

  constructor() {
  }

  transform(type: string, area: string) {
      if (area === 'BC' || area === 'AB'|| area === 'AU_EST' || area == 'CO') { return this.labelForACBC(type); }
      if (area === 'ON') { return this.labelForON(type); }
      return '';
  }

  private labelForACBC(type: string): string {
    return this.unitHeaderNameLabelForACBC(type)
  }

  private labelForON(type: string): string {
    return this.unitHaderNameForON(type);
  }

  private unitHeaderNameLabelForACBC(type: string): string {
    switch(type) {
      case 'unitNumber':
        return 'Unit Number';
      case 'SL.':
        return 'SL.';
      default: 
        return 'Unit';
    }
  }

  private unitHaderNameForON(type: string): string {
    switch(type) {
      case 'unitNumber':
        return 'Suite Number';
      case 'SL.':
        return 'Level Unit';
      default: 
        return 'Suite';
    }
  }
}
