import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomForm } from '@avesdo-common/src/lib/feature/custom-forms/services/custom-form-model.service';

@Component({
  selector: 'app-create-realtor-dialog',
  templateUrl: './create-realtor-dialog.component.html',
  styleUrls: ['./create-realtor-dialog.component.scss']
})
export class CreateRealtorDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { realtorCustomForm: CustomForm, setEmail: (string) => void } ,
    public dialogRef: MatDialogRef<CreateRealtorDialogComponent, boolean>
  ) { }

  useExistingEmail(value) {
    this.data.setEmail(value);
    this.dialogRef.close(true);
  }

  createRealtor() {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
  }

}
