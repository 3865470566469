import { Component, OnInit, ApplicationRef } from '@angular/core';
import { SelectionService } from '../../services/selection.service';
import { SelectionFloorPlates, SelectionFloorPlate } from '@avesdo-common/src/lib/feature/selection/models/SelectionFloorPlates';
import { PropertyDetail } from '@avesdo-common/src/lib/feature/selection/models/PropertyDetail';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { forkJoin } from 'rxjs';
import { XYCoordinates } from '@avesdo-common/src/lib/models/generic/XYCoordinates';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';
import { SelectionPreviewDialogComponent } from '@avesdo-common/src/lib/feature/selection/components/selection-preview-dialog/selection-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { NotificationService } from '@avesdo-common/src/lib/services/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-selection-setup',
  templateUrl: './selection-setup.component.html',
  styleUrls: ['./selection-setup.component.scss']
})
export class SelectionSetupComponent implements OnInit {
  data: SelectionFloorPlates;
  selectedFloorPlate: SelectionFloorPlate;
  selectedPropertiesByFloor: Dictionary<PropertyDetail>;
  propertyPositions: Dictionary<XYCoordinates>;
  dragging: boolean;

  get propertyBeingEdited() {
    return this.selectedPropertiesByFloor[this.selectedFloorPlate.floorPlate.id];
  }

  constructor(
    public appRef: ApplicationRef,
    public dialog: MatDialog,
    private selectionService: SelectionService,
    private avHelperService: AvHelperService,
    private notificationService: NotificationService,
    private translateService: TranslateService
    ) { }

  dropped(event) {
    const { nativeElement } = event.source.element;
    const canvas = document.getElementById('floor-plate-canvas');
    const matrix = this.avHelperService.getCssXYTranslate(nativeElement);
    this.propertyPositions[this.propertyBeingEdited.id] = this.getPercentagePosition(
      matrix[0],
      matrix[1],
      canvas
    );
  }

  hasNoPosition(percentagePosition: XYCoordinates) {
    return !percentagePosition;
  }

  prev() {
    const { properties } = this.selectedFloorPlate;
    const index = properties.indexOf(this.propertyBeingEdited);
    if (index > 0) {
      this.selectedPropertiesByFloor[this.selectedFloorPlate.floorPlate.id] = properties[index - 1];
    }
  }

  next() {
    const { properties } = this.selectedFloorPlate;
    const index = properties.indexOf(this.propertyBeingEdited);
    if (properties[index + 1]) {
      this.selectedPropertiesByFloor[this.selectedFloorPlate.floorPlate.id] = properties[index + 1];
    }
  }

  resetPropertyPosition() {
    this.propertyPositions[this.propertyBeingEdited.id] = null;
  }

  preview() {
    const copy = cloneDeep(this.data);

    copy.floorPlates = copy.floorPlates.map((floorPlate) => {
      return {
        ...floorPlate,
        properties: floorPlate.properties.map((property) => ({
          ...property,
          checkboxPosition: this.propertyPositions[property.id]
        }))
      };
    });

    this.dialog.open(SelectionPreviewDialogComponent, {
      data: {
        data: copy,
        selectedFloorPlate: copy.floorPlates.find(
          (floorPlate) => floorPlate.floorPlate.id === this.selectedFloorPlate.floorPlate.id
        ),
      }
    });
  }

  autoMapPropertyPosition() {
    Object.keys(this.propertyPositions).forEach(key => {
      this.propertyPositions[key] = {
        x: -10,
        y: -10
      }
    });
  }

  submit() {
    const payload = Object.keys(this.propertyPositions)
      .map((key) => ({ id: parseInt(key) , checkboxPosition: this.propertyPositions[key]}));

    this.selectionService.updatePropertiesByMode(payload, 'selectionCoordinates')
      .subscribe((response) => {
        const msg = this.translateService.instant('GENERIC.SUCCESS');
        this.notificationService.success(msg);

      }, (error) => {
        this.notificationService.error(error);
      });
  }

  getPixelPosition(percentagePosition: XYCoordinates) {
    const div = document.getElementById('floor-plate-canvas');

    if (!div || this.dragging) {
      return undefined;
    } // Unbind the cdkDragFreeDragPosition while dragging;

    if (this.hasNoPosition(percentagePosition)) {
      return { x: div.clientWidth - 534.5, y: -82 };
    }

    const{ x, y } = percentagePosition;

    return {
      x: x / 100 * div.clientWidth,
      y: y / 100 * div.clientHeight
    };
  }

  private getPercentagePosition(xPixel: number, yPixel: number, div: HTMLElement) {
    return {
      x: xPixel / div.clientWidth * 100,
      y: yPixel / div.clientHeight * 100
    };
  }

  ngOnInit() {
    forkJoin([
      this.selectionService.getBuildings(),
      this.selectionService.getFloorPlates(),
      this.selectionService.getPropertyDetails()
    ]).subscribe(([buildings, floorPlates, propertyDetails]) => {
      this.data = new SelectionFloorPlates(buildings, floorPlates, propertyDetails);

      this.selectedFloorPlate = this.data.floorPlates[0];

      this.selectedPropertiesByFloor = this.data.floorPlates
        .reduce((dict, selectionFloorPlate) => {
          const { floorPlate, properties } = selectionFloorPlate;
          dict[floorPlate.id] = properties[0];
          return dict;
        }, {});

      this.propertyPositions = propertyDetails
        .reduce((dict, property) => {
        dict[property.id] = property.checkboxPosition;
        return dict;
      }, {});
    });
  }
}
