import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  success(message: string, duration?: number, action?: string) {
    this.snackBar.open(message, action, {
      duration: duration ? duration : 1000,
      panelClass: ['bg-success', 'text-white']
    });
  }

  info(message: string, duration?: number) {
    this.snackBar.open(message, null, {
      duration: duration ? duration : 1000,
      panelClass: ['bg-info', 'text-white']
    });
  }

  error(message: string, verticalPosition: MatSnackBarVerticalPosition = 'bottom') {
    this.snackBar.open(message, 'Close!', {
      duration: 10000,
      panelClass: ['bg-danger', 'text-white'],
      verticalPosition
    });
  }
}
