import { Injectable } from '@angular/core';
import {
  MaxFileSize,
} from '../../constants/file-upload-constants';

@Injectable({
  providedIn: 'root',
})
export class FileValidationService {
  public isValidFileType(selectedFile: File, allowedFileTypes: string[]): boolean {
    return allowedFileTypes.includes(selectedFile.type);
  }

  public isValidFileSize(selectedFile: File): boolean {
    return selectedFile.size <= MaxFileSize * 1024 * 1024;
  }
}
