export interface IUserClaims {
  canChangePrice: boolean;
  canManageStaff: boolean;
  canReassign: boolean;
  canSignAsDeveloper: boolean;
  canCollapsePackage: boolean;
  canUnreserveAny: boolean;
  canSell: boolean;
  canSearchByUnitNumber: boolean;
  canRejectAnyDoc: boolean;
  canVerifyDeal: boolean;
  canDoProjectSetting: boolean;
  canChangeDealStatus: boolean;
  canDoAdmin: boolean;
  canDoPrivilegeAdmin: boolean;
  isCRMEnabled: boolean;
  isMPEnabled: boolean;
  canDownloadExecutiveReport: boolean;
  updateClosingDate: boolean;
  updateEstimatedClosingDate: boolean;
  email: string;
}

export class UserClaimsModel implements IUserClaims {
  canChangePrice: boolean = false;
  canManageStaff: boolean = false;
  canReassign: boolean = false;
  canSignAsDeveloper: boolean = false;
  canCollapsePackage: boolean = false;
  canUnreserveAny: boolean = false;
  canSell: boolean = false;
  canSearchByUnitNumber: boolean = false;
  canRejectAnyDoc: boolean = false;
  canVerifyDeal: boolean = false;
  canDoProjectSetting: boolean = false;
  canChangeDealStatus: boolean = false;
  canDoAdmin: boolean = false;
  canDoPrivilegeAdmin: boolean = false;
  isCRMEnabled: boolean = false;
  isMPEnabled: boolean = false;
  canDownloadExecutiveReport: boolean = false;
  updateClosingDate: boolean = false;
  updateEstimatedClosingDate: boolean = false;
  email: string = '';
  
  constructor(userClaims?: IUserClaims) {
    if (!userClaims) return;
    this.canChangeDealStatus = userClaims.canChangeDealStatus;
    this.canChangePrice = userClaims.canChangePrice;
    this.canManageStaff = userClaims.canManageStaff;
    this.canReassign = userClaims.canReassign;
    this.canSignAsDeveloper = userClaims.canSignAsDeveloper;
    this.canCollapsePackage = userClaims.canCollapsePackage;
    this.canUnreserveAny = userClaims.canUnreserveAny;
    this.canSell = userClaims.canSell;
    this.canSearchByUnitNumber = userClaims.canSearchByUnitNumber;
    this.canRejectAnyDoc = userClaims.canRejectAnyDoc;
    this.canVerifyDeal = userClaims.canVerifyDeal;
    this.canDoProjectSetting = userClaims.canDoProjectSetting;
    this.canChangeDealStatus = userClaims.canChangeDealStatus;
    this.canDoAdmin = userClaims.canDoAdmin;
    this.canDoPrivilegeAdmin = userClaims.canDoPrivilegeAdmin;
    this.isCRMEnabled = userClaims.isCRMEnabled;
    this.isMPEnabled = userClaims.isMPEnabled;
    this.canDownloadExecutiveReport = userClaims.canDownloadExecutiveReport;
    this.updateClosingDate = userClaims.updateClosingDate;
    this.updateEstimatedClosingDate = userClaims.updateEstimatedClosingDate;
    this.email = userClaims.email;
  }
}
