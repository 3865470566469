import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '@avesdo-common/src/lib/services/language/language.service';
import { EftPaymentService } from '../../feature/contracts-new/modules/reporting/services/eftPayment.service';

@Component({
  selector: 'app-redirect-home',
  templateUrl: './redirect-home.component.html',
  styleUrls: ['./redirect-home.component.scss']
})
export class RedirectHomeComponent implements OnInit {
  readonly loginPage = ['/Account/Login'];
  icon: string;
  title: string;
  message: {text: string, bold?: boolean}[];
  isLoading: boolean = true;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private eftPaymentService: EftPaymentService
  ) { }

  get routeParams() {
    return this.route.snapshot.paramMap;
  }

  get routeQueries() {
    return this.route.snapshot.queryParamMap;
  }

  bankAccountConnected() {
    const bankAccountId = this.routeQueries.get('Token') ?? this.routeQueries.get('token');
    this.eftPaymentService.acceptPadAgreement(
      this.routeParams.get('id'),
      this.routeParams.get('securityToken'),
      bankAccountId
    ).subscribe(response => {
      this.isLoading = false;
      if (!response.success) {
        this.message =  [{text: response.message ?? 'ERRORS.UNKNOWN'}];
        return;
      }
      if (bankAccountId) {
        this.icon = 'fal fa-check-circle redirect-home__color--green';
        this.title = 'GENERIC.BANK_ACCOUNT_LINKED';
        this.message = [{text: 'GENERIC.BANK_ACCOUNT_LINKED_MSG'}];
        return;
      }
      this.icon = 'fal fa-exclamation-circle text-warning';
      this.title = 'GENERIC.BANK_ACCOUNT_CONFIRMED';
      this.message = response.hasDueDatePassed ? [{
        text: 'GENERIC.BANK_ACCOUNT_LINKED_DATE_PAST_MSG_1',
        bold: true
      }, {
        text: 'GENERIC.BANK_ACCOUNT_LINKED_DATE_PAST_MSG_2'
      }] : [{text: 'GENERIC.BANK_ACCOUNT_CONFIRMED_MSG'}];
    },
      () => this.message = [{text: 'ERRORS.UNKNOWN'}]
    );
  }

  ngOnInit(): void {
    this.languageService.initialize();
    switch (this.routeParams.get('pageType')) {
      case 'BankAccountConnected':
        this.bankAccountConnected();
        break;
    }
  }

}
