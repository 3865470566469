import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AvTableColumnConfig } from '../av-table.component';

@Component({
  selector: 'lib-av-range-filter',
  templateUrl: './av-range-filter.component.html',
  styleUrls: ['./av-range-filter.component.scss']
})
export class AvRangeFilterComponent {
  @Input() componentConfig: {
    control: FormGroup;
    columnConfig: AvTableColumnConfig;
  }

  isFilterActive() {
    const { control } = this.componentConfig;
    return control.get('min').value !== null || control.get('max').value !== null;
  }

  constructor() { }
}
