import { AuthService } from './services/auth.service';
import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.headers.get('Anonymous') === 'true') {
      request.headers.set('Anonymous', undefined);
      return next.handle(request);
    }

    const token = this.authService.getJwtToken();
    if (token) {
      request = this.addToken(request, token);
    }

    const developerId = this.authService.developerId;
    if (developerId) {
      request = this.addDeveloperId(request, developerId.toString());
    }

    return next.handle(request);
  }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  addDeveloperId(request: HttpRequest<any>, developerId: string) {
    return request.clone({
      setHeaders: {
        DeveloperId: developerId
      }
    });
  }
}
