import { FloorPlate } from '@avesdo-common/src/lib/feature/selection/models/FloorPlate';
import { PropertyDetail } from '@avesdo-common/src/lib/feature/selection/models/PropertyDetail';
import { Building } from '@avesdo-common/src/lib/feature/selection/models/Building';

export class SelectionFloorPlates {
  building: Building;
  floorPlates: SelectionFloorPlate[];

  constructor(
      buildings: Building[],
      floorPlates: FloorPlate[],
      propertyDetails: PropertyDetail[]
    ) {
      this.building = buildings[0];
      this.floorPlates = this.sortPropertiesByFloorPlate(floorPlates, propertyDetails);
  }

  sortPropertiesByFloorPlate(floorPlates: FloorPlate[], propertyDetails: PropertyDetail[]) {
    return floorPlates.map((floorPlate) => ({
      floorPlate,
      properties: propertyDetails
      .filter((property) =>
        property.buildingId === this.building.id
        && property.floorSection === floorPlate.floorSection
      )
    }));
  }
}

export interface SelectionFloorPlate {
  floorPlate: FloorPlate;
  properties: PropertyDetail[];
}
