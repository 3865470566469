import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SelectionFloorPlates, SelectionFloorPlate } from '@avesdo-common/src/lib/feature/selection/models/SelectionFloorPlates';
import { PropertyDetail } from '@avesdo-common/src/lib/feature/selection/models/PropertyDetail';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'lib-selection-view',
  templateUrl: './selection-view.component.html',
  styleUrls: ['./selection-view.component.scss']
})
export class SelectionViewComponent {
  @Input() data: SelectionFloorPlates;
  @Input() selectedFloorPlate: SelectionFloorPlate;
  @Input() selectedProperties: SelectionModel<PropertyDetail>;

  @Output() propertyClick = new EventEmitter<PropertyDetail>();

  constructor() { }
}
