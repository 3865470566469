
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFeaturePermissionRespsonse, IPermission } from '@crm/src/app/permissions/model/permissions';
import { FeaturePermissions } from '@ng-new/src/app/feature/closing/enums/FeaturePermissions';
import { IFeaturePermissionsState } from './feature-permissions.reducer';

export const getFeaturePermissions = createFeatureSelector<IFeaturePermissionsState>(
  'featurePermissions'
);

export const canActivateModule = (moduleName: string) => createSelector(
  getFeaturePermissions,
  (state: IFeaturePermissionsState): boolean => {
    const { featurePermissions } = state;
    if (!featurePermissions) return undefined;

    const permission = featurePermissions.find((permission) => permission.parentFeature === null && permission.value === moduleName);
    return !!permission;
  }
);

export const canWriteClosingDatesPermission = createSelector(
  getFeaturePermissions,
  (state: IFeaturePermissionsState): ClosingDatePermissions => {

    const hasWritePermission = (
      featurePermissions: IFeaturePermissionRespsonse[],
      permissionName: string,
      ): boolean => {
        return !!(featurePermissions && 
          featurePermissions.find((permission) => 
              permission.value === permissionName 
              && permission.permissions.find((permission) => permission.value === 'Write')
              && permission.parentFeature
              && permission.parentFeature.value === 'Closing')
      );
    }

    const { featurePermissions } = state;

    const canWriteEstimatedCompletionDate = hasWritePermission(featurePermissions, FeaturePermissions.UpdateEstimatedClosingDate);
    const canWriteActualCompletionDate = hasWritePermission(featurePermissions, FeaturePermissions.UpdateClosingDate);

    return {
      canWriteEstimatedCompletionDate,
      canWriteActualCompletionDate,
    }
  });

export interface ClosingDatePermissions {
  canWriteEstimatedCompletionDate: boolean;
  canWriteActualCompletionDate: boolean;
}
