import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@avesdo-common/src/lib/environments/environment';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';
import { ApiResponse } from '@avesdo-common/src/lib/models/generic/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class ExcelReportsService {

  constructor(private http: HttpClient) { }

  getCustomExcelReport(reportId: number, argumentList: Dictionary<string>): Observable<HttpResponse<Blob>> {
    const url = `${environment.crmApiUrl}/contracts/Excel/CustomExcelReport`
    const params = { reportId, ...argumentList };

    return this.http.get(url, {
      params,
      responseType: 'blob',
      observe: 'response',
    }).pipe(catchError((error) => throwError(error)))
  }

  getReport(reportName: string): Observable<HttpResponse<Blob>> {
    const url = `${environment.crmApiUrl}/Reports/contracts?reportTypeEnum=${reportName}`

    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
    }).pipe(catchError((error) => throwError(error)))
  }

  getBuildingReport(buildingId: number): Observable<HttpResponse<Blob>> {
    const url = `${environment.crmApiUrl}/contracts/Excel/BuidlingReport`
    const params = { buildingId };

    return this.http.get(url, {
      params,
      responseType: 'blob',
      observe: 'response',
    }).pipe(catchError((error) => throwError(error)))
  }

  getExcelReportsData(): Observable<[]> {
    return this.http.post(`${environment.crmApiUrl}/Contracts/Excel/GetExcelReportsData`, null).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => { throw new Error(error) })
    );
  }

  getUniqueCustomExcelReports(): Observable<ApiResponse<any>> {
    return this.http.get(`${environment.crmApiUrl}/Contracts/Excel/GetUniqueCustomExcelReports`)
    .pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => { throw new Error(error) })
    );
  }

  updateCustomExcelReports (params: any): Observable<ApiResponse<any>> {
    return this.http.post(`${environment.crmApiUrl}/Contracts/Excel/UpdateCustomExcelReports`, params)
    .pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => { throw new Error(error) })
    );
  }
}
