import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { IProperty } from '../../models/building-models/Property';
import { AvHelperService } from '../../services/avHelper/av-helper.service';
import { TimeRemainingPipe } from '@avesdo-common/src/lib/pipes/time-remaining.pipe';


// DON'T REMOVE DYNAMIC COMMENT
// @dynamic
@Component({
  selector: 'lib-property-gallery-info',
  templateUrl: './property-gallery-info.component.html',
  styleUrls: ['./property-gallery-info.component.scss']
})
export class PropertyGalleryInfoComponent implements OnInit, OnChanges , OnDestroy {
  @Input() property: IProperty;
  propImages: string[] = [];
  parkingIncluded;
  storageIncluded;
  bikeIncluded;
  denIncluded;
  included = 'GENERIC.INCLUDED';
  notIncluded = 'GENERIC.NOT_INCLUDED';
  yes = 'GENERIC.YES';
  no = 'GENERIC.NO';
  timeLeftToReserve;
  timerLeftInterval;
  constructor(private avHelperSrvc: AvHelperService) { }

  ngOnInit() {
    this.timerLeftInterval = setInterval(() => { this.setTimer(); } , 60000  );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.property.currentValue) {
      return;
    }
    this.propImages = [];
    if (
      this.property.cfgBuildingMedias &&
      this.property.cfgBuildingMedias.length > 0
    ) {
      this.propImages = this.property.cfgBuildingMedias.map(
        pic => pic.mediaUrl
      );
    } else {
      //TODO: remove this if section once deployed
      if (this.property.floorPlanImage) {
        this.propImages.push(this.property.floorPlanImage);
      }

      if (this.property.building.buildingImage) {
        this.propImages.push(this.property.building.buildingImage);
      }
      this.parkingIncluded = (this.property.parkingIncluded === 0) ? this.notIncluded : this.included;
      this.bikeIncluded = (this.property.bikeIncluded === 0) ? this.notIncluded : this.included;
      this.storageIncluded = (this.property.storageIncluded === 0) ? this.notIncluded : this.included;
      this.denIncluded = (this.property.den === 0) ? this.no : this.yes;
      this.setTimer();

    }
  }

  setTimer() {
    const reservationTime = this.property.propertyReservation && this.property.propertyReservation.reserveExpireDate;
    if (reservationTime) {
      const timeRemainingPipe = new TimeRemainingPipe();
      const timeLeft = timeRemainingPipe.transform(reservationTime);
      if (timeLeft.indexOf('No time remaining') === -1 && timeLeft.indexOf('NoLimitReservation') === -1) {
        this.timeLeftToReserve = timeLeft;
      }
    }
  }

 ngOnDestroy() {
  clearInterval(this.timerLeftInterval);
}
}
