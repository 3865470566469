import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@ng-new/src/environments/environment';
import { CoreApiResponse } from '@avesdo-common/src/lib/models/generic/CoreApiResponse';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';
import { IBuilding } from '@avesdo-common/src/lib/models/building-models/Building';
import { YongleAppConstant } from '../models/YongleAppConstant';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { DemandColorThemes, DemandLevelSettings } from '@avesdo-common/src/lib/models/heatmap/DemandLevelSettings';
import { ApiResponse } from '@avesdo-common/src/lib/models/generic/ApiResponse';
import { DropDownStatus } from '../models/DropdownStatus';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _yongleAppConstant: YongleAppConstant;

  constructor(
    private http: HttpClient,
    ) { }

  get yongleAppConstant(): YongleAppConstant {
    return { ...this._yongleAppConstant };
  }

  private mapEnvironment(environment: string): string {
    const env = environment?.toLowerCase();
    switch (env) {
      case 'local':
        return 'dev';
      case 'production':
        return 'prod';
      default:
        return env;
    }
  }

  getUserSetting() {
    return this.http
      .get<any>(`${environment.crmApiUrl}/Contracts/config/GetUserSetting`)
      .pipe(
        handleCoreApiResponse,
        map((res) => res.data),
        catchError((error: any) => throwError(error))
      );
  }

  getDeveloperInfo() {
    return this.http
      .get<CoreApiResponse<{
        id: number,
        loginAction: string,
        name: string
      }>>(`${environment.crmApiUrl}/Contracts/config/GetDeveloperInfo`)
      .pipe(
        handleCoreApiResponse,
        map((res) => res.data),
        catchError((error: any) => throwError(error))
      );
  }

  getBuildings() {
    return this.http
      .get<CoreApiResponse<IBuilding[]>>(`${environment.crmApiUrl}/Contracts/config/GetBuildings`)
      .pipe(
        handleCoreApiResponse,
        map((res) => res.data),
        catchError((error: any) => throwError(error))
      );
  }

  getBuildingSettings() {
    return this.http
      .get<any>(`${environment.crmApiUrl}/Contracts/config/GetBuildingSettings`)
      .pipe(
        handleCoreApiResponse,
        map((res) => res.data),
        catchError((error: any) => throwError(error))
      );
  }

  getYongleAppConstant(): Observable<YongleAppConstant> {
    return this.http
      .get<any>(`${environment.crmApiUrl}/Contracts/config/GetYongleAppConstants`)
      .pipe(
        handleCoreApiResponse,
        map((res) => res.data),
        catchError((error: any) => throwError(error))
      );
  }

  setYongleAppConstant(yongleAppConstant: YongleAppConstant) {
    this._yongleAppConstant = {
      jsVersion: new Date().toString(),
      //initialRealtorEmail: 'jeff@soldbyservice.com',
      //initialClientEmail: 'john@soldbyservice.com',
      initialRealtorEmail: '',
      initialClientEmail: '',
      chartColorArray: ['#97BBCD', '#DCDCDC', '#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#8E0DDE', '#5DDE0D', '#FC8F00', '#006DFC', '#FF33E7', '#FFF785', '#858DFF', '#97BBCD', '#DCDCDC', '#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#8E0DDE', '#5DDE0D', '#FC8F00', '#006DFC', '#FF33E7', '#FFF785', '#858DFF'],
      ...yongleAppConstant,
      environment: this.mapEnvironment(yongleAppConstant.environment)
    }
  }

  getRawDemandLevelSettings() {
    return this.http
    .get<any>(`${environment.crmApiUrl}/sales/selection/demandSettings`)
    .pipe(
      handleCoreApiResponse,
      map((res) => res.data),
      catchError((error: any) => of(error))
    );
  }

  getDemandLevelSettingsDictionary(demandSettings: any, selectedTheme='green'): Dictionary<DemandLevelSettings> {
    const colorTheme = DemandColorThemes[selectedTheme];
    const settings = demandSettings.demandLevels.reduce((dict, currentVal) => {
      const newValue = currentVal;
      newValue.color = colorTheme.colors.filter(color=>color.level==currentVal.level)[0].color;
      newValue.textColor = colorTheme.colors.filter(color=>color.level==currentVal.level)[0].textColor;
      dict[currentVal.level] = currentVal;
      return dict
    }, {});

    return settings;

  }

  getPropertyDropdownStatus(includeSalesStatus?: any): Observable<DropDownStatus[]> {
    const url = `${environment.crmApiUrl}/Contracts/config/GetPropertyDropdownStatus`;

    return this.http.get<ApiResponse<DropDownStatus[]>>(url, {params : {includeSalesStatus}}).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }
  
  getDealDropdownStatus(): Observable<DropDownStatus[]> {
    const url = `${environment.crmApiUrl}/Contracts/config/GetDealDropdownStatus`;

    return this.http.get<ApiResponse<DropDownStatus[]>>(url).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  getPropertyCustomStatus(): Observable<any[]> {
    const url = `${environment.crmApiUrl}/Contracts/config/GetPropertyCustomStatus`;

    return this.http.get<ApiResponse<any[]>>(url).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  getPackageStatus(): Observable<DropDownStatus[]> {
    const url = `${environment.crmApiUrl}/Contracts/config/GetSelectorPackageStatus`;

    return this.http.get<ApiResponse<DropDownStatus[]>>(url).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  getProgressStatus(): Observable<DropDownStatus[]> {
    const url = `${environment.crmApiUrl}/Contracts/config/GetSelectorProgressStatus`;

    return this.http.get<ApiResponse<DropDownStatus[]>>(url).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }
}
