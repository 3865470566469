import {
  Directive,
  ViewContainerRef,
  OnInit,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AvTableColumnConfig } from './../components/av-table.component';
import { AvTextFilterComponent } from './../components/av-text-filter/av-text-filter.component';
import { AvMultiSelectFilterComponent } from './../components/av-multi-select-filter/av-multi-select-filter.component';
import { AvRangeFilterComponent } from './../components/av-range-filter/av-range-filter.component';
import { AvMultiSelectLargeComponent } from '../components/av-multi-select-large/av-multi-select-large.component';

@Directive({
  selector: '[libAvTableFilter]'
})
export class AvTableFilterDirective implements OnInit {
  @Input() columnConfig: AvTableColumnConfig;
  @Input() control: AbstractControl;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
    const {
      columnConfig,
      control,
      viewContainerRef,
      componentFactoryResolver
    } = this;

    if (!columnConfig.filterType)
      return;

    const componentMap = {
      text: AvTextFilterComponent,
      number: AvTextFilterComponent,
      multiSelect: AvMultiSelectFilterComponent,
      range: AvRangeFilterComponent,
      multiSelectLarge: AvMultiSelectLargeComponent
    };

    const componentFactory = componentFactoryResolver
      .resolveComponentFactory(componentMap[columnConfig.filterType]);

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<any>componentRef.instance).componentConfig = { control, columnConfig };
    (<any>componentRef.instance).filterType = columnConfig.filterType;
  }
}
