import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'av-button-group',
  templateUrl: './av-button-group.component.html',
  styleUrls: ['./av-button-group.component.scss'],
})
export class AvButtonGroupComponent   {
  @Output() selectedButton: EventEmitter<string> = new EventEmitter<string>();
  @Input() buttonList: AvButtonGroupConfigurations[] = [];

  onButtonSelect(selectedButton: string): void {
    this.selectedButton.emit(selectedButton);
  }

}

export interface AvButtonGroupConfigurations {
  IsChecked: boolean;
  Label: string;
  onChange?: any;
  IconClass: string;
  Value: any;
}
