export const LeadFormConstants = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  submit: 'Submit',
  brokerage: 'Brokerage',
  contactDetail: 'Contact Detail',
  addressDetails: 'Address Details',
  addressProperties: 'Address Properties',
  addressDetail: 'Address',
  validation: 'Validation',
  containerAddressProperties: 'ContainerAddressProperties',
  containerRealtor: 'ContainerRealtor',
  areYouRealtor: 'Are you a Realtor?',
  areYourWorkingWithRealtor: 'Are you working with a Realtor?',
  howDidYouHearAboutUs: 'How did you hear about us?',
  CASL: 'CASL',
  templateContainerTypeId: '9abb3c58-1f90-4b10-a1e5-e00fe24e52eb',
  templateContainerType: 'Layout',
  city: 'City',
  stateOrProvince: 'State/Province',
  postalOrZipCode: 'Postal/Zipcode',
  streetAddress: 'Street Address',
  country: 'Country'
};
