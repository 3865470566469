import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@avesdo-common/src/public-api';
import { SharedModule } from '@ng-new/src/app/shared/shared.module';
import { ManageOrganizationsComponent } from '../components/manage-organizations/manage-organizations.component';
import { ManageFeaturePermissionsComponent } from '../dialogs/manage-feature-permissions/manage-feature-permissions.component';
import { UserLookupComponent } from '../components/user-lookup/user-lookup.component';
import {
  AddMarketingOrganizationComponent
} from "@ng-new/src/app/feature/contracts/modules/super-admin/dialogs/add-marketing-org/add-marketing-organization.component";



@NgModule({
  declarations: [
    ManageOrganizationsComponent,
    ManageFeaturePermissionsComponent,
    AddMarketingOrganizationComponent,
    UserLookupComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    ManageOrganizationsComponent,
    ManageFeaturePermissionsComponent,
    AddMarketingOrganizationComponent,
    UserLookupComponent
  ]
})
export class SuperAdminModule { }
