import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  IDualColumnStructure,
  ITemplateControls,
} from '@avesdo-common/src/lib/feature/custom-forms/models/LeadFormTemplateResponse';
import { sortBy as lSortBy } from 'lodash';
import { Widget } from '@avesdo-common/src/lib/feature/custom-forms/models/widget';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep as lCloneDeep, find as lFind } from 'lodash';
import { LeadFormConstants } from '@avesdo-common/src/lib/feature/custom-forms/constants/lead-form-constants';
import { LeadFormStylingService } from '@avesdo-common/src/lib/feature/custom-forms/services/lead-form-styling.service';
import { ILeadFormCustomStyle } from '@avesdo-common/src/lib/feature/custom-forms/models/LeadFormCustomStyling';
import { FormChangeEvent } from '../../models/FormChangeEvent';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { CustomFormSubmission, BaseFormField } from '../../models/CustomFormSubmission';

@Component({
  selector: 'lib-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss'],
})
export class FormRendererComponent implements OnInit {
  @Input() templateControls: ITemplateControls;
  @Input() initialData: CustomFormSubmission;
  @Output() formChange = new EventEmitter<FormChangeEvent>();

  formData;
  submitFormData = [];
  templateId;
  formSubmitted = false;
  selectedTemplateForPreview: ITemplateControls;
  selectedStyle: ILeadFormCustomStyle;
  provinceOption;
  prepopulateData: Dictionary<any>

  constructor(
    private translateService: TranslateService,
    private leadFormStylingService: LeadFormStylingService,
  ) {
  }

  ngOnInit() {
    this.initData(this.initialData);

    this.selectedTemplateForPreview = this.templateControls;
    if (this.selectedTemplateForPreview.templateContainers.length === 1) {
      this.formData = this.mapResponseToFormRenderer(0);
      this.formData = { components: this.setConditionalFields(this.formData) };
    } else {
      const columnData = [];
      let submitButton;
      const columnStructure: IDualColumnStructure[] = [];
      this.selectedTemplateForPreview.templateContainers = lSortBy(
        this.selectedTemplateForPreview.templateContainers,
        'displayOrder'
      );
      for (let index = 0; index < this.selectedTemplateForPreview.templateContainers.length; index++) {
        columnData[index] = this.mapResponseToFormRenderer(index);
        columnData[index] = this.setConditionalFields(columnData[index]);

        if (!submitButton) {
          submitButton = columnData[index].find(data => {
            return data.type == 'button';
          });
        }

        columnData[index] = columnData[index].filter((data) => {
          return data.type !== 'button';
        });
        const columnControls: IDualColumnStructure = {
          components: columnData[index],
          width: 6,
        };
        columnStructure.push(columnControls);
      }

      this.formData = {
        components: [
          {
            columns: [...columnStructure],
            key: 'columns',
            type: 'columns',
          },
          submitButton
        ],
      };
    }
    this.selectedStyle = this.leadFormStylingService.setDefaultStyles(
      this.selectedTemplateForPreview, false
    );
    this.leadFormStylingService.setStylesViewToPreview(this.selectedStyle);
  }

  change(event) {
    event = lCloneDeep(event);

    if (event.changed && event.changed.component && (event.changed.component.name === LeadFormConstants.stateOrProvince)) {
      const optionSelected = lFind(event.changed.instance.defaultDownloadedResources, ['label', event.changed.value]) || lFind(event.changed.instance.defaultDownloadedResources, ['value', event.changed.value]);
      this.provinceOption = {
        key: event.changed.component.key,
        options: [{
          id: optionSelected.id,
          value: optionSelected.value,
          label: optionSelected.label
        }]
      };
    }

    if (event.changed && event.changed.component && (event.changed.component.name === LeadFormConstants.country)) {
      const optionSelected = lFind(event.changed.instance.defaultDownloadedResources, ['value', event.changed.value]);
      const stateProvinceComponents = Array.from(
        document.getElementsByClassName(
          'formio-component-select'
        ) as HTMLCollectionOf<HTMLElement>);
      if (optionSelected.label !== 'Canada' && optionSelected.label !== 'United States Of America') {
        stateProvinceComponents.forEach(component => {
          if (component.innerText.toLowerCase() === LeadFormConstants.stateOrProvince.toLowerCase()) {
            component.style.pointerEvents = 'none';
            component.style.opacity = '0.6';
          }
        });
      } else {
        stateProvinceComponents.forEach(component => {
          if (component.innerText.toLowerCase() === LeadFormConstants.stateOrProvince.toLowerCase()) {
            component.style.pointerEvents = 'auto';
            component.style.opacity = '1';
          }
        });
      }
    }

    if (event.changed) {
      const newData = {
        data: this.getSubmissionParams(event),
        isModified: event.isModified,
        isValid: event.isValid 
      }
      this.formChange.emit(newData);
    }

  };

  mapResponseToFormRenderer(index) {
    const countryKey = this.selectedTemplateForPreview.templateContainers[index]
      .templateContainerControlAssociations.filter(control => control.templateControl.name === LeadFormConstants.country);
    return lSortBy(
      this.selectedTemplateForPreview.templateContainers[index]
        .templateContainerControlAssociations,
      'displayOrder'
    ).map((controlData) => {
      this.submitFormData.push(controlData.templateControl);
      if (countryKey && countryKey.length > 0) {
        return new Widget(controlData.templateControl, controlData.displayOrder, countryKey[0].templateControl.key);
      } else {
        return new Widget(controlData.templateControl, controlData.displayOrder);
      }
    });
  }  

  private initData(initialData: CustomFormSubmission) {
    this.prepopulateData = this.initialData?.data?.reduce((dict, datum) => {
      dict.data[datum.key] = datum.value
      return dict;
    }, {
      data: {}
    });

    initialData?.optionValues?.forEach(({ key, options }) => {
      //Label is for province field
      this.prepopulateData.data[key] = options[0].label || options[0].value;
      if (options[0].label) {
        this.provinceOption = {
          key,
          options: [{
          id: options[0].value,
          value: options[0].value,
          label: options[0].label
        }]
        }
      }
    });
  }

  private setConditionalFields(formData) {
    let areYouRealtorKey = '';
    let areYouWorkingWithRealtorKey = '';
    let brokerageKey = '';

    formData.forEach(component => {
      if (component.name === LeadFormConstants.areYouRealtor) {
        areYouRealtorKey = component.key;
      }
      if (component.name === LeadFormConstants.areYourWorkingWithRealtor) {
        areYouWorkingWithRealtorKey = component.key;
      }
      if (component.name === LeadFormConstants.brokerage) {
        brokerageKey = component.key;
      }
      if (component.key === areYouWorkingWithRealtorKey) {
        component.conditional.when = areYouRealtorKey;
        component.conditional.eq = 'No';
      }
      if (component.key === brokerageKey) {
        component.conditional.when = areYouRealtorKey;
        component.conditional.eq = 'Yes';
      }
      if (component.name === LeadFormConstants.CASL) {
        component.name = '';
        component.validate.customMessage = this.translateService.instant('ERRORS.AGREE_TERMS_AND_CONDITION');
      }
      if (component.type === 'email') {
        component.validate.customMessage = this.translateService.instant('ERRORS.EMAIL_MUST_BE_VALID');
      }
    });
    return formData;
  }

  getSubmissionParams(event): CustomFormSubmission {
    const eventData = [];
    const optionData = [];
    // remove button from fromData..
    this.submitFormData = this.submitFormData.filter(
      (data) => data.controlType !== 'button'
    );
    this.submitFormData.forEach((formData) => {
      if (formData.controlType === 'select') {

        const optionSelected = lFind(formData.templateControlOptions, ['value', event.data[formData.key]]);
        if (optionSelected) {
          optionData.push({
            key: formData.key,
            options: [{
              id: optionSelected.id,
              value: optionSelected.value
            }]
          });
        } else if (formData.label === LeadFormConstants.stateOrProvince && this.provinceOption) {
          optionData.push(this.provinceOption);
        }
      } else {
        eventData.push({
          key: formData.key,
          value: event.data[formData.key],
        });
      }
    });

    return {
      data: eventData,
      optionValues: optionData
    }
  }
}
