import { Injectable, Injector, ApplicationRef, ComponentFactoryResolver } from '@angular/core';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { SelectionSetupComponent } from '@avesdo-common/src/lib/feature/selection/components/selection-setup/selection-setup.component';
import { ManageOrganizationsComponent } from '@ng-new/src/app/feature/contracts/modules/super-admin/components/manage-organizations/manage-organizations.component';
import { UserLookupComponent } from '../../feature/contracts/modules/super-admin/components/user-lookup/user-lookup.component';

@Injectable({
  providedIn: 'root'
})
export class AngularComponentsService {
  components: Dictionary<any> = {
    'SelectionSetupComponent': SelectionSetupComponent,
    'ManageOrganizationsComponent': ManageOrganizationsComponent,
    'UserLookupComponent': UserLookupComponent
  };

  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}

  renderComponent(divId: string, componentClsName: string) {
    const componentClass = this.components[componentClsName];

    let factory = this.resolver.resolveComponentFactory(componentClass);

    let newNode = document.createElement('div');
    document.getElementById(divId).appendChild(newNode);

    const ref = factory.create(this.injector, [], newNode);
    this.appRef.attachView(ref.hostView);
  }
}
