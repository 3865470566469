import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import flatpickr from 'flatpickr';
import { CustomDatePipe } from '@avesdo-common/src/lib/pipes/custom-date.pipe';
import { DateInputChangeEvent } from '../date-input/date-input.component';

@Component({
  selector: 'lib-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss']
})
export class DateTimeInputComponent implements OnInit, AfterViewInit {

  @Input() defaultDate: string;
  @Input() id = 'id' + Math.floor(Math.random()*10000) + 1;
  @Input() enableTime: boolean = true;
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() timezone: string;
  @Output() dateChanged = new EventEmitter<DateInputChangeEvent>();

  localTimezone: string;
  shortTimezone: string;
  placeholder: string;
  private timezoneDiff: number = 0;
  private lastDateEntered: Date;

  constructor() { }

  getLocalTimezone(date: Date) {
    return new Date(date).toLocaleTimeString('en-CA',{timeZoneName:'short'}).split(' ')[2];
  }

  getTimezoneObject(timezoneString: string): any {
    const jsonString = timezoneString.replace(/'/g, '"');
    const timezoneObj = JSON.parse(jsonString);
    timezoneObj.standard = timezoneObj.standard.toUpperCase();
    return timezoneObj;
  }

  getShortTimezone(timezoneString: string): string {
    return this.getTimezoneObject(timezoneString).standard;
  }

  ngOnInit() {
    this.shortTimezone = this.getShortTimezone(this.timezone);
    this.placeholder = `${this.label} (${this.shortTimezone})`;
    this.localTimezone = this.getLocalTimezone(this.getNormalizedDate(this.defaultDate));
  }

  private getNormalizedDate(date): Date {
    this.calculateTimezoneDiff(date);
    if (!date) {
      return null;
    }
    return new Date(new CustomDatePipe().transform(date, 'YYYY-MM-DD HH:mm', this.timezone, false));
  }

  private calculateTimezoneDiff(date: any): void {
    const refDate = date ? new Date(date) : new Date();
    const projectDateStr  = new CustomDatePipe().transform(refDate, 'YYYY-MM-DD HH:mm', this.timezone, false);
    const localDateStr    = new CustomDatePipe().transform(refDate, 'YYYY-MM-DD HH:mm', Intl.DateTimeFormat().resolvedOptions().timeZone, false)
    this.timezoneDiff     = (new Date(projectDateStr).getTime() - new Date(localDateStr).getTime())/1000/60;
  }

  ngAfterViewInit(): void {
    this.lastDateEntered = this.getNormalizedDate(this.defaultDate ?? undefined);
    const datePicker1 = flatpickr('lib-date-time-input#' + this.id, {
      minuteIncrement: 15,
      defaultDate: this.getNormalizedDate(this.defaultDate ?? undefined),
      enableTime: true,
      dateFormat: 'm-d-Y H:i',
      wrap: true,
      onChange: (selectedDates, rawDateStr, instance) => {
        if (this.disabled) {
          if (this.defaultDate) {
            instance.setDate(this.getNormalizedDate(this.defaultDate ?? undefined));
          } else {
            instance.clear();
          }
        } else {
          if (!rawDateStr) {
            instance.setDate(this.lastDateEntered);
            return;
          } else {
            this.lastDateEntered = selectedDates[0];
          }
          // recalc timezone diff in case of DST or ST
          this.calculateTimezoneDiff(selectedDates[0].toISOString());
          
          const newDate = new Date (selectedDates[0].getTime() - this.timezoneDiff*60000);
          this.dateChanged.emit({ data: rawDateStr,  newDate });
          // update label for DST or ST
          this.localTimezone = this.getLocalTimezone(selectedDates[0]);
        }
      }
  })
  }

}
