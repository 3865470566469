import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from '../../services/notifications/notification.service';

@Component({
  selector: 'lib-public-url-link',
  templateUrl: './public-url-link.component.html',
  styleUrls: ['./public-url-link.component.scss']
})
export class PublicUrlLinkComponent implements OnInit {

  @Input() publicUrl: string;
  @Input() justifyCenter = true;

  constructor(
    private notify: NotificationService,
  ) { }

  ngOnInit(): void {
  }

  copyNotify(): void {
    navigator.clipboard.writeText(this.publicUrl);
    this.notify.success('Copied to clipboard');
  }

}
