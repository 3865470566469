import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AvTableColumnConfig } from '../av-table.component';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';

@Component({
  selector: 'lib-av-multi-select-large',
  templateUrl: './av-multi-select-large.component.html',
  styleUrls: ['./av-multi-select-large.component.scss']
})
export class AvMultiSelectLargeComponent {
  @Input() componentConfig: {
    control: FormGroup;
    columnConfig: AvTableColumnConfig;
  }

  constructor(public avHelperService: AvHelperService) { }

  getSummary() {
    const { controls } = this.componentConfig.control;
    const selectedItems = Object.keys(controls).filter((key) => controls[key].value);

    return this.avHelperService.getStringArraySummary(selectedItems);
  }

  setCheckboxes(value: boolean) {
    for (const key in this.componentConfig.control.controls) {
      this.componentConfig.control.controls[key].setValue(value);
    }
  }
}
