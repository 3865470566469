import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@avesdo-common/src/lib/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { FloorRange } from '@avesdo-common/src/lib/feature/selection/models/FloorRange';
import { GridProperty, IGridProperty } from '@avesdo-common/src/lib/models/building-models/GridProperty';
import { PropertyDetail } from '@avesdo-common/src/lib/feature/selection/models/PropertyDetail';

@Injectable({
  providedIn: 'root'
})
export class CfgFloorRangeService {
  constructor(
    private http: HttpClient
  ) { }

  getFloorRanges(developerId): Observable<FloorRange[]> {
    const url = `${environment.crmApiUrl}/corempapi/cfgfloorrange/developer/${developerId}`;

    return this.http
      .get<any>(url)
      .pipe(
        map(fr => fr.data),
        catchError((error: any) => throwError(error))
      );
  }
  isSetupCompleted(developerId): Observable<boolean> {
    const url = `${environment.crmApiUrl}/corempapi/cfgfloorrange/IsSetupCompleted`;

    return this.http
      .post<any>(url, {
        developerId
      })
      .pipe(
        catchError((error: any) => throwError(error))
      );
  }
  
  getFloorRangeByProperty(floorRanges: FloorRange[], property:  IGridProperty | GridProperty | PropertyDetail): FloorRange {
    return floorRanges.find(fr => fr.floorSections.includes(property?.floorSection));
  }
}
