import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lib-av-table-chip',
  templateUrl: './av-table-chip.component.html',
  styleUrls: ['./av-table-chip.component.scss']
})
export class AvTableChipComponent {
  @Input() componentConfig: {
    label: string;
    control: FormControl;
    emptyValue: any;
    isCurrency?: boolean;
  };

  constructor() { }
}
