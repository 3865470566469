import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionFloorPlates, SelectionFloorPlate } from '@avesdo-common/src/lib/feature/selection/models/SelectionFloorPlates';
import { SelectionModel } from '@angular/cdk/collections';
import { PropertyDetail } from '@avesdo-common/src/lib/feature/selection/models/PropertyDetail';

// @dynamic
@Component({
  selector: 'lib-selection-preview-dialog',
  templateUrl: './selection-preview-dialog.component.html',
  styleUrls: ['./selection-preview-dialog.component.css']
})
export class SelectionPreviewDialogComponent {
  selectionModel = new SelectionModel<PropertyDetail>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    data: SelectionFloorPlates,
    selectedFloorPlate: SelectionFloorPlate;
  }) { }
}
