import { Routes } from '@angular/router';
import { Component } from '@angular/core';

interface LegacyRoute {
  path: string;
  route: any;
}

//see angularJS routes in projects\ng-new\src\app\feature\contracts\angularJS\Scripts\ng\routes.js
declare const angularJSRoutes: LegacyRoute[];

@Component({
  selector: 'app-empty-component',
  template: '',
})
export class EmptyComponent {

  constructor() { }
}

export const legacyRoutes: Routes = angularJSRoutes.reduce((result, route) => {
  //format legacy path for angular 2+ router
  const path = route.path.substring(1).replace(/\/$/, '');

  return result.concat({
    path,
    component: EmptyComponent
  });

}, [{ path: '', pathMatch: 'full', redirectTo: 'Start', }] as any);
