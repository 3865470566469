import {
	Injectable,
	OnInit,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, ValidationErrors, Validator } from '@angular/forms';
import { HasSubscriptionMixin } from '@avesdo-common/src/lib/mixins/has-subscription.mixin';
import { BaseInjectorConstructor } from 'ng-mix';

export const AvFormControlMixin = <Tbase extends BaseInjectorConstructor>(superClass: Tbase) => {

	@Injectable()
	abstract class AvFormControl extends HasSubscriptionMixin(superClass) implements OnInit, ControlValueAccessor, Validator {
		@Input() groupLabel?: string = '';
		@Input() placeholder?: string = '';
		@Input() customControlOptions?: Array<any> = [];
		@Input() selectMultiple?: boolean = false;
		@Input() initialValue?: any;
		@Input() isRequired?: boolean = true;
		@Output() change: EventEmitter<any> = new EventEmitter();

		@Input() customFormControl = new FormControl();

		selectedOption: any = null;
		onChange = (option) => { };
		onTouched = () => { };
		touched = false;
		disabled = false;
		
		middlewareFunction = (value: any) => value;

		validate(control: AbstractControl): ValidationErrors {
			const selectedValue = control.value;
			if (!selectedValue) {
				return {
					required: true
				};
			}
		}

		writeValue(obj: any): void {
			this.selectedOption = obj;
		}
		registerOnChange(fn: any): void {
			this.onChange = fn;
		}
		registerOnTouched(fn: any): void {
			this.onTouched = fn;
		}
		setDisabledState?(isDisabled: boolean): void {
			this.disabled = isDisabled;
		}
		markAsTouched() {
			if (!this.touched) {
				this.onTouched();
				this.touched = true;
			}
		}

		private processNewValue(value: any): any {
			return this.middlewareFunction(value);
		}

		ngOnInit(): void {

			super.ngOnInit();

			if (this.initialValue || this.initialValue === 0) this.selectedOption = this.initialValue;
			this.customFormControl.setValue(this.selectedOption);

			this.subscription.add(
				this.customFormControl.valueChanges
					.subscribe(value => {
						const newValue = this.processNewValue(value);
						this.onChange(newValue);
						this.change.emit(newValue);
					})
			)

		}
	}

	return AvFormControl;
}