import { environment } from '@avesdo-common/src/lib/environments/environment';

export class SsoClientSetting {
  authority = environment.authority;
  client_id = 'MarketPlaceClient';
  redirect_uri = `${environment.coreBaseUrl}` + '/callback';
  post_logout_redirect_uri = environment.coreBaseUrl;
  response_type = 'id_token token';
  scope = 'openid profile MarketPlaceResource.Read roles';
  filterProtocolClaims = true;
  loadUserInfo = true;
  automaticSilentRenew = true;
  silent_redirect_uri = `${environment.coreBaseUrl}` + '/silentRenew';

  constructor(currentUrl: string) {}
}
