import { 
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { PropertyStatus } from '@avesdo-common/src/lib/enums/PropertyStatus';
import { FloorPlan, getPropertiesByFloorPlan } from '@avesdo-common/src/lib/feature/selection/models/FloorPlan';
import { FloorPlate } from '@avesdo-common/src/lib/feature/selection/models/FloorPlate';
import { PropertyDetail } from '@avesdo-common/src/lib/feature/selection/models/PropertyDetail';
import { SelectionChoice } from '@avesdo-common/src/lib/feature/selection/models/SelectionChoice';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { NotificationService } from '@avesdo-common/src/lib/services/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CfgFloorRangeService } from '@avesdo-common/src/lib/feature/selection/services/cfg-floor-range.service';
import { FloorRange } from '@avesdo-common/src/lib/feature/selection/models/FloorRange';

@Component({
  selector: 'app-unit-picker',
  templateUrl: './unit-picker.component.html',
  styleUrls: ['./unit-picker.component.scss']
})
export class UnitPickerComponent implements OnInit {

  @Input() properties: PropertyDetail[];
  @Input() floorPlates: FloorPlate[];
  @Input() floorRanges: FloorRange[];
  @Input() maxNumberOfChoices?: number = 0;
  @Input() numberOfChoicesAdded?: number = 0;
  @Input() viewOnly?: boolean = false;
  @Input() editChoice?: SelectionChoice;
  @Input() isPropertySelected;
  @Input() isFloorPlanBased: boolean;

  @Output() close = new EventEmitter();
  @Output() addChoice = new EventEmitter<SelectionChoice>();
  @Output() saveChoice = new EventEmitter<SelectionChoice>();

  floorPlans: FloorPlan[];
  displayFloorPlans: FloorPlan[];
  selectedChoices: SelectionChoice[] = [];
  numberOfChoices: number;
  showViewDetails: boolean = false;
  floorplanDetail: FloorPlan;
  floorPlanProperties: Dictionary<PropertyDetail[]> = {};
  province: string;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private floorRangeService: CfgFloorRangeService
  ) { }


  isFormValid() {
    return this.selectedChoices.length > 0;
  }

  finishSelection() {
    this.selectedChoices.forEach(choice => {
      this.addChoice.emit(choice);
    });
    this.close.emit();
  }

  addSelectedChoice(choice: SelectionChoice) {
    if (this.isPropertySelected(choice.propertyId) || this.selectedChoices.find(selected => selected.propertyId == choice.propertyId)) {
      this.notificationService.error(this.translateService.instant('SELECTIONS.CHOICE_ALREADY_SELECTED'));
      return;
    }
    this.selectedChoices.push(choice);
    this.numberOfChoices++;
    this.showViewDetails = false;
    if (this.numberOfChoices >= this.maxNumberOfChoices) {
      this.finishSelection();
    }
  }

  openDetails(floorplan: FloorPlan) {
    this.showViewDetails = true;
    this.floorplanDetail = floorplan;
  }

  filterfloorPlans(floorPlans: FloorPlan[]) {
    this.displayFloorPlans = floorPlans;
  }

  ngOnInit(): void {
    this.numberOfChoices = this.numberOfChoicesAdded;
    this.floorPlans = getPropertiesByFloorPlan(this.properties);
    this.floorPlans = this.floorPlans.filter(floorPlan => {
      this.floorPlanProperties[floorPlan.id] = this.properties.filter(p => floorPlan.propertyIds.includes(p.id) && p.availabilty === PropertyStatus.Available);
      return this.floorPlanProperties[floorPlan.id].length > 0;
    });
    this.displayFloorPlans = this.floorPlans;

    // All properties should have same province
    this.province = this.properties.find(property => property.province)?.province;
  }

}
