import { CustomDatePipe } from '@avesdo-common/src/public-api';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-date-display',
  templateUrl: './date-display.component.html',
  styleUrls: ['./date-display.component.scss']
})
export class DateDisplayComponent implements OnInit {

  @Input() date: string;
  @Input() format?: string = 'MMM DD, YYYY h:mm A';
  @Input() timezone: string;

  displayDate: string;

  constructor() { }

  ngOnInit(): void {
    this.displayDate = this.transformDate(this.date);
  }

  transformDate(dateStr: string): string {
    return dateStr ? new CustomDatePipe().transform(dateStr, this.format, this.timezone, true) : '';
  }

}
