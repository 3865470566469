import { UtilityServices } from "@avesdo-common/src/lib/services/utility.service";

export class Deposit {
  id: number;
  isReceived: boolean;
  depositType: string;
  depositLabel: string;
  depositOrder: number;
  dueDate: string | Date;
  amount: number;
  percentage: any;
  packageId: number;
  projectId: number;
  isTentative: boolean;

  constructor(data) {
    const util = new UtilityServices();
    this.id = data.id;
    this.isReceived = data.isReceived;
    this.depositType = data.depositType;
    this.depositLabel = data.depositLabel;
    this.depositOrder = data.depositOrder;
    this.dueDate = util.fromMVCDate(data.dueDate, true);
    this.amount = data.amount;
    this.percentage = data.percentage;
    this.packageId = data.packageId;
    this.projectId = data.projectId;
    this.isTentative = data.isTentative;
  }

  // TODO Add functions from js model
}

export interface DepositReport {
    packageId: number;
    unitNumber: string;
    depositNumber: string;
    dueDate: string | Date;
    amount: number;
    amountReceived: number;
    status: number;
    balance: number;
    enabledEftPayments: boolean;
}