import {Injectable} from '@angular/core';
import {environment} from '@avesdo-common/src/lib/environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {XYCoordinates} from '@avesdo-common/src/lib/models/generic/XYCoordinates';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';
import {PropertyDetail} from '@avesdo-common/src/lib/feature/selection/models/PropertyDetail';
import { SelectionSubmissionPayload } from '../models/SelectionSubmissionPayload';
import { ApiResponse } from '@avesdo-common/src/lib/models/generic/ApiResponse';
import { SelectionSubmissionSource } from '../enums/SelectionSubmissionSource';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {

  constructor(private http: HttpClient,
              private avHelperService: AvHelperService) {}

  getBuildings() {
    const params = {};
    return this.http
      .get<any>(`${environment.crmApiUrl}/Contracts/config/GetBuildings`, params)
      .pipe(
        catchError((error: any) => throwError(error)),
        map((response) => this.avHelperService.toCamel(response.Data))
      );
  }

  getFloorPlates() {
    const params = {};
    return this.http
      .get<any>(`${environment.crmApiUrl}/Contracts/config/GetFloorplates`, params)
      .pipe(
        catchError((error: any) => throwError(error)),
        map((response) => this.avHelperService.toCamel(response.Data))
      );
  }

  getPropertyDetails(): Observable<PropertyDetail[]> {
    const params = {};
    return this.http
      .post<any>(`${environment.crmApiUrl}/Contracts/Property/GetPropertyDetails`, params)
      .pipe(
        catchError((error: any) => throwError(error)),
        map((response) => this.avHelperService.toCamel(response.Data))
      );
  }

  updatePropertiesByMode(props: { id: number, checkboxPosition: XYCoordinates }[], mode) {
    const params = {
      props,
      mode,
    };

    return this.http
      .post<any>(`${environment.crmApiUrl}/Contracts/Property/UpdatePropertiesByMode`, params)
      .pipe(
        catchError((error: any) => throwError(error)),
      );
  }

  saveForm(params: SelectionSubmissionPayload) : Observable<any> {
    let url = `${environment.crmApiUrl}/SalesAggregator/submission`;
    if(params.submissionSource == SelectionSubmissionSource.Purchaser && !params.purchaserId)
      url += "/public";

    return this.http.post<ApiResponse<any>>(url, params).pipe(
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }
}
