import { Injectable, ElementRef } from '@angular/core';
import { IClassificationType } from '../../models/classifications/ClassificationType';
import { IClassificationItem } from '../../models/classifications/ClassificationItem';
import { AbstractControl } from '@angular/forms';
import { Dictionary } from '../../models/generic/Dictionary';
import { range } from 'lodash';
import { HighlightSearch } from '../../pipes/higlight.pipe';
import { LegacyImagePipe, LegacyImageFolderType } from '../../pipes/legacy-image.pipe';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AvHelperService {
  constructor() {}

  createIdDict(items) {
    const itemsDict = {};

    for (let item of items) {
      itemsDict[item.id] = item;
    }

    return itemsDict;
  }

  getClassificationItemByValueAndTitle(
    value: string,
    title: string,
    itemsDict: { [key: string]: IClassificationItem },
    typesDict: { [key: string]: IClassificationType }
  ): IClassificationItem {
    let result: IClassificationItem = {
      id: null,
      classificationId: null,
      label: null,
      parentItemId: null,
      value: null,
    };

    for (let prop in itemsDict) {
      const item = itemsDict[prop];

      if (
        item &&
        item.value === value &&
        typesDict[item.classificationId].title === title
      ) {
        result = item;
      }
    }

    return result;
  }

  getValueOrUndefined(control: AbstractControl) {
    return control.value !== '' ? control.value : undefined; // backend is complaining if send null
  }

  objectIsEmpty(obj: Object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  dictionaryHasTrue(obj: Dictionary<boolean>) {
    return Object.keys(obj).some((key) => obj[key]);
  }

  getBackgroundImgUrl(mediaUrl: string, isThumbnail?: boolean) {
    if (!mediaUrl) return 'none';

    let result = `url(${mediaUrl})`;

    if (isThumbnail) {
      const index = result.indexOf('.jpg');
      result = `${result.slice(0, index)}_thumbnail${result.slice(index)}`;
    }

    return result;
  }

 getCssXYTranslate(item: HTMLElement): number | number[] | undefined {
    const transArr = [];
    if (!window.getComputedStyle) {
      return;
    }
    const style = window.getComputedStyle(item);
    const transform = style.transform || style.webkitTransform;
    let mat = transform.match(/^matrix3d\((.+)\)$/);
    if (mat) {
      return parseFloat(mat[1].split(', ')[13]);
    }
    mat = transform.match(/^matrix\((.+)\)$/);
    mat ? transArr.push(parseInt(mat[1].split(', ')[4], 10)) : transArr.push(0);
    mat ? transArr.push(parseInt(mat[1].split(', ')[5], 10)) : transArr.push(0);

    return transArr;
  }

  toCamel(o) {
    if (o === null) return o;
    //Question: Can server on .NET MVC have option to return camel case?
    var newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(obj => {
          if (typeof obj === 'object') {
            value = this.toCamel(obj);
          }
          return value;
      });
    } else {
      newO = {};
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
          value = o[origKey];
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = this.toCamel(value);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  getStringArraySummary(stringArray: string[], defaultString = '') {
    stringArray = stringArray.filter((value) => value.trim());

    const { length } = stringArray;
    if (!length) {
      return defaultString;
    } else if (length < 4) {
      return stringArray.join(', ')
    } else  {
      return stringArray.slice(0,3).join(', ') + ` (+${length - 3} other${ length === 4 ? '': 's'})`;
    }
  }

  getLegacyImageUrl(url: string, folderType: LegacyImageFolderType) {
    return new LegacyImagePipe().transform(url, folderType);
  }

  getHttpHeaderFileName(headerResponse: HttpHeaders) {
    const contentDisposition = headerResponse.get('Content-Disposition');
    const filename = contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim()
      .replace(/\"/g, '');

    return filename;
  }

  static generatePriceRange() {
    return range(100000, 1000001, 50000)
    .concat(range(1250000, 10000001, 250000))
    .concat(range(10500000, 20000001, 500000))
  }

  static makeBold(input: string, wordsToBold: string): any {
    const highlightPipe = new HighlightSearch();
    return  highlightPipe.transform(input, wordsToBold);
  }
}
