import { Component } from '@angular/core';

@Component({
  selector: 'app-reload-page-modal',
  templateUrl: './reload-page-modal.component.html',
  styleUrls: ['./reload-page-modal.component.scss']
})
export class ReloadPageModalComponent {
  reloadPage() {
    window.location.reload();
  }

  constructor() { }
}
