import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';
import { ConfigService } from '../shared/services/config.service';
import { tap, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@avesdo-common/src/lib/services/notifications/notification.service';
import { LocalStorageNames } from '../shared/enums/LocalStorageNames';

@Injectable({
  providedIn: 'root'
})
export class RequiredSettingsResolver implements Resolve<boolean> {

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private notificationSrvc: NotificationService,
    private translateSrvc: TranslateService,
  ) {}

  resolve(): Promise<any> {
    return forkJoin([
      this.configService.getUserSetting(),
      this.configService.getDeveloperInfo(),
      this.configService.getBuildings(),
      this.configService.getYongleAppConstant()
    ])
    .pipe(
      catchError(() => {
        const errorMsg = this.translateSrvc.instant('ERRORS.REQUIRED_SETTINGS');
        this.notificationSrvc.error(errorMsg);

        //Logs user out of building if required settings can not be retrieved
        localStorage.removeItem(LocalStorageNames.CoreDeveloperId);

        return of(null);
      }),
      tap((data) => {
        if (data) {
          const [userSettings, developerInfo, buildings, yongleAppConstant] = data;
          this.authService.setAuthUserSettings(
            userSettings,
            developerInfo,
            buildings
          );
          this.configService.setYongleAppConstant(yongleAppConstant)
        }
      }),
    ).toPromise();
  }
}
