import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AngularAngularjsNavigateService {

  constructor(private route: Router) { }

  public navigateToRoute(route: string, isRedirect: boolean): void {
    if (isRedirect) this.route.navigate([route ?? '/']);
  }
}
