import { createAction, props } from '@ngrx/store';
import { IFeaturePermissionRespsonse } from '@crm/src/app/permissions/model/permissions';

export const FETCH_FEATURE_PERMISSIONS_IF_NEEDED = '[FeaturePermissions] Fetch Feature Permissions If Needed';
export const FETCH_FEATURE_PERMISSIONS = '[FeaturePermissions] Fetch Feature Permissions Detail';
export const FETCH_FEATURE_PERMISSIONS_SUCCESS = '[FeaturePermissions] Fetch Feature Permissions Detail success';
export const FETCH_FEATURE_PERMISSIONS_FAIL = '[FeaturePermissions] Fetch Feature Permissions Detail fail';

export const fetchFeaturePermissions = createAction(FETCH_FEATURE_PERMISSIONS);
export const fetchFeaturePermissionsIfNeeded = createAction(FETCH_FEATURE_PERMISSIONS_IF_NEEDED);

export const fetchFeaturePermissionsSuccess = createAction(
  FETCH_FEATURE_PERMISSIONS_SUCCESS,
  props<{ featurePermissions: IFeaturePermissionRespsonse[] }>()
);

export const fetchFeaturePermissionsFail = createAction(FETCH_FEATURE_PERMISSIONS_FAIL);
