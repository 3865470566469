import { Component, Input } from '@angular/core';

//This component is only used in selection setup for the drag & drop
@Component({
  selector: 'lib-circle-checkbox',
  templateUrl: './circle-checkbox.component.html',
  styleUrls: ['./circle-checkbox.component.scss']
})
export class CircleCheckboxComponent {
  @Input() disabled = false;
  @Input() selected = false;
  @Input() isDraggable = false;

  constructor() { }
}
