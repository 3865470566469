import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertySigners',
})
@Injectable({
  providedIn: 'root',
})
export class PropertySignersPipe implements PipeTransform {


  transform(signersList: any[], options?: {isPendingSigners: boolean}) {
    return this.constructPrpertySignersList(signersList, options?.isPendingSigners);
  }

  constructPrpertySignersList(signersList: any[], isPendingSigners: boolean): string {
    let namesList: string[] = [];
    signersList.forEach((signer: any) => {
      if (isPendingSigners) {
        if (signer.signerRole.toLowerCase() == 'client')
        {
          namesList.push(this.getFullName(signer.userDetail));
        }
        return;
      }
      if (signer.userLabel.substring(0, 1).toLowerCase() == 'c')
      {
        namesList.push(this.getFullName(signer.userDetail));
      }
    });
    return namesList.join(', ');
  }

  private getFullName(user) {
    return `${(user.firstName) ? user.firstName: ''} ${(user.middleName) ? user.middleName: ''} ${(user.lastName) ? user.lastName: ''}`;
  }
}
