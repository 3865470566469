import { Injectable } from '@angular/core';
import { environment } from '@ng-new/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';
import { Observable, throwError } from 'rxjs';
import { IFeaturePermissionRespsonse } from '@crm/src/app/permissions/model/permissions';
import { CoreApiResponse } from '@avesdo-common/src/lib/models/generic/CoreApiResponse';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(private http: HttpClient) { }

  getFeaturePermissions(): Observable<IFeaturePermissionRespsonse[]> {
    const url = `${environment.crmApiUrl}/Contracts/Feature/GetUserFeatures`;

    return this.http.get<any>(url).pipe(
      handleCoreApiResponse,
      map((response: CoreApiResponse<IFeaturePermissionRespsonse[]>) => response.data),
      catchError(err => throwError(err)));
  }
}
