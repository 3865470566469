import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ElementModifierTypes } from '../enums/ElementModifierTypes.enum';
import { UserClaims } from '../enums/UserClaims';
import { UserRole } from '../enums/UserRole';
import { IAuthState } from '../models/states/auth/auth-state.interface';

@Directive({
  selector: '[hasPermission]',
})
export class AvCheckPermissionDirective implements OnDestroy, AfterViewInit {
  @Input() requiredClaims: UserClaims[] = [];
  @Input() requiredRoles: UserRole[] = [];
  @Input() elementModifierType: ElementModifierTypes;

  private authState: IAuthState;
  private hasAllClaims: boolean = true;
  private hasAllRoles: boolean = true;

  private subscription: Subscription;

  constructor(private el: ElementRef, store: Store) {
    this.subscription = store.subscribe(
      (state: { auth: IAuthState }) => (this.authState = state.auth)
    );
  }

  ngAfterViewInit(): void {
    this.hasAllRequiredClaimsRoles();
    if (this.hasAllClaims && this.hasAllRoles) return;
    if (this.elementModifierType === ElementModifierTypes.Hide)
      this.hideElement();
  }

  private hasAllRequiredClaimsRoles(): void {
    this.hasAllClaims = this.requiredClaims.every((claim: UserClaims) => {
      return this.authState.claims[claim];
    });
    this.hasAllRoles = this.requiredRoles.every((role: UserRole) => {
      return this.authState.user.role.includes(role);
    });
  }

  private disableElement(): void {
    this.el.nativeElement.disabled = true;
    this.el.nativeElement.classList.add('bg-light');
    this.el.nativeElement.classList.add('text-secondary');
  }

  private hideElement(): void {
    this.el.nativeElement.hidden = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
