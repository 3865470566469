import { UtilityServices } from "../../services/utility.service";

export class UserDetailImage {
  signImg: string;
  initialImg: string;

  constructor(data) {
    this.signImg = data.signImg ?? '';
    this.initialImg = data.initialImg ?? '';
  }
}

export class UserDetailsReporting {
  userDetailId: number;
  birthday: string | Date;
  residencyStatus: string;
  citizenship: string;
  developerIds: number[];

  business: string;
  idNumber: number;
  idType: string;
  idIssuedBy: string;
  idExpire: string | Date;
  industry: string;

  idNumber2: number;
  idType2: string;
  idIssuedBy2: string;
  idExpire2: string | Date;

  employment: string;
  householdIncome: string;
  education: string;
  maritalStatus: string;
  primaryLanguage: string;
  secondaryLanguage: string;
  haveChildren: boolean;

  employer: string;
  income: string;
  creditScore: string;

  child1BirthYear: number;
  child2BirthYear: number;
  child3BirthYear: number;
  child4BirthYear: number;
  child5BirthYear: number;
  child6BirthYear: number;
  child7BirthYear: number;

  havePet: boolean;
  petDog: boolean;
  petCat: boolean;
  petFish: boolean;
  petBird: boolean;
  petOther: boolean;

  lat: number;
  lng: number;
  invalidAddress: boolean;

  constructor(data: UserDetailsReporting) {
    const utilityServices = new UtilityServices();
    this.userDetailId = data.userDetailId;
    this.birthday = utilityServices.fromMVCDate(data.birthday, true);
    this.residencyStatus = data.residencyStatus;
    this.citizenship = data.citizenship;
    this.developerIds = data.developerIds;

    this.business = data.business;
    this.idNumber = data.idNumber;
    this.idType = data.idType;
    this.idIssuedBy = data.idIssuedBy;
    this.idExpire = utilityServices.fromMVCDate(data.idExpire, true);
    this.industry = data.industry;

    this.idNumber2 = data.idNumber2;
    this.idType2 = data.idType2;
    this.idIssuedBy2 = data.idIssuedBy2;
    this.idExpire2 = utilityServices.fromMVCDate(data.idExpire2, true);

    this.employment = data.employment;
    this.householdIncome = data.householdIncome;
    this.education = data.education;
    this.maritalStatus = data.maritalStatus;
    this.primaryLanguage = data.primaryLanguage;
    this.secondaryLanguage = data.secondaryLanguage;
    this.haveChildren = data.haveChildren;


    this.employer = data.employer;
    this.income = data.income;
    this.creditScore = data.creditScore;


    this.child1BirthYear = data.child1BirthYear;
    this.child2BirthYear = data.child2BirthYear;
    this.child3BirthYear = data.child3BirthYear;
    this.child4BirthYear = data.child4BirthYear;
    this.child5BirthYear = data.child5BirthYear;
    this.child6BirthYear = data.child6BirthYear;
    this.child7BirthYear = data.child7BirthYear;

    this.havePet = data.havePet;
    this.petDog = data.petDog;
    this.petCat = data.petCat;
    this.petFish = data.petFish;
    this.petBird = data.petBird;
    this.petOther = data.petOther;

    this.lat = data.lat;
    this.lng = data.lng;
    this.invalidAddress = data.invalidAddress;
  }
}

// TODO: Fix commented code
export class UserDetails {
  id: number;
  created: string | Date;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  unitNumber: string;
  street: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  phone: string;
  phoneExt: string;
  phoneFormat: string;
  fax: string;
  faxFormat: string;
  businessPhone: string;
  businessPhoneExt: string;
  businessPhoneFormat: string;
  sendEmail: boolean;
  isBlackList: boolean;
  saveSign: boolean;
  crmPersonId: number
  organizationId: number;

  brokerageGstNumber: string;
  brokerage: string;
  livingHere: boolean;
  lawFirm: string;
  address: string;

  isCorp: boolean;
  corpName: string;
  corpAddress: string;
  corpNumber: string;
  crmSource: string;
  crmSourceId: number;

  email: string;
  userRole: string;
  isActive: string;
  buildingId: number;
  developerNames: string;
  developerIds: number[];
  developerId: number;
  SSOuserId: number;

  canSell: boolean;
  canChangePrice: boolean;
  canUnreserveAny: boolean;
  canManageStaff: boolean;
  canSearchByUnitNumber: boolean;
  canCollapsePackage: boolean;
  canRejectAnyDoc: boolean;
  canReassign: boolean;
  canSignAsDeveloper: boolean;
  canVerifyDeal: boolean;
  canChangeDealStatus: boolean;
  canDoProjectSetting: boolean;
  canDoAdmin: boolean;
  canDoPrivilegeAdmin: boolean;
  canDownloadExecutiveReport: boolean;
  isCRMEnabled: boolean;
  isMPEnabled: boolean;
  updateClosingDate: boolean;
  updateEstimatedClosingDate: boolean;

  extra: any;
  // extraJson = user.Extra == null || user.Extra.trim() === '' ? {} : JSON.parse(user.Extra);
  // extraJson.StaffClaims = ExtraJson.StaffClaims == null ? {} : ExtraJson.StaffClaims;
  // extraJson.StaffClaims.DeveloperSignNumber = ExtraJson.StaffClaims.DeveloperSignNumber == null ? null : ExtraJson.StaffClaims.DeveloperSignNumber;

  userDetailReporting: UserDetailsReporting;
  userDetailImage: UserDetailImage;

  isPrec: boolean;
  cellPhone: string;
  sin: string;
  nickname: boolean;

  frontPictureIdUrl: boolean;

  constructor(user, setDefaultProvince?: string) {
    const utilityServices = new UtilityServices();
    this.id = user.id;
    this.created = utilityServices.fromMVCDate(user.created);
    this.title = user.title ?? '';
    this.firstName = user.firstName ?? '';
    this.middleName = user.middleName ?? '';
    this.lastName = user.lastName ?? '';
    this.unitNumber = user.unitNumber ?? '';
    this.street = user.street ?? '';
    this.city = user.city ?? '';
    this.province = setDefaultProvince ?? user.province;
    this.postalCode = user.postalCode ?? '';
    this.country = user.country;
    this.phone = user.phone == undefined ? '' : user.phone;
    this.phoneExt = user.phoneExt == undefined ? '' : user.phoneExt;
    this.phoneFormat = this.phone === '' ? '' : utilityServices.formatPhoneNumber(user.phone) + (this.phoneExt === '' ? '' : ' ext. ' + this.phoneExt);
    this.fax = user.fax ?? '';
    this.faxFormat = this.fax.length ? utilityServices.formatPhoneNumber(user.fax) : '';
    this.businessPhone = user.businessPhone ?? '';
    this.businessPhoneExt = user.businessPhoneExt ?? '';
    this.businessPhoneFormat = this.businessPhone.length ? utilityServices.formatPhoneNumber(user.businessPhone) : '' + (this.businessPhoneExt === '' ? '' : ' ext. ' + this.businessPhoneExt);
    this.sendEmail = user.sendEmail ?? true;
    this.isBlackList = user.isBlackList ?? false;
    this.saveSign = user.saveSign ?? true;
    this.crmPersonId = user.crmPersonId;
    this.organizationId = user.organizationId;

    this.brokerageGstNumber = user.brokerageGstNumber ?? '';
    this.brokerage = user.brokerage ?? '';
    this.livingHere = user.livingHere ?? '';
    this.lawFirm = user.lawFirm ?? '';
    this.address = user.address;

    this.isCorp = user.isCorp;
    this.corpName = user.corpName;
    this.corpAddress = user.corpAddress;
    this.corpNumber = user.corpNumber;
    this.crmSource = user.crmSource;
    this.crmSourceId = user.crmSourceId;

    this.email = user.email;
    this.userRole = user.userRole;
    this.isActive = user.isActive;
    this.buildingId = user.buildingId;
    this.developerNames = user.developerNames;
    this.developerIds = user.developerIds;
    this.developerId = user.developerId;
    this.SSOuserId = user.SSOuserId;

    this.canSell = user.canSell;
    this.canChangePrice = user.canChangePrice;
    this.canUnreserveAny = user.canUnreserveAny;
    this.canManageStaff = user.canManageStaff;
    this.canSearchByUnitNumber = user.canSearchByUnitNumber;
    this.canCollapsePackage = user.canCollapsePackage;
    this.canRejectAnyDoc = user.canRejectAnyDoc;
    this.canReassign = user.canReassign;
    this.canSignAsDeveloper = user.canSignAsDeveloper;
    this.canVerifyDeal = user.canVerifyDeal;
    this.canChangeDealStatus = user.canChangeDealStatus;
    this.canDoProjectSetting = user.canDoProjectSetting;
    this.canDoAdmin = user.canDoAdmin;
    this.canDoPrivilegeAdmin = user.canDoPrivilegeAdmin;
    this.canDownloadExecutiveReport = user.canDownloadExecutiveReport;
    this.isCRMEnabled = user.isCRMEnabled;
    this.isMPEnabled = user.isMPEnabled;
    this.updateClosingDate = user.updateClosingDate;
    this.updateEstimatedClosingDate = user.updateEstimatedClosingDate;

    this.extra = user.extra;
    // this.extraJson = user.Extra == null || user.Extra.trim() === '' ? {} : JSON.parse(user.Extra);
    // this.extraJson.StaffClaims = this.ExtraJson.StaffClaims == null ? {} : this.ExtraJson.StaffClaims;
    // this.extraJson.StaffClaims.DeveloperSignNumber = this.ExtraJson.StaffClaims.DeveloperSignNumber == null ? null : this.ExtraJson.StaffClaims.DeveloperSignNumber;

    this.userDetailReporting = user.userDetailReporting ? new UserDetailsReporting(user.userDetailReporting) : undefined;
    this.userDetailImage = user.userDetailImage ? new UserDetailImage(user.userDetailImage) : undefined;

    this.isPrec = user.isPrec;
    this.cellPhone = user.cellPhone;
    this.sin = user.sin;
    this.nickname = user.nickname;
    this.frontPictureIdUrl = user.frontPictureIdUrl;
  }

  getFullName(includeDisabledSuffix?) {
    return (this.firstName + ' ' + (this.middleName !== '' ? this.middleName + ' ' : '') + this.lastName + (includeDisabledSuffix && !this.isActive ? ' (disabled)' : '')).trim();
  }

  getAddress() {
    return `${this.unitNumber} ${this.street} ${this.city} ${this.province} ${this.postalCode}`.trim();
  }

  // TODO Fix unused functions
  // UserDetails.prototype.isValid = function (userType, UserProfileQuestions, isBasic) {
  //     var self = this;
  //     this.resetValidation();
  //      if (this.FirstName == null || _.trim(this.FirstName) == '')
  //         this.valid.FirstName = false;
  //     if (this.LastName == null || _.trim(this.LastName) == '')
  //         this.valid.LastName = false;

  //     var emailRegEx = /\S+@\S+\.\S+/;
  //     if (this.Email == null || _.trim(this.Email) == '' || !emailRegEx.test(this.Email))
  //         this.valid.Email = false;

  //     if (isBasic) {
  //         var noErrors =  this.valid.FirstName && this.valid.LastName && this.valid.Email;
  //         return noErrors;
  //     }

  //     if (this.Phone == null || _.trim(this.Phone) == '')
  //         this.valid.Phone = false;
  //     if (this.Street == null || _.trim(this.Street) == '')
  //         this.valid.Street = false;
  //     if (this.City == null || _.trim(this.City) == '')
  //         this.valid.City = false;
  //     //if (this.Province == null || _.trim(this.Province) == '')
  //     //    this.valid.Province = false;
  //     if (this.Country == null || _.trim(this.Country) == '')
  //         this.valid.Country = false;
  //     //if (this.PostalCode == null || _.trim(this.PostalCode) == '')
  //     //    this.valid.PostalCode = false;

  //     if (userType === 'realtor') {
  //         if (this.Brokerage == null || _.trim(this.Brokerage) == '')
  //             this.valid.Brokerage = false;
  //         if ((yongleConstant.area !== 'ON') && (this.IsPrec == null || _.trim(this.IsPrec) == ''))
  //             this.valid.IsPrec = false;
  //         if (this.PostalCode == null || _.trim(this.PostalCode) == '')
  //             this.valid.PostalCode = false;
  //     }

  //     if (userType === 'client') {
  //         if (UserProfileQuestions === 'UserProfileAll') {
  //             this.validateMarketingQuestions(UserProfileQuestions);
  //         }

  //         if (this.IsCorp == null || _.trim(this.IsCorp) == '')
  //             this.valid.IsCorp = false;

  //         if (this.UserDetailReporting.Birthday == null || _.trim(this.UserDetailReporting.Birthday) == '')
  //             this.valid.UserDetailReporting.Birthday = false;

  //         if (this.UserDetailReporting.IdExpire == null || _.trim(this.UserDetailReporting.IdExpire) == '')
  //             this.valid.UserDetailReporting.IdExpire = false;


  //         if (this.UserDetailReporting.ResidencyStatus == null || _.trim(this.UserDetailReporting.ResidencyStatus) == '')
  //             this.valid.UserDetailReporting.ResidencyStatus = false;

  //         if (this.UserDetailReporting.ResidencyStatus === 'Non-resident' && (this.UserDetailReporting.Citizenship === null || _.trim(this.UserDetailReporting.Citizenship) === ''))
  //             this.valid.UserDetailReporting.Citizenship = false;

  //         if (this.UserDetailReporting.Business == null || _.trim(this.UserDetailReporting.Business) == '')
  //             this.valid.UserDetailReporting.Business = false;

  //         if (this.UserDetailReporting.IdNumber == null || _.trim(this.UserDetailReporting.IdNumber) == '')
  //             this.valid.UserDetailReporting.IdNumber = false;

  //         if (this.UserDetailReporting.IdType == null || _.trim(this.UserDetailReporting.IdType) == '')
  //             this.valid.UserDetailReporting.IdType = false;

  //         if (this.UserDetailReporting.IdIssuedBy == null || _.trim(this.UserDetailReporting.IdIssuedBy) == '')
  //             this.valid.UserDetailReporting.IdIssuedBy = false;

  //         if (this.IsCorp === true) {
  //             if (this.CorpName == null || _.trim(this.CorpName) == '')
  //                 this.valid.CorpName = false;
  //             //if (this.CorpAddress == null || _.trim(this.CorpAddress) == '')
  //             //    this.valid.CorpAddress = false;
  //         }

  //         if (_.trim(this.Country) === 'Canada' && (this.PostalCode == null || _.trim(this.PostalCode) === ''))
  //             this.valid.PostalCode = false;

  //     }
  //     var hasError = false;
  //     angular.forEach(_.keys(this.valid), function (prop) {
  //         hasError = hasError || (this.valid[prop] == false);
  //     });
  //     angular.forEach(_.keys(this.valid.UserDetailReporting), function (prop) {
  //         hasError = hasError || (this.valid.UserDetailReporting[prop] == false);
  //     });
  //     return !hasError;
  // }
}