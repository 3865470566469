import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { AllOptions } from '../heatmap-toggle/heatmap-toggle.component';

@Component({
  selector: 'lib-heatmap-legend',
  templateUrl: './heatmap-legend.component.html',
  styleUrls: ['./heatmap-legend.component.scss']
})
export class HeatmapLegendComponent implements OnInit {

  @Input() colorfield: Array<string> = [];
  @Input() displayMode?: 'row' | 'col' = 'row';
  @Output() selectChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() checkboxChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() optionsChanged: EventEmitter<AllOptions> = new EventEmitter();

  entireProject = false;
  includeZero = true;
  selection = null;
  
  constructor() { }

  onSelectChanged(newOption: MatSelectChange) {
    this.entireProject = newOption.value;
    this.selectChanged.emit(this.entireProject);
    this.emitAllChanges();
  }

  onCheckboxChanged(newOption: MatCheckboxChange) {
    this.includeZero = newOption.checked;
    this.checkboxChanged.emit(this.includeZero);
    this.emitAllChanges();
  }

  onOptionsChanged(newOption: AllOptions) {
    this.entireProject = newOption.entireProject;
    this.includeZero = newOption.includeZero;
    this.selection = newOption.selection;
    this.emitAllChanges();
  }

  private emitAllChanges() {
    this.optionsChanged.emit({entireProject: this.entireProject, includeZero: this.includeZero, selection: this.selection});
  }

  ngOnInit(): void {
  }

}
