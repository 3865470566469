import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';

@Component({
  selector: 'app-select-renew-component',
  template: ''
})
export class SilentRenewComponent implements OnInit {
  constructor(private authService: AuthService) {}
  ngOnInit() {
    this.authService.refreshToken().catch(err => console.log(err));
  }
}
