import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { VirtualLineUpInfo } from '../models/VirtualLineUpInfo';
import { RegisterService } from '../services/register.service';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class VirtualLineUpInfoResolver implements Resolve<VirtualLineUpInfo> {
  constructor (
    private registerSrvc: RegisterService,
    private configSrvc: ConfigService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<VirtualLineUpInfo> {
    const { id } = route.queryParams;
    return this.registerSrvc.getVirtualLineUpInfo(id).pipe(
      catchError(() => of(null)),
      tap((info: VirtualLineUpInfo) => {
        if (info) {
          this.configSrvc.setYongleAppConstant({
            area: info.developmentArea,
            timezone: info.timezone,
          });
        }
      }),
    )
  }
}
