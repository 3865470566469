export abstract class UserRole {
  public static Admin = 'MP_Admin';
  public static Realtor = 'MP_Realtor';
  public static Developer = 'MP_Developer';
  public static Sales = 'MP_Sales';
  public static CRMSales = 'CRM_Sales';
  public static CRMDeveloper = 'CRM_Developer';
  public static Client = 'client';
  public static AvesdoReporting = 'avesdoReporting';
  public static CoreUser = 'Core_User';
  public static AvesdoCS = 'AvesdoCS';
  public static AllowedMPRoles = ['MP_Admin', 'MP_Realtor', 'MP_Developer'];
  public static AllowedCRMRoles = ['CRM_Developer', 'CRM_Sales', 'Core_User'];
}
