import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ImagePreviewService } from '../../../lib/services/images/image-preview.service';

@Component({
  selector: 'lib-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnChanges {
  @Input() imageUrls: string[];
  imgUrlsNoImage: boolean[];
  imgHidden: boolean[];
  index: number = 0;

  constructor(private imagePreviewServ: ImagePreviewService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.imageUrls.currentValue) {
      return;
    }

    this.imgUrlsNoImage = new Array(changes.imageUrls.currentValue.length).fill(
      false
    );
    this.imgHidden = new Array(changes.imageUrls.currentValue.length).fill(
      true
    );
    this.imgHidden[this.index] = false;
  }
  openImage(imgUrl: string): void {
    if (this.imgUrlsNoImage[this.index]) {
      return;
    }

    this.imagePreviewServ.openBasicImage(imgUrl, '');
  }

  handleNoImage(index: number) {
    this.imgUrlsNoImage[index] = true;
  }

  next() {
    if (this.index >= this.imageUrls.length - 1) {
      return;
    }
    this.imgHidden[this.index] = true;
    this.index++;
    this.imgHidden[this.index] = false;
  }

  previous() {
    if (this.index <= 0) {
      return;
    }
    this.imgHidden[this.index] = true;
    this.index--;
    this.imgHidden[this.index] = false;
  }

  isHidden(index: number) {
    return this.index !== index;
  }
}
