import { Injectable, OnInit } from '@angular/core';
import { BaseInjectorConstructor } from 'ng-mix';
import { Userpilot } from 'userpilot';

export const UserpilotMixin = <Tbase extends BaseInjectorConstructor>(superClass: Tbase) => {

  @Injectable()
  abstract class UserpilotClass extends superClass implements OnInit {
    lastPage: string;
    
    ngOnInit(): void {
      super.ngOnInit();

      // Native js checking route change
      const oldPushState = history.pushState;
      history.pushState = function pushState() {
        const ret = oldPushState.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      };
      const oldReplaceState = history.replaceState;
      history.replaceState = function replaceState() {
        const ret = oldReplaceState.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      };
      window.addEventListener('popstate', () => {
        window.dispatchEvent(new Event('locationchange'));
      });

      // event listener for location change
      window.addEventListener('locationchange', () => {
        if (this.lastPage !== window.location.href) {
          this.lastPage = window.location.href;
          Userpilot.reload();
        }
      });
    }
  }

  return UserpilotClass;
}