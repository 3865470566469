import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'lib-floor-plate-canvas',
  templateUrl: './floor-plate-canvas.component.html',
  styleUrls: ['./floor-plate-canvas.component.scss']
})
export class FloorPlateCanvasComponent implements OnChanges {
  @Input() imgUrl: string;
  @Output() load = new EventEmitter();

  loadingImage: boolean;
  error = false;

  ngOnChanges() {
    this.loadingImage = true;
  }

  onError() {
    this.loadingImage = false;
    this.error = true;
  }

  onLoad() {
    this.loadingImage = false;
    this.load.emit();
  }

  constructor() { }
}
