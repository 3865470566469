import { CommonModule } from '@angular/common';
import { Compiler, Component, ComponentRef, Injector, Input, NgModule, NgModuleRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AvTableModule } from '../../feature/av-table/av-table.module';
import { AvesdoCommonModule } from '../../avesdo-common.module';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'lib-av-dynamic-html-block',
  templateUrl: './av-dynamic-html-block.component.html',
  styleUrls: ['./av-dynamic-html-block.component.css'],
  
})

export class AvDynamicHtmlBlockComponent implements OnInit {

  @Input() htmlTemplate: string;
  @Input() templateData: any;

  @ViewChild('dvc', { read: ViewContainerRef }) dvc: ViewContainerRef;

  modulesCount: number = 0;

  private componentReference: ComponentRef<any>;
  private temporaryModule: any;
  private temporaryComponent: Component;

  constructor(private compiler: Compiler,
    private injector: Injector,
    private moduleRef: NgModuleRef<any>) {
   }

  ngOnInit(): void {
    this.compiler.clearCache();
  }

  ngAfterViewInit() {
    this.createComponentFromRaw(this.htmlTemplate, this.templateData);
  }

  private createComponentFromRaw(htmlTemplate: string, templateData: any) {
    this.createTemporyComponentWithConstructor(htmlTemplate, templateData);
    this.temporaryModule = this.createCustomNgModule(this.temporaryComponent);
    this.compileAndRenderDynamicComponet();

  }

  private createTemporyComponentWithConstructor(htmlTemplate: string, templateData: any): void {
    function temporaryComponentConstructor() { this.htmlTemplate = htmlTemplate, this.data = templateData }
    this.temporaryComponent = Component({ template: htmlTemplate, styles: [] })(
      new temporaryComponentConstructor().constructor
    );
  }

  private createCustomNgModule(temporaryComponent: any ) {
    this.compiler.clearCache();
    return NgModule({
      imports: [CommonModule, AvesdoCommonModule, SharedModule],
      declarations: [temporaryComponent, AvDynamicHtmlBlockComponent],
      providers: []
    })(class {});
  }

  private compileAndRenderDynamicComponet(): void {
    this.compiler
    .compileModuleAndAllComponentsAsync(this.temporaryModule)
    .then((factories) => {
      
      const f = factories.componentFactories[0];
      this.componentReference = f.create(this.injector, [], null, this.moduleRef);
      this.componentReference.instance.name = `dynamic-component`;
      this.dvc.insert(this.componentReference.hostView);
    });
  }

  ngOnDestroy() {
    if (this.componentReference) {
      this.componentReference.destroy();
    }
  }
}

