import { BaseInjectorConstructor } from 'ng-mix';
import { Injectable, OnInit, Input, Output, EventEmitter } from '@angular/core';

export const AsyncButtonMixin = <Tbase extends BaseInjectorConstructor>(superClass: Tbase) => {

  @Injectable()
  abstract class AsyncButton extends superClass implements OnInit {
    @Input() disabled: boolean;
    @Input() isLoading: boolean;
    @Input() color: string = 'primary';

    @Output() btnClick = new EventEmitter<any>();

    onBtnClick() {
      !this.disabled && this.btnClick.emit();
    }
  }

  return AsyncButton;
}