import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models/generic/ApiResponse';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  //Azure block blob limit is 4MiB
  static readonly MAX_FILE_UPLOAD_SIZE = 4.194e6;

  constructor(
    private http: HttpClient,
    private translateSrvc: TranslateService
  ) {}

  uploadFile(file: File, path: string = 'utilityService/sas'): Observable<any> {
    if (file.size > FileUploadService.MAX_FILE_UPLOAD_SIZE) {
      return throwError(
        this.translateSrvc.instant('ERRORS.IMAGE_SIZE_EXCEEDED', {
          fileName: file.name,
          overSizeLimit: '4MB',
        })
      );
    }

    const sasUrl = `${environment.crmApiUrl}/${path}`;
    const fileName = new Date().getTime() + file.name;
    const params = { fileName, type: 'Upload' };

    return this.http.get<ApiResponse<string>>(sasUrl, { params }).pipe(
      map((response) => response.data),
      catchError((error) => throwError(error)),
      mergeMap((uploadUrl) => this.uploadToAzure(uploadUrl, file))
    );
  }

  private uploadToAzure(uploadUrl: string, file: File): Observable<string> {
    return this.http.put<any>(uploadUrl, file, { headers: {
      'Anonymous': 'true',
      'x-ms-blob-type': 'BlockBlob'
    }}).pipe(
      map(() => uploadUrl.split('?')[0]),
      catchError((error) => throwError(error)),
    );
  }

}
