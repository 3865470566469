import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-av-user-details',
  templateUrl: './av-user-details.component.html',
  styleUrls: ['./av-user-details.component.scss']
})
export class AvUserDetailsComponent implements OnInit {
  @Input() hide: boolean = false;
  @Input() phone: string;
  @Input() email: string;

  constructor() { }

  ngOnInit(): void {
  }

}
