import { Injectable } from '@angular/core';
import { ConfigService } from '@ng-new/src/app/shared/services/config.service';
import moment from 'moment-timezone/builds/moment-timezone-with-data.min';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private configService: ConfigService
  ) { }

  checkIfSameTimeZone() {
    const currentDateTimezone = new Date().getTimezoneOffset();
    const appTimezone = moment.tz.zone(this.configService.yongleAppConstant.timezone).parse(new Date());
    return currentDateTimezone === appTimezone;
  }

  setYongleTime(moment) {
    if (this.checkIfSameTimeZone()) {
      moment.set({ 'hour': 1, 'minute': 0, 'second': 0, 'millisecond': 0 });
    }
    return moment;
  }
  
  getTimeZoneOffset() {
    return moment.tz(this.configService.yongleAppConstant.timezone).format('Z');
  }

  toCurrentTimezone(dateObj) {
    const convertedDate = moment(dateObj).tz(this.configService.yongleAppConstant.timezone).toDate();
    return convertedDate;
  }

  toMomentDate(dateObj, useCurrentTimezone) {
    let momentDate = moment(dateObj);
    if ((dateObj != null && dateObj.getHours() === 0 && dateObj.getMinutes() === 0 && dateObj.getMilliseconds() === 0) && !this.checkIfSameTimeZone()) {
      // setting the time to 5PM to make the date conversion consitent in different time zones
      momentDate = momentDate.set({ hours: 17, minutes: 0 })
      return momentDate;
    }
    if (useCurrentTimezone) {
      momentDate = momentDate.tz(this.configService.yongleAppConstant.timezone);
      if ((dateObj != null && dateObj.getHours() === 0 && dateObj.getMinutes() === 0 && dateObj.getMilliseconds() === 0) && !this.checkIfSameTimeZone()) {
        momentDate = moment.utc(dateObj);
        // setting the time to 5PM to make the date conversion consitent in different time zones
        momentDate = momentDate.set({ hours: 17, minutes: 0 })
      }
    }
    return momentDate;
  }

  momentNow(useCurrentTimezone) {
    let momentNow = moment();
    if (useCurrentTimezone) {
      momentNow = momentNow.tz(this.configService.yongleAppConstant.timezone);
    }
    return momentNow;
  }
}
