import { createAction, props } from '@ngrx/store';
import { IBuilding } from '@avesdo-common/src/lib/models/building-models/Building';
import {IOrganizationAccount} from '@avesdo-common/src/lib/models/building-models/Organization';

export const FETCH_BUILDINGS_BY_ORGANIZATION =
  '[BuildingOrganization] Fetch Buildings by organization';
export const FETCH_BUILDINGS_BY_ORGANIZATION_SUCCESS =
  '[BuildingOrganization] Fetch Buildings by organization success';
export const FETCH_BUILDINGS_BY_ORGANIZATION_FAIL =
  '[BuildingOrganization] Fetch Buildings by organization fail';

export const FETCH_ORGANIZATION_ACCOUNT_BY_DEVELOPER_ID =
  '[OrganizationAccount] Fetch organization accounts by developer id';
export const FETCH_ORGANIZATION_ACCOUNT_SUCCESS_BY_DEVELOPER_ID =
  '[OrganizationAccount] Fetch organization accounts success by developer id';
export const FETCH_ORGANIZATION_ACCOUNT_FAIL_BY_DEVELOPER_ID =
  '[OrganizationAccount] Fetch organization accounts fail by developer id';


export const fetchBuildingsByOrganization = createAction(
  FETCH_BUILDINGS_BY_ORGANIZATION
);
export const fetchBuildingsByOrganizationSuccess = createAction(
  FETCH_BUILDINGS_BY_ORGANIZATION_SUCCESS,
  props<{ buildings: IBuilding[] }>()
);
export const fetchBuildingsByOrganizationFail = createAction(
  FETCH_BUILDINGS_BY_ORGANIZATION_FAIL
);

export const fetchOrganizationAccountByDeveloperId = createAction(
  FETCH_ORGANIZATION_ACCOUNT_BY_DEVELOPER_ID
);

export const fetchOrganizationAccountSuccessByDeveloperId = createAction(
  FETCH_ORGANIZATION_ACCOUNT_SUCCESS_BY_DEVELOPER_ID,
  props<{accounts: IOrganizationAccount[]}>()
);

export const fetchOrganizationAccountFailByDeveloperId = createAction(
  FETCH_ORGANIZATION_ACCOUNT_FAIL_BY_DEVELOPER_ID
);
