import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-av-plain-text',
  templateUrl: './av-plain-text.component.html',
  styleUrls: ['./av-plain-text.component.scss']
})
export class AvPlainTextComponent implements OnInit {
  @Input() text: string;
  @Input() class?: string;

  constructor() { }

  ngOnInit(): void {
  }
}
