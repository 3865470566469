import { Injectable, OnInit } from '@angular/core';
import { BaseInjectorConstructor } from 'ng-mix';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getBuildingTimezone } from '../redux/BuildingSettings/building-settings.selectors';
import { fetchBuildingSettingsIfNeeded } from '../redux/BuildingSettings/building-settings.actions';

export const BuildingTimezoneMixin = <Tbase extends BaseInjectorConstructor>(superClass: Tbase) => {

  @Injectable()
  abstract class BuildingTimezone extends superClass implements OnInit {
    timezone$: Observable<string>;
	
    store = this.injector.get(Store);

    ngOnInit(): void {
      super.ngOnInit();

      this.store.dispatch(fetchBuildingSettingsIfNeeded());

      this.timezone$ = this.store.select(getBuildingTimezone).pipe(
        filter((timezone) => timezone),
        take(1)
      );

    }		
  }

  return BuildingTimezone;
}