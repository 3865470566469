import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderComponentConfig } from '@avesdo-common/src/lib/components/header/header.component';
import { AuthService } from '@ng-new/src/app/auth/services/auth.service';
import { UserPermissions } from '@ng-new/src/model/allowedDevelopers.model';
import { Router } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ModuleNames } from '../shared/enums/ModuleNames';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';
import { HttpClient } from '@angular/common/http';
import { environment } from '@ng-new/src/environments/environment';
import { ISharedState } from '../shared/redux/shared.reducer';
import { fetchFeaturePermissions } from '../shared/redux/FeaturePermissions/feature-permissions.actions';
import { LegacyRouteChangeService } from '../shared/services/legacy-route-change.service';
import { IBuilding } from '@avesdo-common/src/lib/models/building-models/Building';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';
import FileSaver from 'file-saver';
import { LegacyUserRole } from '@avesdo-common/src/lib/enums/LegacyUserRole';
import { propogateUserClaims } from '../auth/redux/auth.actions';
import { AuthUser, UserRole } from '@avesdo-common/src/public-api';
import { User } from 'oidc-client';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  headerConfig: HeaderComponentConfig = {
    user: null,
    showCrm: false,
    showMarketPlace: false,
    showClosing: false,
    showSelection: false,
    canAccessAdminPage: null,
    buildings$: null,
    fromMp: false,
    highlightCore: true,
    selectionsLabel: null
  };

  showCommonHeader: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<any>,
    private http: HttpClient,
    private legacyRouteChangeSrvc: LegacyRouteChangeService,
    private avHelperSrvc: AvHelperService
  ) { }

  onGoToMarketPlace(tab: 'Grid' | 'Selections') {
    switch (tab) {
      case 'Grid':
        this.router.navigate(['sales/grid']);
        break;
      case 'Selections':
        this.router.navigate(['sales/selection-forms']);
        break;
      default:
        break;
    }
  }

  onGoToCrm() {
    this.router.navigate(['leads']);
  }

  onGoToPostSale(tab: 'deposits') {
    switch (tab) {
      case 'deposits':
        this.router.navigate(['post-sale/deposits']);
        break;
      default:
        break;
    }

  }

  onDownloadMasterReport() {
    this.http.post<any>(`${environment.crmApiUrl}/contracts/Excel/GetExcelReportsData`, null).pipe(
      handleCoreApiResponse,
      mergeMap((resp) => {
        const reportId = resp.data?.filter(report => report.functionName === 'MasterReport')[0].id;
        const { developerId } = this.headerConfig.user;
        const params = { reportId, developerId };

        return this.http.get(
          `${environment.crmApiUrl}/contracts/Excel/CustomExcelReport`,
          {
            params,
            responseType: 'blob',
            observe: 'response'
           }
        );
      })
    ).subscribe((response) => {
        const fileName = this.avHelperSrvc.getHttpHeaderFileName(response.headers);
        FileSaver.saveAs(response.body, fileName);
    });
  }

  onGoToCore() {
    this.router.navigate(['/']); // let the routing service determine redirect path
  }

  onGoToAdminPage() {
    this.legacyRouteChangeSrvc.changeLegacyRoute('/Account/Admin/');
    this.router.navigate(['Account/Admin']);
  }

  onGoToClosing() {
    this.router.navigate(['closing']);
  }

  onLogout() {
    this.authService.logout();
  }

  onBuildingSelect(building: IBuilding) {
    this.authService.setDeveloperId(parseInt(building.developerId));
    this.authService.setBuildingName(building.buildingName);
    window.location.href = '/';
  }

  private loadPermission() {
    this.store.dispatch(fetchFeaturePermissions());

    this.authService.getUserPermission().subscribe((result) => {
      const { Data: data }: { Data: UserPermissions } = result;

      if (data) {
        this.headerConfig.showCrm = data.AllowedCRMDeveloper;
        this.headerConfig.showMarketPlace = data.AllowedMPDeveloper;
        this.headerConfig.showClosing = data.ClosingModuleEnabled;
        this.headerConfig.showSelection = data.SelectionModuleEnabled;
      }
    });
  }

  private setActiveLink(moduleName: ModuleNames) {
    this.headerConfig.highlightCore = moduleName === ModuleNames.Contracts;
    this.headerConfig.highlightClosing = moduleName === ModuleNames.Closing;
    this.headerConfig.highlightOffplan = moduleName === ModuleNames.Sales;
    this.headerConfig.highlightCrm = moduleName === ModuleNames.Leads;
    this.headerConfig.highlightPostSale = moduleName === ModuleNames.PostSale;
  }

  private getLabels() {
    const url = `${environment.crmApiUrl}/corempapi/developer/${this.authService.developerId}/selections/getlabels`

    return this.http.get<any>(url).pipe(
      catchError((error) => throwError(error))
    );
  }

  private isPurchaserOrRealtor(user: AuthUser): boolean {
    const userRole = !user.role.filter(r => r).length || user.role.includes(UserRole.Realtor);
    const userLegacyRole = user.legacyRole === LegacyUserRole.Client || user.legacyRole === LegacyUserRole.Realtor
    return userRole || userLegacyRole;
  }

  ngOnInit() {
    this.subscription.add(this.store.select('ngNewShared')
      .subscribe((result: ISharedState) => {
        this.headerConfig.hideHeader = result.hideCommonHeader;
        this.setActiveLink(result.activeModule);
      })
    );

    const user = this.authService.getAuthorizedUser();
    this.headerConfig.user = user;

    this.showCommonHeader = true;

    if (!this.isPurchaserOrRealtor(user))
      this.loadPermission();

    this.store.dispatch(propogateUserClaims());

    this.headerConfig.buildings$ = this.authService.getProjectList();
    this.headerConfig.buildings$.subscribe(b => {
      this.headerConfig.user.building = b.find(bu => +bu.developerId === this.authService.developerId);
      this.getLabels().subscribe(({ labels }) => {
        this.headerConfig.selectionsLabel = labels.find((label) => label.key === 'Selections').value;
      });
    });

    this.headerConfig.canAccessAdminPage = this.authService.getAuthorizedUser().legacyRole === LegacyUserRole.AvesdoCS 
      || this.authService.getAuthorizedUser().legacyRole === UserRole.AvesdoReporting;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
