
import { createReducer, on, Action } from '@ngrx/store';
import {
  fetchFeaturePermissions, fetchFeaturePermissionsFail, fetchFeaturePermissionsSuccess
} from '@ng-new/src/app/shared/redux/FeaturePermissions/feature-permissions.actions';
import { IFeaturePermissionRespsonse } from '@crm/src/app/permissions/model/permissions';

export interface IFeaturePermissionsState {
  isLoading: boolean;
  featurePermissions: IFeaturePermissionRespsonse[];
}

export const initialState: IFeaturePermissionsState = {
  isLoading: false,
  featurePermissions: null,
};

const featurePermissionsReducer = createReducer(
  initialState,
  on(fetchFeaturePermissions, state => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(fetchFeaturePermissionsSuccess, (state, payload) => {
    const { featurePermissions } = payload;

    return {
      ...state,
      featurePermissions,
      isLoading: false
    };
  }),
  on(fetchFeaturePermissionsFail, state => ({
    ...state,
    isLoading: false
  }))
);

export function FeaturePermissionsFn(
  state: IFeaturePermissionsState | undefined,
  action: Action
) {
  return featurePermissionsReducer(state, action);
}
