import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CustomForm } from '../../services/custom-form-model.service';
import { Subscription } from 'rxjs';
import { mergeMap, tap, map } from 'rxjs/operators';
import { Dictionary } from '@avesdo-common/src/lib/models/generic/Dictionary';
import { PublicItem } from '../../models/PublicItem';
import { fetchPublicItemsIfNeeded } from '../../redux/PublicItems/public-items.actions';
import { Store } from '@ngrx/store';
import { getPublicItemsState } from '../../redux/PublicItems/public-items.selectors';
import { AvCustomControlTypes } from '../../enums/AvCustomControlTypes';
import { IFormControls } from '@avesdo-common/src/lib/feature/custom-forms/models/LeadFormTemplateResponse';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@avesdo-common/src/public-api';
import { ConfirmationDialogService } from '@avesdo-common/src/lib/services/dialogs/confirmation-dialog.service';

export interface ViewOverride {
  controlType?: AvCustomControlTypes,
  containerClass?: string;
}

@Component({
  selector: 'lib-av-custom-fields',
  templateUrl: './av-custom-fields.component.html',
  styleUrls: ['./av-custom-fields.component.scss']
})
export class AvCustomFieldsComponent implements OnInit, OnDestroy {
  @Input() customForm: CustomForm;
  @Input() viewOverrideByLabel: Dictionary<ViewOverride> = {};
  @Input() readonly = false;
  @Input() showDialogForExistingEmail = false;
  @Input() containerClass?: string;
  @Output() useExistingEmail = new EventEmitter<string>();

  subscriptions = new Subscription();
  asyncOptionsByControl: Dictionary<PublicItem[]> = {};
  existingEmailDialog: MatDialogRef<ConfirmationDialogComponent, boolean>;
  emailFieldFocused: boolean;

  AvCustomControlTypes = AvCustomControlTypes;

  get formGroup() { return this.customForm.formGroup; };
  get tplCtrlAsscs() { return this.customForm.tplCtrlAsscs; };

  constructor(
    private store: Store,
    private confirmationDialogService: ConfirmationDialogService,
    private translateSrvc: TranslateService
  ) { }

  getControlType(control : IFormControls): AvCustomControlTypes {
    return this.viewOverrideByLabel[control.label]?.controlType || control.controlType as AvCustomControlTypes;
  }

  onFileUpload(formControl: AbstractControl, imgUrl: string) {
    formControl.setValue(imgUrl);
    this.customForm.formGroup.markAsTouched();
  }

  onFileCleared(formControl: AbstractControl) {
    formControl.setValue(null);
    this.customForm.formGroup.markAsTouched();
  }

  onCurrencyChange(formControl: AbstractControl, value) {
    if (Number.isNaN(formControl.value)) {
      formControl.setValue(value);
      this.customForm.formGroup.markAsTouched();
    }
  }

  hasRealtorAlreadyExists(formControl: AbstractControl): boolean {
    if (formControl.hasError('realtorAlreadyExists')) {
      if (!this.emailFieldFocused && !this.existingEmailDialog && this.showDialogForExistingEmail) {
        this.openRealtorAlreadyExistsDialog(formControl);
       }
      return true;
    }
    return false;
  }

  openRealtorAlreadyExistsDialog(formControl: AbstractControl) {
    this.existingEmailDialog = this.confirmationDialogService.openDialog({
      title: 'ERRORS.EMAIL_EXIST',
      message: 'ERRORS.REALTOR_EMAIL_EXIST_MESSAGE',
      no: 'GENERIC.CANCEL',
    })
    this.existingEmailDialog.afterClosed().subscribe(result => {
      if (result) {
        this.useExistingEmail.emit(formControl.value);
        return;
      }
      formControl.reset();
      this.existingEmailDialog = undefined;
    });
  }

  getLabel(templateControl: IFormControls, hideRequiredLabel = false) {
    let label = templateControl.label;

    if (templateControl.isRequired && !hideRequiredLabel) {
      label = label.concat(` (${this.translateSrvc.instant('ERRORS.REQUIRED')})`)
    }

    return label;
  }

  isAsyncFieldDisabled(controlKey: string) {
    return this.asyncOptionsByControl[controlKey]?.length === 0
  }

  initializeAsyncFields() {
    this.customForm.tplCtrlAsscs.forEach(({ templateControl }) => {
      const triggerKey = templateControl.refreshOn;

      if (triggerKey) {
        const triggerControl = this.customForm.formGroup.get(triggerKey);
        const asyncControl = this.customForm.formGroup.get(templateControl.key);

        this.subscriptions.add(
          //Listen for changes on the control trigger
          triggerControl.valueChanges
            .pipe(
              tap((id) => { id && this.store.dispatch(fetchPublicItemsIfNeeded({ id })) }),
              //Get the options applicable to the control trigger
              mergeMap((id) => {
                return this.store.select(getPublicItemsState)
                  .pipe(map(({ publicItemsById }) => publicItemsById[id]))
              })
            )
            .subscribe((options) => {
              this.asyncOptionsByControl[templateControl.key] = options;

              // force N/A if no options available, allowing to validate if value is required;
              if (options?.length === 0) {
                asyncControl.setValue('N/A');
              }
            })
        );

        //Trigger a change to fetch intial options
        triggerControl.setValue(triggerControl.value);

        this.subscriptions.add(triggerControl.valueChanges.subscribe(() => {
          asyncControl.setValue(null);
        }));
      }
    });
  }

  ngOnInit(): void {
    this.initializeAsyncFields();
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
