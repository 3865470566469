import { Component, Input, OnChanges } from '@angular/core';
import { SelectedRealtor } from '../../../models/SelectedRealtor';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';

@Component({
  selector: 'lib-selected-realtor',
  templateUrl: './selected-realtor.component.html',
  styleUrls: ['./selected-realtor.component.scss']
})
export class SelectedRealtorComponent implements OnChanges {
  @Input() realtor: SelectedRealtor;

  regionLine: string

  constructor(private avHelperSrvc: AvHelperService) { }

  ngOnChanges(): void {
    this.regionLine = this.avHelperSrvc.getStringArraySummary([
      this.realtor.city || '',
      this.realtor.province || '',
      this.realtor.country || ''
    ]);
  }

}
