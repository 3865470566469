import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeRemaining'
})
export class TimeRemainingPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const NoLimitReservation = new Date(3000, 0, 1).toDateString();

    const reservationDate = new Date(value);

    if (NoLimitReservation === reservationDate.toDateString()) {
      return 'NoLimitReservation';
    }

    const future = reservationDate.getTime();

    const currentDate = new Date();
    const now =  currentDate.getTime() + currentDate.getTimezoneOffset() * 60000;

    const distance = future - now;
    if (distance < 0) {
      return 'No time remaining';
    }
    const hours = Math.floor(distance / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  }
}
