import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllowedFileTypes } from '../../constants/file-upload-constants';

@Component({
  selector: 'lib-av-file-upload-container',
  templateUrl: './av-file-upload-container.component.html',
  styleUrls: ['./av-file-upload-container.component.scss']
})
export class AvFileUploadContainerComponent implements OnInit {
  @Input() isBatch?: boolean = false;
  @Input() isFromDirectory?: boolean = false;
  @Input() useLegacyButton?: boolean = false;
  @Input() isValid?: boolean;
  @Input() allowedFiles?: string[] = AllowedFileTypes;
  @Output() onDropFiles = new EventEmitter<any>();
  @Output() onHover = new EventEmitter<any>();
  @Output() onFileSelected = new EventEmitter<any>();

  get dragDropText() {
    return this.isBatch ? 'BULKFILEUPLOAD.DRAG_DROP_FILES_INFO' : 'BULKFILEUPLOAD.DRAG_DROP_FILE_INFO';
  }

  get buttonText() {
    return this.isFromDirectory ? 'BULKFILEUPLOAD.SELECT_FOLDER' : (this.isBatch ? 'BULKFILEUPLOAD.SELECT_FILES' : 'BULKFILEUPLOAD.SELECT_FILE');
  }

  constructor() { }

  ngOnInit(): void {
  }

}
