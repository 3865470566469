import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-photo-button',
  templateUrl: './photo-button.component.html',
  styleUrls: ['./photo-button.component.scss']
})
export class PhotoButtonComponent {
  @Input() disabled: boolean;
  @Output() btnClick = new EventEmitter<any>();
}
