import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SelectionSetupComponent } from './components/selection-setup/selection-setup.component';
import { MaterialModule } from '@avesdo-common/src/lib/material.module';
import {
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { CircleCheckboxComponent } from './components/circle-checkbox/circle-checkbox.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SelectionViewComponent } from './components/selection-view/selection-view.component';
import { SelectionPreviewDialogComponent } from './components/selection-preview-dialog/selection-preview-dialog.component';
import { FloorPlateCanvasComponent } from './components/floor-plate-canvas/floor-plate-canvas.component';
import { SelectionSubmissionAccordionComponent } from './components/selection-submission-form/selection-submission-accordion/selection-submission-accordion.component';
import { BuyerChoicesComponent } from './components/selection-submission-form/buyer-choices/buyer-choices.component';
import { CustomFormsModule } from '../custom-forms/custom-forms.module';
import { SharedModule } from '../../shared.module';
import { SelectedRealtorComponent } from './components/selection-submission-form/selected-realtor/selected-realtor.component';
import { UnitPickerComponent } from './components/selection-submission-form/unit-picker/unit-picker.component';
import { HeatmapToggleComponent } from './components/heatmap-toggle/heatmap-toggle.component';
import { HeatmapLegendComponent } from './components/heatmap-legend/heatmap-legend.component';
import { FloorPlanCardComponent } from './components/selection-submission-form/floor-plan-card/floor-plan-card.component';
import { FloorPlanDetailsComponent } from './components/selection-submission-form/floor-plan-details/floor-plan-details.component';
import { UnitPickerFilterComponent } from './components/selection-submission-form/unit-picker-filter/unit-picker-filter.component';
import { ImageExpandSidenavComponent } from './components/selection-submission-form/floor-plan-details/image-expand-sidenav/image-expand-sidenav.component';

//export Required for Aot
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/locale/', '.json');
}

// @dynamic
@NgModule({
  declarations: [
    SelectionSetupComponent,
    CircleCheckboxComponent,
    SelectionViewComponent,
    SelectionPreviewDialogComponent,
    FloorPlateCanvasComponent,
    SelectionSubmissionAccordionComponent,
    BuyerChoicesComponent,
    SelectedRealtorComponent,
    UnitPickerComponent,
    HeatmapToggleComponent,
    HeatmapLegendComponent,
    FloorPlanCardComponent,
    FloorPlanDetailsComponent,
    UnitPickerFilterComponent,
    ImageExpandSidenavComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    CustomFormsModule
  ],
  entryComponents: [
    SelectionSetupComponent,
    SelectionViewComponent,
    SelectionPreviewDialogComponent
  ],
  exports: [
    FloorPlateCanvasComponent,
    SelectionSubmissionAccordionComponent,
    SelectedRealtorComponent,
    UnitPickerComponent,
    HeatmapToggleComponent,
    HeatmapLegendComponent,
    FloorPlanDetailsComponent
  ]
})
export class SelectionModule { }
