import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BuildingGeocode } from '../../models/building-models/BuildingGeocode';

@Component({
  selector: 'lib-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent {
  @Input() building: BuildingGeocode;
  @Output() clickAction: EventEmitter<any> = new EventEmitter();
  @Output() linkClick: EventEmitter<any> = new EventEmitter();
  @Input() hideTooltip: Boolean;

  constructor() {}
}
