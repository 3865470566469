import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentName } from '@avesdo-common/src/lib/enums/EnvironmentName';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public settings: IFeatureConfig;
  environment;

  constructor(private http: HttpClient) { }
  load(configFileLocation: string) {
    const hostUrl = window.location.hostname;
    if ((hostUrl.indexOf(EnvironmentName.AvesdoDev) >= 0 || (hostUrl.indexOf(EnvironmentName.LocalHost) >= 0))) {
      this.environment = EnvironmentName.Dev;
    } else if (hostUrl.indexOf(EnvironmentName.Qa) >= 0) {
      this.environment = EnvironmentName.Qa;
    } else if (hostUrl.indexOf(EnvironmentName.Demo) >= 0) {
      this.environment = EnvironmentName.Demo;
    } else if (hostUrl.indexOf(EnvironmentName.Uat) >= 0) {
      this.environment = EnvironmentName.Uat;
    } else {
      this.environment = EnvironmentName.Prod;
    }


    return new Promise<void>((resolve, reject) => {
      this.http
        .get(configFileLocation)
        .toPromise()
        .then((response: IAppConfig) => {
          this.settings = <IFeatureConfig>(
            response[this.environment]
          );
          resolve();
        })
        .catch((response: any) => {
          reject(`Could not load the config file`);
        });
    });
  }
}

export interface IAppConfig {
  dev: IFeatureConfig;
  qa: IFeatureConfig;
  demo: IFeatureConfig;
  uat: IFeatureConfig;
  prod: IFeatureConfig;
}

export interface IFeatureConfig {
  showAllSupportedLanguages: boolean;
  showSimplifiedRealtorRegistration: boolean;
  showNoCCWorkFlow: boolean;
  gridPollingTime: number;
  showWIIFM: boolean;
  showPostSaleModule: boolean;
  showClosingModule: boolean;
  showSelectionWorkflow: boolean;
  useSignalRFlow: boolean;
  selectionForms: boolean;
  deleteSelectionForms: boolean;
  uiRefresh: {
    dashboard: boolean;
    listing: boolean;
    dealLogs:boolean;
    disclosure: boolean;
    excelReporting: boolean;
    purchaserReporting: boolean;
    salesPerformance: boolean;
    paymentReporting: boolean;
    depositReporting: boolean;
    rescissionReporting: boolean;
    subjectReporting: boolean;
    parkingLockers: boolean;
    documents: boolean;
    upgrades: boolean;
    dealFinder: boolean;
  };
  maxDaysForEftPaymentChanges: number;
  maxDaysForEftPayorChanges: number;
  dayDiffForEftExclusion: number;
  cfgEmailTemplatesOnCrm: boolean;
  maxNumberOfAddableUnitsListingSetup: number;
  triggerCopyToDemoEnabled: boolean;
  triggerScheduleEnableEftForAllDeals: boolean;
  depositDashboard: {
    dashboard: boolean;
    depositSchedule: boolean;
    payments: boolean;
    management: boolean;
  };
  postSale: {
    disclosure: boolean;
    deposits: boolean;
    masterReport: boolean;
  };
  scheduleADSFlag: boolean;
}
