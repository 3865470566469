import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@ng-new/src/environments/environment';
import { ExternalRegisterInfo } from '../models/ExternalRegisterInfo';
import { VirtualLineUpInfo } from '../models/VirtualLineUpInfo';
import { CoreApiResponse } from '@avesdo-common/src/lib/models/generic/CoreApiResponse';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  getExternalRegisterInfo(dev: string): Observable<ExternalRegisterInfo> {

    const params = new HttpParams().set('dev', dev);
    return this.http
      .get<CoreApiResponse<ExternalRegisterInfo>>(`${environment.crmApiUrl}/Contracts/Register/GetExternalRegisterInfo`, { params } )
      .pipe(
        handleCoreApiResponse,
        map((resp) => resp.data),
        catchError((error: any) => throwError(error))
      );
  }

  getVirtualLineUpInfo(id: string): Observable<VirtualLineUpInfo> {

    const params = new HttpParams().set('id', id);
    return this.http
      .get<CoreApiResponse<VirtualLineUpInfo>>(`${environment.crmApiUrl}/Contracts/Register/GetVirtualLineUpInfo`, { params } )
      .pipe(
        handleCoreApiResponse,
        map((resp) => resp.data),
        catchError((error: any) => throwError(error))
      );
  }
}
