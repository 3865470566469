export enum AvCustomControlTypes {
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Select = 'select',
  Checkbox = 'checkbox',
  Datetime = 'datetime',
  BooleanButtonToggle = 'booleanButtonToggle',
  FileUpload = 'fileUpload',
  Text = 'text',
  Currency = 'currency'
}