import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@avesdo-common/src/lib/services/language/language.service';
import { Userpilot } from 'userpilot';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  constructor(
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.initialize();
    Userpilot.anonymous();
  }

}
