import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'lib-infinite-scroll-container',
  templateUrl: './infinite-scroll-container.component.html',
  styleUrls: ['./infinite-scroll-container.component.scss'],
})
export class InfiniteScrollContainerComponent implements AfterViewInit {
  @ViewChild('content') ngContentRef: ElementRef;

  @Input() isLoading: boolean;
  @Output() atBottom = new EventEmitter();

  constructor() {}

  onScroll(event) {
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    if (offsetHeight + scrollTop >= scrollHeight - 1 && !this.isLoading) {
      this.atBottom.emit();
    }
  }

  ngAfterViewInit() {
    if (!this.isLoading)
      this.atBottom.emit();
  }
}
